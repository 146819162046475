import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useFriends } from '../utilities/friendContext';
import { useJetpackSnackbar } from './Snackbar';
import * as ROUTES from '../constants/routes';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function AcceptInvitationContextWrapper() {
	const query = useQuery();
	const friendRequestCode = query.get('friendRequest');
	const groupSchedulingInviteEventId = query.get('groupSchedulingInvite');

	return (
		<AcceptInvitationWithContext
			friendRequestCode={friendRequestCode}
			groupSchedulingInviteEventId={groupSchedulingInviteEventId}
		/>
	);
}

function AcceptInvitationWithContext(props: AcceptInvitationWithContextProps) {
	const { acceptFriendRequest } = useFriends();

	const location = useLocation();

	const { enqueueSnackbar } = useJetpackSnackbar();

	useEffect(() => {
		// Accept friend request

		if (props.friendRequestCode) {
			acceptFriendRequest(props.friendRequestCode)
				.then(result => {
					if ('success' in result && 'senderDisplayName' in result) {
						enqueueSnackbar(`${result.senderDisplayName} is now your friend.`, {variant: 'success'});	
					} else if ('success' in result) {
						enqueueSnackbar('Friend added.', {variant: 'success'});
					} else {
						enqueueSnackbar('Error accepting friend request.', {variant: 'error'});
					}
				});
		}
	}, [acceptFriendRequest, enqueueSnackbar, props.friendRequestCode])

	if (props.friendRequestCode) {
		// TODO: Add a welcome page that tells a user about the friend request with a button to accept or ignore.
		return (
			<Redirect to={{
				pathname: ROUTES.COLLABORATION
			}} />
		);
	} else if (props.groupSchedulingInviteEventId) {
		const route = ROUTES.GROUP_SCHEDULING_INVITE;
		return (
			<Redirect to={{
				pathname: route,
				search: `?groupSchedulingInvite=${props.groupSchedulingInviteEventId}`
			}} />
		);
	} else {
		return (
			<Redirect to={{
				pathname: ROUTES.HOME,
				search: location.search
			}} />
		);
	}
}

// --------------------
// Types
// --------------------

type AcceptInvitationWithContextProps = {
	friendRequestCode: string | null;
	groupSchedulingInviteEventId: string | null;
}