import React, { createContext, useContext } from 'react';
import { useFirebase } from '../firebaseContext';
import { Features } from './features';

const FeatureContext = createContext<FeatureContextType | undefined>(undefined);

export function FeatureProvider({children}: ProviderProps) {
	const firebaseContext = useFirebase();
	const RemoteConfig = firebaseContext.RemoteConfig;

	const getValue = ((key: Features) => {
		if (!RemoteConfig) {
			console.error(`Error while getting feature ${key}: No remoteConfig object found.`);
			return null;
		}
		return RemoteConfig.getValue(key);
	})

	const getBoolean = ((key: Features) => {
		if (!RemoteConfig) {
			console.error(`Error while getting feature ${key}: No remoteConfig object found.`);
			return false;
		}
		return RemoteConfig.getBoolean(key);
	})

	const getNumber = ((key: Features) => {
		if (!RemoteConfig) {
			console.error(`Error while getting feature ${key}: No remoteConfig object found.`);
			return 0;
		}
		return RemoteConfig.getNumber(key);
	})

	const getString = ((key: Features) => {
		if (!RemoteConfig) {
			console.error(`Error while getting feature ${key}: No remoteConfig object found.`);
			return '';
		}
		return RemoteConfig.getString(key);
	})

	const forProvider = {
		getValue: getValue,
		getBoolean: getBoolean,
		getNumber: getNumber,
		getString: getString
	};

	return (
		<FeatureContext.Provider value={forProvider}>
			{children}
		</FeatureContext.Provider>
	)
}

export function useFeature() {
	const context = useContext(FeatureContext);
	if (context === undefined) {
		throw new Error('useFeature must be used within a FeatureProvider.');
	}
	return context;
}

// --------------------
// Types
// --------------------

type FeatureContextType = {
	getValue: (key: Features) => any;
	getBoolean: (key: Features) => boolean;
	getNumber: (key: Features) => number;
	getString: (key: Features) => string;
};

type ProviderProps = {children: React.ReactNode};