import React from 'react';
import styled from '@emotion/styled';
import { SnackbarProvider, useSnackbar } from 'notistack';

import { colors } from '../styles/colors';

export function JetpackSnackbarProvider({ children, ...rest }: any) {
	return (
		<StyledSnackbarProvider
			{...rest}
			maxSnack={1}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
		>
			{children}
		</StyledSnackbarProvider>
	);
}

export function useJetpackSnackbar() {
	return useSnackbar();
}

// Overriding styles per https://github.com/iamhosseindhv/notistack/issues/440#issuecomment-955632581
const StyledSnackbarProvider = styled(SnackbarProvider)`
	&.SnackbarItem-variantSuccess {
		background-color: ${colors.gray1};
		color: ${colors.greenLight};
	}
	&.SnackbarItem-variantError {
		background-color: ${colors.gray1};
		color: ${colors.jetpackCoral};
	}
	&.SnackbarItem-variantWarning {
		background-color: ${colors.gray1};
		color: ${colors.jetpackYellow};
	}
	&.SnackbarItem-variantInfo {
		background-color: ${colors.gray1};
		color: ${colors.blueLight};
	}
`;