import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { useFirebase, functionNames } from './firebaseContext';
import { events, useAnalytics } from './analyticsContext';

const authContext = createContext<AuthContextType | undefined>(undefined);

// Provider component that wraps your app and makes auth object available to any child component that calls useAuth()
export function AuthProvider({ children }: ProviderProps) {
	const auth = useProvideAuth();
	return (
		<authContext.Provider value={auth}>
			{children}
		</authContext.Provider>
	);
}

// Hook for child components to get the auth object re-render when it changes
export function useAuth() {
	const context = useContext(authContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
	const [state, setState] = useState<ProviderStateType>({
		loading: true,
		authenticated: false,
		user: null
	})
	const firebaseContext = useFirebase();
	const Auth = firebaseContext.Auth!;
	const Firestore = firebaseContext.Firestore!;
	const Functions = firebaseContext.Functions!;

	const analyticsContext = useAnalytics();
	const setAnalyticsUser = analyticsContext.setUser;
	const unsetAnalyticsUser = analyticsContext.unsetUser;
	const trackEvent = analyticsContext.trackEvent;

	// Subscribe to user on mount
  // Because this sets state in the callback it will cause any component that utilizes this hook to re-render with the latest auth object
	useEffect(() => {
		const unsubscribe = Auth.onAuthStateChanged(user => {
			if (user) {
				setAnalyticsUser(user.uid);
				
				setState(s => {
					return {
						...s,
						loading: false,
						authenticated: true,
						user: user
					};
				});
			} else {
				unsetAnalyticsUser();

				setState(s => {
					return {
						...s,
						loading: false,
						authenticated: false,
						user: null
					};
				});
			}
		});

		// Clean up subscription on unmount
		return () => unsubscribe();
	}, [Auth, setAnalyticsUser, unsetAnalyticsUser, trackEvent]);

	// Wrap any Firebase methods we want to use making sure to save the user to state
	const signin = (email: string, password: string) => {
		return Auth
			.signInWithEmailAndPassword(email, password)
			.then(response => {
				setState(s => {
					return {
						...s,
						loading: false,
						authenticated: true,
						user: response.user
					};
				});
				return response.user;
			})
			.then(user => {
				// Analytics
				trackEvent(events.LoggedIn, {user_id: user?.uid, location: 'webApp'});
				return user;
			})
			.catch(error => {
				console.error(error);
				throw error;
			});
	};

	const signup = (fullname: string, email: string, password: string, inviteCode: string) => {
		const validateSignupInviteCode = Functions.httpsCallable(functionNames.validateSignupInviteCode);

		if (!inviteCode) {
			// TODO: Let the user join the waitlist.
			return Promise.reject('No invite code.')
		}

		let userId = '';
		
		// Validate invite code
		return validateSignupInviteCode({
				inviteCode: inviteCode
			})
			.then((response) => {
				if (!response.data) {
					console.error('Invalid invitation.');
					throw response;
				} else {
					// Create user
					return Auth
						.createUserWithEmailAndPassword(email, password)
				}
			})
			.then(response => {
				if (!response.user || !response.user.uid) {
					throw new Error('No user ID received when creating user.');
				} else {
					userId = response.user.uid;
				}

				// Update auth context state
				setState(s => {
					return {
						...s,
						loading: false,
						authenticated: true,
						user: response.user
					};
				});
				return response.user;
			})
			.then(response => {
				return Auth.currentUser?.updateProfile({
					displayName: fullname
				});
			})
			.then(response => {
				return Firestore.collection('users').doc(userId)
					.set({
						displayName: fullname
					}, { merge: true });
			})
			.then(async response => {
				// Mark invite code claimed (with email address and userId)
				return Firestore.collection('inviteCodes')
					.doc(inviteCode)
					.update({
						claimed: true,
						claimedByEmail: email,
						claimedByUserId: Auth.currentUser?.uid || null
					})
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const signupWithoutInviteCode = (fullname: string, email: string, password: string) => {
		let userId = '';

		return Auth.createUserWithEmailAndPassword(email, password)
			.then(response => {
				if (!response.user || !response.user.uid) {
					throw new Error('No user ID received when creating user.');
				} else {
					userId = response.user.uid;
				}

				setState(s => {
					return {
						...s,
						loading: false,
						authenticated: true,
						user: response.user
					};
				});
			})
			.then(response => {
				return Auth.currentUser?.updateProfile({
					displayName: fullname
				});
			})
			.then(response => {
				return Firestore.collection('users').doc(userId)
					.set({
						displayName: fullname
					}, { merge: true });
			})
			.then(response => {
				return Firestore.collection('friendRequests')
					.add({
						from: userId,
						fromDisplayName: fullname,
						multiUse: true
					})
			})
			.catch(error => {
				console.error(error);
				throw error;
			});
	}

	const signout = () => {
		return Auth
			.signOut()
			.then(() => {
				// Analytics
				trackEvent(events.LoggedOut, {user_id: state.user?.uid, location: 'webApp'});
			})
			.then(() => {
				setState(s => {
					return {
						...s,
						loading: false,
						authenticated: false,
						user: null
					};
				});
			})
			.catch(error => {
				console.error(error);
				throw error;
			});
	};

	const sendPasswordResetEmail = () => {
		if (!Auth.currentUser) {
			console.log('Error sending password reset email: No current user.');
		} else if (!Auth.currentUser.email) {
			console.log('Error sending password reset email: No email associated with user.');
		}
		return Auth
			.sendPasswordResetEmail(Auth.currentUser!.email!)
			.then(() => {
				return;
			})
			.catch((error) => {
				console.log('Error sending password reset email.');
				console.log(error);
				throw error;
			});
	};

	const confirmPasswordReset = (code:string, password: string) => {
		return Auth
			.confirmPasswordReset(code, password)
			.then(() => {
				return true;
			});
	};

	// Return the user object and auth methods
	return {
		user: state.user,
		authenticated: state.authenticated,
		loading: state.loading,
		signin: signin,
		signup: signup,
		signupWithoutInviteCode: signupWithoutInviteCode,
		signout: signout,
		sendPasswordResetEmail: sendPasswordResetEmail,
		confirmPasswordReset: confirmPasswordReset
	};
}

// --------------------
// Types
// --------------------

export type AuthContextType = {
	user: firebase.User | null,
	authenticated: boolean,
	loading: boolean,
	signin: (email: string, password: string) => Promise<firebase.User | null>,
	signup: (fullname: string, email: string, password: string, inviteCode: string) => Promise<any>,
	signupWithoutInviteCode: (fullname: string, email: string, password: string) => Promise<any>,
	signout: any,
	sendPasswordResetEmail: () => Promise<void>,
	confirmPasswordReset: any,
}

type ProviderProps = {children: React.ReactNode};

type ProviderStateType = {
	loading: boolean,
	authenticated: boolean,
	user: firebase.User | null
}