import React, { useState } from 'react';
import {
  Switch,
	Redirect,
  Route,
	useLocation
} from 'react-router-dom';
import styled from '@emotion/styled';
import { AppBar, CircularProgress, Drawer, IconButton, Toolbar } from '@mui/material';
import { FiMenu } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';

import * as ROUTES from '../constants/routes';
import { UserProvider, useUser } from '../utilities/userContext';
import { FriendProvider } from '../utilities/friendContext';
import { ChatProvider } from '../utilities/chatContext';
import { SparkProvider } from '../utilities/sparkContext';
import Navbar from './Navbar';
import HomePage from './HomePage';
import AcceptInvitation from './AcceptInvitation';
import SparksPage from './SparksPage';
import CollaborationPage from './CollaborationPage';
import GroupSchedulingMainPage from './GroupScheduling/GroupSchedulingMainPage';
import GroupSchedulingInviteLandingPage from './GroupScheduling/GroupSchedulingInviteLandingPage';
import GroupSchedulingParticipantPage from './GroupScheduling/GroupSchedulingParticipantPage';
import GroupSchedulingOwnerPage from './GroupScheduling/GroupSchedulingOwnerPage';
import SettingsPage from './SettingsPage';
import ConfirmEmail from './ConfirmEmail';
import GoogleCalendarOAuthRedirect from './GoogleCalendarOAuthRedirect';
import OutlookCalendarOAuthRedirect from './OutlookCalendarOAuthRedirect';
import Admin from './Admin';
import { colors } from '../styles/colors';
import Logomark_Gradient_64px from '../assets/Logomark_Gradient_64px.png'

export default function LoggedInApp() {
	const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

	// TODO: Standardize this across components; possibly use useMediaQuery from '@mui/material' or a defined constant
	const isMobile = useMediaQuery({ maxWidth: 767 });

	if (isMobile) {
		return (
			<Providers>
				<LoggedInAppContainerMobile>
					<MobileAppBar position="sticky">
						<MobileToolbar>
							<IconButton onClick={() => setMobileNavIsOpen(true)}>
								<FiMenu color={colors.jetpackMint} size={24} />
							</IconButton>
							<LogoAndTitle>
								<Logomark src={Logomark_Gradient_64px} alt="Jetpack Logo" />
							</LogoAndTitle>
							{/* Uncomment the next line if we want the logo to be in the center of the toolbar */}
							{/* <div style={{width: 24, height: 24, paddingLeft: 8, paddingRight: 8}} /> */}
						</MobileToolbar>
					</MobileAppBar>
					<Drawer
						open={mobileNavIsOpen}
						onClose={() => setMobileNavIsOpen(false)}
					>
						<Navbar
							isMobile={true}
							closeMobileNav={() => setMobileNavIsOpen(false)}
						/>
					</Drawer>
					<PageArea>
						<Pages />
					</PageArea>
				</LoggedInAppContainerMobile>
			</Providers>
		);
	} else {
		return (
			<Providers>
				<LoggedInAppContainerDesktop>
					<NavbarAreaDesktop>
						<Navbar isMobile={false} />
					</NavbarAreaDesktop>
					<PageArea>
						<Pages />
					</PageArea>
				</LoggedInAppContainerDesktop>
			</Providers>
		);
	}
}

function Providers({children}: ProvidersProps) {
	return (
		<UserProvider>
			<FriendProvider>
				<ChatProvider>
					<SparkProvider>
						{children}
					</SparkProvider>
				</ChatProvider>
			</FriendProvider>
		</UserProvider>
	);
}

function Pages() {
	// TODO: Redirect uncaught URLs to Home
	return (
		<Switch>
			<Route exact path={ROUTES.HOME} component={HomePage} />
			<Route exact path={ROUTES.ACCEPT_INVITATION} component={AcceptInvitation} />
			<Route exact path={ROUTES.SPARKS} component={SparksPage} />
			<Route exact path={ROUTES.COLLABORATION} component={CollaborationPage} />
			<Route exact path={ROUTES.GROUP_SCHEDULING} component={GroupSchedulingMainPage} />
			<Route exact path={ROUTES.GROUP_SCHEDULING_INVITE} component={GroupSchedulingInviteLandingPage} />
			<Route path={ROUTES.GROUP_SCHEDULING + '/:eventId/owner'} component={GroupSchedulingOwnerPage} />
			<Route path={ROUTES.GROUP_SCHEDULING + '/:eventId'} component={GroupSchedulingParticipantPage} />
			<Route exact path={ROUTES.SETTINGS} component={SettingsPage} />
			<Route exact path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmail} />
			<Route exact path={ROUTES.GOOGLE_CALENDAR_OAUTH_REDIRECT} component={GoogleCalendarOAuthRedirect} />
			<Route exact path={ROUTES.OUTLOOK_CALENDAR_OAUTH_REDIRECT} component={OutlookCalendarOAuthRedirect} />
			<AdminRoute exact path={ROUTES.ADMIN}>
				<Admin />
			</AdminRoute>
		</Switch>
	);
}

function AdminRoute({ children, ...rest }: any) {
  let user = useUser();

  // TODO: Make location state type more robust (store it somewhere and reference it everywhere)
  let location = useLocation<{from: string}>();

	if (user.loading) {
		return (
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<CircularProgress />
			</div>
		);
	} else if (user.getRoles().includes('admin')) {
    // User is admin
    return (
      <Route
        {...rest}
        render={() => children}
      />
    );
  } else {
    // Not admin. Store path to location state and redirect to home screen.
    console.warn('User is not admin. Redirecting to home.');
		return (
      <Redirect
        to={{
          pathname: ROUTES.HOME,
          state: { from: location.pathname },
          search: location.search
        }}
      />
    );
  }
}

const LoggedInAppContainerDesktop = styled.div`
	width: 100vw;
	height: 100vh;
	display: grid;
	grid-template-columns: [left-edge] max-content [navbar-end] auto [right-edge];
`;

const NavbarAreaDesktop = styled.div`
	grid-column-start: left-edge;
	grid-column-end: navbar-end;
	height: 100vh;
`;

const PageArea = styled.div`
	height: 100vh;
	overflow: scroll;
`;

const LoggedInAppContainerMobile = styled.div`
	height: 100vh;
	width: 100vw;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
`;

const MobileAppBar = styled(AppBar)`
	background: ${colors.gray1};
`;

const MobileToolbar = styled(Toolbar)`
	justify-content: space-between;
`;

const LogoAndTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Logomark = styled.img`
	height: 24px;
	width: 24px;
	align-self: 'center';
`;

// --------------------
// Types
// --------------------

type ProvidersProps = {children: React.ReactNode};