import React from 'react';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';
import { Dialog } from '@mui/material';
import { useSparks } from '../utilities/sparkContext';
import NewSparkWithData from './NewSparkWithData';
import SparkListWithData from './SparkListWithData';
import SparkDetailWithData from './SparkDetailWithData';
import { colors } from '../styles/colors';

export default function Home() {
	const sparkContext = useSparks();

	// TODO: Standardize this across components; possibly use useMediaQuery from '@mui/material' or a defined constant
	const isMobile = useMediaQuery({ maxWidth: 767 });

	if (isMobile) {
		return (
			<HomeContainerMobile>
				<SparkListAreaMobile>
					<NewSparkWithData />
					<SparkListWithData />
				</SparkListAreaMobile>
				<Dialog
					open={sparkContext.isSparkDetailOpen()}
					onClose={() => sparkContext.closeSparkDetail()}
					fullWidth={true}
				>
					<SparkDetailWithData />
				</Dialog>
			</HomeContainerMobile>
		);
	} else {
		return (
			<HomeGridContainerDesktop>
				<SparkListAreaDesktop>
					<NewSparkWithData />
					<SparkListWithData />
				</SparkListAreaDesktop>
				<SparkDetailAreaDesktop>
					<SparkDetailWithData />
				</SparkDetailAreaDesktop>
			</HomeGridContainerDesktop>
		);
	}
}

const HomeGridContainerDesktop = styled.div`
	width: 100%;
	height: 100%;
	max-height: 100vh;
	display: grid;
	grid-template-columns: [left-edge] min(50%, 512px) [spark-list-end] auto [right-edge];
	background: ${colors.jetpackBackgroundDark};
`;

const SparkListAreaDesktop = styled.div`
	grid-column-start: left-edge;
	grid-column-end: spark-list-end;
	overflow: scroll;
	box-sizing: border-box;
	padding: 24px 12px 24px 24px;
`;

const SparkDetailAreaDesktop = styled.div`
	grid-column-start: spark-list-end;
	grid-column-end: right-edge;
	height: 100vh;
	box-sizing: border-box;
	padding: 24px 24px 24px 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const HomeContainerMobile = styled.div`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background: ${colors.jetpackBackgroundDark};
`;

const SparkListAreaMobile = styled.div`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: scroll;
	box-sizing: border-box;
	padding: 24px 24px 24px 24px;
`;