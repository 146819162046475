import React, { createContext, useContext, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { environmentType } from './environmentUtils';

const MIXPANEL_TOKEN_TEST = 'a50d91fb88f7fff64ff0e42acd061c99';
const MIXPANEL_TOKEN_PROD = '1c6c7a34b431c5b9f1bc96355b18b347';

export const events = {
	AcceptedGroupSchedulingEventInvitation: 'AcceptedGroupSchedulingEventInvitation',
	AddedCalendarAccount: 'AddedCalendarAccount',
	AddedFriendToGroupSchedulingEvent: 'AddedFriendToGroupSchedulingEvent',
	AddedRegisteredEmailAddress: 'AddedRegisteredEmailAddress',
	AddedRegisteredPhoneNumber: 'AddedRegisteredPhoneNumber',
	ClickedAddOutlookCalendarButton: 'ClickedAddOutlookCalendarButton',
	CopiedFriendRequestLink: 'CopiedFriendRequestLink',
	CreatedGroupSchedulingEvent: 'CreatedGroupSchedulingEvent',
	DeletedGroupSchedulingEvent: 'DeletedGroupSchedulingEvent',
	LoggedIn: 'LoggedIn',
	LoggedOut: 'LoggedOut',
	RemovedCalendarAccount: 'RemovedCalendarAccount',
	RemovedRegisteredEmailAddress: 'RemovedRegisteredEmailAddress',
	RemovedRegisteredPhoneNumber: 'RemovedRegisteredPhoneNumber',
	RequestedOutlookCalendarWaitlist: 'RequestedOutlookCalendarWaitlist',
	SetPrimaryCalendarAccount: 'SetPrimaryCalendarAccount',
	UpdatedDisplayName: 'UpdatedDisplayName',
	UpdatedGroupSchedulingCalendarResponseForSelf: 'UpdatedGroupSchedulingCalendarResponseForSelf',
	UpdatedGroupSchedulingManualFreeBusyTime: 'UpdatedGroupSchedulingManualFreeBusyTime'
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

export function AnalyticsProvider(props: AnalyticsProviderProps) {
	useEffect(() => {
		const mixpanelToken = environmentType === 'prod' ? MIXPANEL_TOKEN_PROD : MIXPANEL_TOKEN_TEST;
		mixpanel.init(mixpanelToken);
	}, [])

	const setUser = (loginUserId: string) => {
		mixpanel.identify(loginUserId);
		mixpanel.register({
			distinct_id: loginUserId,
			user_id: loginUserId
		});
	}

	const unsetUser = () => {
		mixpanel.reset();
	}

	const trackEvent = (eventName: string, eventProps: any) => {
		// Default props can also go in setUser() under mixpanel.register().
		const defaultProps: any = {};

		const enrichedEventProps = {...defaultProps, ...eventProps};

		mixpanel.track(eventName, enrichedEventProps);
	}

	const forProvider = {
		setUser,
		unsetUser,
		trackEvent
	};

	return (
		<AnalyticsContext.Provider value={forProvider}>
			{props.children}
		</AnalyticsContext.Provider>
	);
}

export function useAnalytics() {
	const context = useContext(AnalyticsContext);
	if (context === undefined) {
		throw new Error('useAnalytics must be used within an AnalyticsProvider.');
	}
	return context;
}

// --------------------
// Types
// --------------------

type AnalyticsContextType = {
	setUser: (loginUserId: string) => void;
	unsetUser: () => void;
	trackEvent: (eventName: string, eventProps?: any) => void;
}

type AnalyticsProviderProps = {
	children: React.ReactNode;
}