import { createTheme } from '@mui/material/styles';
import { colors } from './colors';

export const theme = createTheme({
	palette: {
		primary: {
			main: colors.blue
		},
		secondary: {
			main: colors.yellow
		},
		error: {
			main: colors.red
		},
		warning: {
			main: colors.orange
		},
		info: {
			main: colors.purple
		},
		success: {
			main: colors.green
		},
		text: {
			primary: colors.textDark,
			secondary: colors.textDarkAccent
		}
	},
	typography: {
		fontFamily: [
			'Inter',
			'-apple-system',
			'BlinkMacSystemFont',
			'sans-serif',
		].join(',')
	}
});

export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: colors.blueLight
		},
		secondary: {
			main: colors.yellow
		},
		error: {
			main: colors.red
		},
		warning: {
			main: colors.orange
		},
		info: {
			main: colors.purple
		},
		success: {
			main: colors.green
		},
		text: {
			primary: colors.textLight,
			secondary: colors.textLightAccent
		},
	},
	typography: {
		fontFamily: [
			'Inter',
			'-apple-system',
			'BlinkMacSystemFont',
			'sans-serif',
		].join(',')
	},
	components: {
		MuiRadio: {
			styleOverrides: {
				root: {
					color: colors.white,
				}
			}
		}
	}
});