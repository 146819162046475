import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { Button, InputBase, Link } from '@mui/material';
import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { AnimatePresence, motion } from 'framer-motion';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { FiCheckCircle, FiCalendar, FiTrash2, FiCircle, FiMail, FiX, FiMessageCircle } from 'react-icons/fi';
import { useMediaQuery } from 'react-responsive';
import DOMPurify from 'dompurify';

import JetpackCard from './JetpackCard';
import { colors } from '../styles/colors';
import { Spark } from '../types/jetpack/spark';

const DEFAULT_DUE_DATE = DateTime.now().plus({ days: 1});

export default function SparkDetail(props: SparkDetailProps) {
	const spark = props.spark;
	// const [titleFromInput, setTitleFromInput] = useState('');
	// const [noteFromInput, setNoteFromInput] = useState('');
	const [dueDatePickerOpen, setDueDatePickerOpen] = useState(false);

	// TODO: Standardize this across components; possibly use useMediaQuery from '@mui/material' or a defined constant
	const isMobile = useMediaQuery({ maxWidth: 767 });

	const handleTaskIconClick = () => {
		const updatedSpark: Spark = {...spark};
		updatedSpark['isDone'] = spark?.isDone ? false : true;
		props.onUpdateSpark(updatedSpark);
	}
	
	const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const newTitle = e.target.value;
		const updatedSpark: Spark = {...spark};
		updatedSpark['title'] = newTitle;
		// setTitleFromInput(newTitle);
		props.onUpdateSparkDebounced(updatedSpark);
	}

	const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const newNote = e.target.value;
		const updatedSpark: Spark = {...spark};
		updatedSpark['note'] = newNote;
		// setNoteFromInput(newNote);
		props.onUpdateSparkDebounced(updatedSpark);
	}

	const handleDeleteSpark = () => {
		props.onDeleteSpark(spark?.id!);
	}

	const handleToggleIsTask = () => {
		const updatedSpark: Spark = {...spark};
		updatedSpark['isTask'] = spark && spark['isTask'] ? false : true;
		props.onUpdateSpark(updatedSpark);
		props.onDeleteField(spark!.id!, 'dueDate');
	}

	const handleDueDateChange = (date: any) => {
		const updatedSpark: Spark = {...spark};

		if (date === null) {
			// Due date has been cleared
			props.onDeleteField(spark!.id!, 'dueDate');
		} else {
			// Due date has been set
			const luxonDate = new LuxonUtils().date(date);
			const luxonDateToSave = DateTime.fromObject({
				year: luxonDate.year,
				month: luxonDate.month,
				day: luxonDate.day,
				hour: 12,
				minute: 0,
				zone: 'utc'
			});
			const jsDate = luxonDateToSave.toJSDate();
			updatedSpark['dueDate'] = jsDate;
			updatedSpark['isTask'] = true;
			props.onUpdateSpark(updatedSpark);
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterLuxon}>
			<MotionWrapper isVisible={props.isVisible} isMobile={isMobile}>
				<SparkDetailContainer
					elevation={3}
				>
					{!!spark ? 
						<Fragment>
						<TaskTitleAndClose>
							<SparkDetailContentHeader>
								<TitleText
									defaultValue={props.spark?.title}
									key={'Title-' + spark.id}
									onChange={handleTitleChange}
									multiline={true}
								/>
								<TaskAndDueDateLine>
									{spark.isTask ?
										<TaskIcon
											isDone={spark.isDone ? true : false}
											onClick={handleTaskIconClick}
										/> :
									null}
									<DueDateText>
										{spark.dueDate ?
											`Due ${DateTime.fromJSDate(spark.dueDate).toRelativeCalendar({ unit: 'days' })}` :
											(spark.isTask ?
												<Link
													onClick={() => setDueDatePickerOpen(true)}
													color='textSecondary'
													underline='hover'
													style={{cursor: 'pointer'}}
												>
													Click to add a due date
												</Link> :
												null
											)
										}
									</DueDateText>
								</TaskAndDueDateLine>
							</SparkDetailContentHeader>
							<div style={{display: 'flex', flexDirection: 'column'}}>
								<ActionIcon
									isActive={false}
									onClick={props.onCloseSpark}
								>
									<FiX size={24} />
								</ActionIcon>
								<div style={{height: '100%'}} />
							</div>
						</TaskTitleAndClose>
						<SparkDetailContent>
							<NoteText
								defaultValue={props.spark?.note}
								placeholder={'Add a note...'}
								key={'Note-' + spark.id}
								onChange={handleNoteChange}
								multiline={true}
							/>
							<Tags>
								<InboundMessageSection spark={props.spark} />
							</Tags>
							<SparkDetailActions>
								<ActionIcon
									isActive={!!spark.isTask}
									onClick={() => handleToggleIsTask()}
								>
									<FiCheckCircle size={24} />
								</ActionIcon>
								<div style={{width: 16}} />
								<ActionIcon
									isActive={!!spark.dueDate}
									onClick={() => setDueDatePickerOpen(!dueDatePickerOpen)}
								>
									<FiCalendar size={24} />
								</ActionIcon>
								<div style={{width: 16}} />
								<ActionIcon
									onClick={() => handleDeleteSpark()}
								>
									<FiTrash2 size={24} />
								</ActionIcon>
							</SparkDetailActions>
						</SparkDetailContent>
						<MobileDatePicker
							value={spark?.dueDate ? spark.dueDate : DEFAULT_DUE_DATE}
							onChange={() => null}
							clearable={true}
							open={dueDatePickerOpen}
							onOpen={() => setDueDatePickerOpen(true)}
							onClose={() => setDueDatePickerOpen(false)}
							onAccept={handleDueDateChange}
							renderInput={() => <div />}
						/>
						</Fragment>
					: null}
				</SparkDetailContainer>
			</MotionWrapper>
		</LocalizationProvider>
	)
};

function InboundMessageSection(props: InboundMessageSectionProps) {
	const [inboundMessageVisible, setInboundMessageVisible] = useState(false);

	if (props.spark && props.spark.inboundMessage?.channel) {
		const channel = props.spark.inboundMessage.channel;
		switch (channel) {
			case 'appChat':
				if (props.spark.inboundMessage.appChat?.body) {
					return (
						<TagSection>
							<TagSectionHeader>Created from app message</TagSectionHeader>
							<ViewInboundMessageButton onClick={() => setInboundMessageVisible(!inboundMessageVisible)}><FiMessageCircle size={24} /><span style={{width: 8}} />{inboundMessageVisible ? 'Hide message' : 'View message'}</ViewInboundMessageButton>
							<div style={{height: 8}} />
							{inboundMessageVisible && props.spark.inboundMessage.appChat.body ? <div style={{wordBreak: 'break-word'}}>{props.spark.inboundMessage.appChat.body}</div>: null}
						</TagSection>
					);
				} else return null;
			case 'email':
				if (props.spark?.inboundMessage.email?.bodyHtml) {
					return (
						<TagSection>
							<TagSectionHeader>Created from email</TagSectionHeader>
							<ViewInboundMessageButton onClick={() => setInboundMessageVisible(!inboundMessageVisible)}><FiMail size={24} /><span style={{width: 8}} />{inboundMessageVisible ? 'Hide email' : 'View email'}</ViewInboundMessageButton>
							<div style={{height: 8}} />
							{inboundMessageVisible && props.spark.inboundMessage.email.subject ? <div>{'Subject: ' + props.spark.inboundMessage.email.subject}<br /><br /></div>: null}
							{inboundMessageVisible ? <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.spark?.inboundMessage?.email?.bodyHtml)}} style={{wordBreak: 'break-word'}} /> : null}
						</TagSection>
					);
				} else return null;
			case 'sms':
				if (props.spark?.inboundMessage.sms?.body) {
					return (
						<TagSection>
							<TagSectionHeader>Created from text message</TagSectionHeader>
							<ViewInboundMessageButton onClick={() => setInboundMessageVisible(!inboundMessageVisible)}><FiMessageCircle size={24} /><span style={{width: 8}} />{inboundMessageVisible ? 'Hide text' : 'View text'}</ViewInboundMessageButton>
							<div style={{height: 8}} />
							{inboundMessageVisible && props.spark.inboundMessage.sms.body ? <div style={{wordBreak: 'break-word'}}>{props.spark.inboundMessage.sms.body}</div>: null}
						</TagSection>
					);
				} else return null;
			default:
				return null;
		}
	} else {
		return null;
	}
}

function TaskIcon(props: TaskIconProps) {
	return (
		<TaskIconWrapper
			onClick={e => {
				e.stopPropagation();
				props.onClick();
			}}
		>
			{props.isDone ? <FiCheckCircle size={24} /> : <FiCircle size={24} />}
		</TaskIconWrapper>
	)
}

const MotionWrapper = ({ isVisible, isMobile, children }: {isVisible: boolean, isMobile: boolean, children: JSX.Element}) => {
	if (isMobile) {
		return children;
	} else {
		return (
			<AnimatePresence>
				{isVisible && <motion.div
					initial={{ translateX: -50, opacity: 0 }}
					animate={{ translateX: 0, opacity: 1 }}
					exit={{ translateX: -50, opacity: 0 }}
					transition={{
						type: 'spring',
						damping: 30,
						stiffness: 500
					}}
					style={{
						height: '100%',
						width: '100%'
					}}
				>
					{children}
				</motion.div>}
			</AnimatePresence>
		);
	} 
}

const SparkDetailContainer = styled(JetpackCard)`
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: 24px;
	display: flex;
	flex-direction: column;
`;

const TaskTitleAndClose = styled.div`
	width: 100%;
	margin-bottom: 8px;
	display: flex;
	flex-direction: row;
`;

const TaskIconWrapper = styled.div`
	margin-right: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	color: ${colors.gray3};
	:hover {
		color: ${colors.gray1};
		cursor: pointer;
	}
`;

const SparkDetailContentHeader = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const SparkDetailContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	overflow: scroll;
`;

const SparkDetailActions = styled.div`
	display: flex;
	justify-self: flex-end;
	flex-direction: row;
	justify-content: flex-start;
`;

const ActionIcon = styled.div<ActionIconProps>`
	vertical-align: middle;
	color: ${props => props.isActive ? colors.blue : colors.gray3};
	:hover {
		color: ${props => props.isActive ? colors.blueDark : colors.gray1};
		cursor: pointer;
	}
`;

const TitleText = styled(InputBase)`
	width: 100%;
	font-weight: bold;
`;

const TaskAndDueDateLine = styled.div`
	display: flex;
	flex-direction: row;
	align-items: 'center';
`;

const DueDateText = styled.div`
	align-self: center;
	color: ${colors.textDarkAccent};
	font-style: italic;
`;

const NoteText = styled(InputBase)`
	width: 100%;
	margin-bottom: 16px;
	white-space: pre-wrap;
`;

const Tags = styled.div`
	margin-bottom: 16px;
`;

const TagSection = styled.div`
	display: flex;
	flex-direction: column;
`;

const TagSectionHeader = styled.div`
	margin-bottom: 8px;
	font-weight: bold;
	color: ${colors.textDark};
`;

const ViewInboundMessageButton = styled(Button)`
	width: fit-content;
	padding: 16px;
	border: none;
	border-radius: 32px;
	background-color: ${colors.gray6};
	color: ${colors.textDark};
	text-transform: none;
	font-weight: bold;
`;

// --------------------
// Types
// --------------------

export interface SparkDetailProps {
	spark: Spark | null;
	isVisible: boolean;
	onCloseSpark: () => void;
	onDeleteSpark: (sparkId: string) => void;
	onUpdateSpark: (spark: Spark) => void;
	onUpdateSparkDebounced: (spark: Spark) => void;
	onDeleteField: (sparkId: string, fieldName: string) => void;
	componentRef?: any | null;
}

type InboundMessageSectionProps = {
	spark: Spark | null;
}

type TaskIconProps = {
	isDone?: boolean;
	onClick: () => void;
}

type ActionIconProps = {
	isActive?: any;
}