import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';

import { useFirebase } from '../utilities/firebaseContext';
import { useUser } from '../utilities/userContext';
import { useAnalytics, events } from './analyticsContext';
import { sortAndUnionTimeIntervals, subtractTimeIntervals } from './scheduleHelpers';
import { groupSchedulingGetBusyTimesEndpoint } from '../constants/cloudFunctionEndpoints';
import * as ROUTES from '../constants/routes';
import { FreeBusyStatus, TimeIntervalEpochSeconds } from '../types/jetpack/collaboration';

const getBusyTimesEndpoint = groupSchedulingGetBusyTimesEndpoint();

const GroupSchedulingContext = createContext<GroupSchedulingContextType | undefined>(undefined);

export function GroupSchedulingProvider(props: GroupSchedulingProviderProps) {
	const { trackEvent } = useAnalytics();
	const [eventId, setEventId] = useState<string>(props.eventId);
	const [userIsOwner, setUserIsOwner] = useState(false);
	const [title, setTitle] = useState<string>('');
	const [availableStart, setAvailableStart] = useState<number | undefined>(undefined);
	const [availableEnd, setAvailableEnd] = useState<number | undefined>(undefined);
	const [availableIntervals, setAvailableIntervals] = useState<Array<TimeIntervalEpochSeconds>>([]);
	const [gotCalendarResponse, setGotCalendarResponse] = useState<boolean>(false);
	const [calendarBusyIntervals, setCalendarBusyIntervals] = useState<Array<TimeIntervalEpochSeconds>>([]);
	const [manualFreeIntervals, setManualFreeIntervals] = useState<Array<TimeIntervalEpochSeconds>>([]);
	const [manualBusyIntervals, setManualBusyIntervals] = useState<Array<TimeIntervalEpochSeconds>>([]);
	const [noCalendarsFound, setNoCalendarsFound] = useState(false);
	const [gettingBusyTimes, setGettingBusyTimes] = useState(true);
	const [updateAfterGetBusyTimes, setUpdateAfterGetBusyTimes] = useState(0);
	const hasTrackedManualFreeBusyUpdate = useRef(false);

	const firebaseContext = useFirebase();
	const auth = firebaseContext.Auth;
	const firestore = firebaseContext.Firestore;
	const userId = auth!.currentUser!.uid;

	const userContext = useUser();
	const displayName = userContext.user?.displayName || auth?.currentUser?.displayName || auth?.currentUser?.email || 'User name not found';

	const history = useHistory();

	useEffect(() => {
		// --------------------------------------------------
		// Effect for getting event with a given eventId
		// --------------------------------------------------

		if (props.doNotRunEffects) {
			return;
		}

		if (!props.eventId) {
			return;
		} else {
			firestore?.collection('groupSchedulingEvents').doc(props.eventId)
				.get()
				.then(response => {
					if (!response.exists) {
						console.error('No event with specified event ID.');
						history.push(ROUTES.COLLABORATION);
					} else {
						return response.data();
					}
				})
				.then(data => {
					if (!data) {
						throw new Error('No group scheduling event data received from Firestore.')
					}

					if (data.users && userId in data.users && data.users[userId].isOwner) {
						setUserIsOwner(true);
					}

					if (data.title) {
						setTitle(data.title);
					}

					if (data.availableIntervals) {
						setAvailableIntervals(data.availableIntervals);
					}

					if (data.availableStart) {
						setAvailableStart(data.availableStart);
					} else {
						console.error('No availableStart found in Firestore.');
					}
					if (data.availableEnd) {
						setAvailableEnd(data.availableEnd);
					} else {
						console.error('No availableEnd found in Firestore.');
					}

					if (data.responses && data.responses[userId] && data.responses[userId].gotCalendarResponse) {
						setGotCalendarResponse(data.responses[userId].gotCalendarResponse);
					}
					if (data.responses && data.responses[userId] && data.responses[userId].calendarBusyIntervals) {
						setCalendarBusyIntervals(data.responses[userId].calendarBusyIntervals);
					}
					if (data.responses && data.responses[userId] && data.responses[userId].manualFreeIntervals) {
						setManualFreeIntervals(data.responses[userId].manualFreeIntervals);
					}
					if (data.responses && data.responses[userId] && data.responses[userId].manualBusyIntervals) {
						setManualBusyIntervals(data.responses[userId].manualBusyIntervals);
					}
				})
				.catch(error => {
					console.error(error);
				});
		}
	}, [props.eventId, updateAfterGetBusyTimes, userId, firestore, history, props.doNotRunEffects])
	
	const addCurrentUserToEvent = (eventIdFromParams?: string) => {
		const eventId = eventIdFromParams || props.eventId;

		firestore?.collection('groupSchedulingEvents').doc(eventId)
			.update({
				[`users.${userId}`]: {
					isOwner: false,
					displayName: displayName
				}
			})
			.then(() => {
				trackEvent(events.AcceptedGroupSchedulingEventInvitation, {event_id: eventId});
			})
			.catch(error => {
				console.error('(addUser) Error adding user to group scheduling event.');
				console.error(error);
			});
	}

	// We use useCallback so that getBusyTimes is only declared once.
	// Redeclaration would cause useEffect to be called on every render, which would in turn cause a re-render.
	// This would continue in a loop forever.
	const getBusyTimes = useCallback(async () => {
		if (!availableStart || !availableEnd) {
			return;
		}

		const requestData = {
			availableStart: availableStart,
			availableEnd: availableEnd
		};

		setGettingBusyTimes(true);

		return auth?.currentUser?.getIdToken()
			.then(idToken => {
				const authHeader = 'Bearer ' + idToken;
				const url = getBusyTimesEndpoint;
				return fetch(url, {
					method: 'POST',
					mode: 'cors',
					headers: {
						Authorization: authHeader,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(requestData)
				})
			})
			.then(response => response.json())
			.then(data => {
				if (data.busyTimes && data.gotCalendarResponse) {
					const calendarBusyIntervals: Array<TimeIntervalEpochSeconds> = data.busyTimes;

					setCalendarBusyIntervals(calendarBusyIntervals);

					const responseKey = 'responses.' + auth.currentUser?.uid ;
					const batch = firestore!.batch();
					const docRef = firestore!.collection('groupSchedulingEvents').doc(eventId);
					return batch.update(docRef, {
							[responseKey + 'calendarBusyIntervals']: firebase.firestore.FieldValue.delete()
						})
						.update(docRef, {
							[responseKey]: {
								calendarBusyIntervals: calendarBusyIntervals,
								gotCalendarResponse: data.gotCalendarResponse
							}
						})
						.commit()
						.then(() => {
							setUpdateAfterGetBusyTimes(count => count + 1);
							setGettingBusyTimes(false);
						})
						.then(() => {
							trackEvent(events.UpdatedGroupSchedulingCalendarResponseForSelf, {user_id: userId, event_id: eventId});
						})
						.catch(error => {
							console.error('(getBusyTimes) Error updating busy times to Firestore.');
							console.error(error);
						});
				} else if (data.code) {
					setNoCalendarsFound(true);
				}
			})
			.catch(error => {
				console.error('(getBusyTimes) Error getting busy times from backend.');
				console.error(error);
			})
		;
	}, [auth?.currentUser, availableStart, availableEnd, eventId, userId, trackEvent, firestore])

	// Get busy times from user's calendar each time the event, start time, or end time change.
	useEffect(() => {
		// --------------------------------------------------
		// Effect for getting busy times from the user's connected calendars
		// --------------------------------------------------

		if (props.doNotRunEffects) {
			return;
		}

		if (availableStart && availableEnd) {
			getBusyTimes();
		}
	}, [props.eventId, availableStart, availableEnd, getBusyTimes, props.doNotRunEffects])

	const manuallyEditFreeBusy = (interval: TimeIntervalEpochSeconds, newStatus: FreeBusyStatus) => {
		let newFreeArray: Array<TimeIntervalEpochSeconds>;
		let newBusyArray: Array<TimeIntervalEpochSeconds>;

		switch (newStatus) {
			case 'free':
				// If newStatus is 'free', add to free array and subtract from busy array.
				newFreeArray = sortAndUnionTimeIntervals([...manualFreeIntervals, ...[interval]]);
				newBusyArray = subtractTimeIntervals([interval], manualBusyIntervals);
				break;
			case 'busy':
				// If newStatus is 'busy', add to busy array and subtract from free array.
				newFreeArray = subtractTimeIntervals([interval], manualFreeIntervals);
				newBusyArray = sortAndUnionTimeIntervals([...manualBusyIntervals, ...[interval]]);
				break;
			default:
				// If newStatus is null, subtract from both free and busy arrays.
				newFreeArray = subtractTimeIntervals([interval], manualFreeIntervals);
				newBusyArray = subtractTimeIntervals([interval], manualBusyIntervals);
		}

		setManualFreeIntervals(newFreeArray);
		setManualBusyIntervals(newBusyArray);

		const freeKey = 'responses.' + userId + '.manualFreeIntervals';
		const busyKey = 'responses.' + userId + '.manualBusyIntervals';
		firestore?.collection('groupSchedulingEvents').doc(eventId)
			.update({
				[freeKey]: newFreeArray,
				[busyKey]: newBusyArray
			})
			.then(() => {
				// Only track this once per component lifecycle.
				if (!hasTrackedManualFreeBusyUpdate.current) {
					trackEvent(events.UpdatedGroupSchedulingManualFreeBusyTime, {event_id: eventId});
					hasTrackedManualFreeBusyUpdate.current = true;
				}
			})
			.catch(error => console.error(error));
	}
	
	const forProvider = {
		eventId,
		userIsOwner,
		title,
		availableStart,
		availableEnd,
		availableIntervals,
		gotCalendarResponse,
		calendarBusyIntervals,
		manualFreeIntervals,
		manualBusyIntervals,
		noCalendarsFound,
		gettingBusyTimes,
		setEventId,
		addCurrentUserToEvent,
		setAvailableStart,
		setAvailableEnd,
		manuallyEditFreeBusy,
		getBusyTimes
	}

	return (
		<GroupSchedulingContext.Provider value={forProvider}>
			{props.children}
		</GroupSchedulingContext.Provider>
	);
}

export function useGroupScheduling() {
	const context = useContext(GroupSchedulingContext);
	if (context === undefined) {
		throw new Error('useGroupScheduling must be used within a GroupSchedulingProvider.');
	}
	return context;
}

// --------------------
// Types
// --------------------

type GroupSchedulingContextType = {
	eventId: string;
	userIsOwner: boolean;
	title: string;
	availableStart: number | undefined;
	availableEnd: number | undefined;
	availableIntervals: Array<TimeIntervalEpochSeconds>;
	gotCalendarResponse: boolean;
	calendarBusyIntervals: Array<TimeIntervalEpochSeconds>;
	manualFreeIntervals: Array<TimeIntervalEpochSeconds>;
	manualBusyIntervals: Array<TimeIntervalEpochSeconds>;
	noCalendarsFound: boolean;
	gettingBusyTimes: boolean;
	setEventId: React.Dispatch<React.SetStateAction<string>>;
	addCurrentUserToEvent: () => void;
	setAvailableStart: React.Dispatch<React.SetStateAction<number | undefined>>;
	setAvailableEnd: React.Dispatch<React.SetStateAction<number | undefined>>;
	manuallyEditFreeBusy: (interval: TimeIntervalEpochSeconds, newStatus: FreeBusyStatus) => void;
	getBusyTimes: () => Promise<void>;
}

type GroupSchedulingProviderProps = {
	children: React.ReactNode;
	eventId: string;
	doNotRunEffects?: boolean;		// Use this prop to avoid running useEffects, which are only used in conjunction with an eventId.
}