import React from 'react';
import { useSparks } from '../utilities/sparkContext';
import SparkDetail from '../components/SparkDetail';
import { Spark } from '../types/jetpack/spark';

export default function SparkDetailWithData({children}: any) {
	const sparkContext = useSparks();

	const selectedSparkId = sparkContext.selectedSparkId;
	let selectedSpark: Spark | null = null;

	if (sparkContext.selectedSparkId) {
		const foundSpark = sparkContext.sparks.find((thisSpark: Spark) => {
			return thisSpark.id === selectedSparkId;
		});
		if (foundSpark) selectedSpark = foundSpark;
	}

	return (
		<SparkDetail
			spark={selectedSpark}
			isVisible={sparkContext.isSparkDetailOpen()}
			onCloseSpark={sparkContext.closeSparkDetail}
			onDeleteSpark={sparkContext.deleteSpark}
			onUpdateSpark={sparkContext.updateSpark}
			onUpdateSparkDebounced={sparkContext.updateSparkDebounced}
			onDeleteField={sparkContext.deleteField}
		/>
	);
}