import React from 'react';
import { useSparks } from '../utilities/sparkContext';
import NewSpark from './NewSpark';

export default function NewSparkWithData({children}: any) {
	const sparkContext = useSparks();

	return (
		<NewSpark
			handleCreateSpark={sparkContext.createSpark}
		/>
	)
}