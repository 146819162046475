import remoteConfigTemplate from './remoteConfigTemplate.json';

let defaultConfigBuilder: DefaultConfig = {};

for (const [key, value] of Object.entries(remoteConfigTemplate.parameters)) {
	defaultConfigBuilder[key] = value.defaultValue.value;
}

export const defaultConfig = defaultConfigBuilder;

// --------------------
// Types
// --------------------

export type Features = keyof typeof remoteConfigTemplate.parameters;

type DefaultConfig = {
	[key: string]: string | number | boolean;
};