const baseColors = {
	white: '#ffffff',
	black: '#000000',
	gray1: '#333333',
	gray2: '#4f4f4f',
	gray3: '#828282',
	gray4: '#bdbdbd',
	gray5: '#e0e0e0',
	gray6: '#f2f2f2'
}

const jetpackColors = {
	jetpackCoral: '#f09595',
	jetpackOrange: '#ffd271',
	jetpackYellow: '#fcf5b0',
	jetpackMint: '#a3f7bf',
	jetpackMintLight: '#d6ffe4',
	jetpackMintSuperLight: '#ebfff1',
	jetpackAqua: '#51eaea',
	jetpackAquaLight: '#d6ffff',
	jetpackAquaSuperLight: '#ebffff',
	jetpackBackgroundDark: '#000000'
}

const paletteColors = {
	blue: '#2979ff',
	blueDark: '#004ecb',
	blueLight: '#75a7ff',
	yellow: '#ffff8d',
	red: '#eb5757',
	orange: '#f2994a',
	purple: '#bb6bd9',
	green: '#27ae60',
	greenDark: '#007d34',
	greenLight: '#64e18e'
}

// eslint-disable-next-line
const lightThemeColors = {
	backgroundPrimary: baseColors.white,
	textDark: baseColors.gray1,
	textDarkAccent: baseColors.gray3,
	textLight: baseColors.white,
	textLightAccent: jetpackColors.jetpackMint,
	dropShadow: baseColors.gray1,
}

// eslint-disable-next-line
const darkThemeColors = {
	backgroundPrimary: jetpackColors.jetpackBackgroundDark,
	textPrimary: baseColors.white,
	textDark: baseColors.gray1,
	textDarkAccent: baseColors.gray3,
	textLight: baseColors.white,
	textLightAccent: jetpackColors.jetpackMint,
	dropShadow: jetpackColors.jetpackMint
}

const themeColors = darkThemeColors;

export const colors = {
	...baseColors,
	...paletteColors,
	...jetpackColors,
	...themeColors
};

export const componentColors = {
	navbarBackground: '#111111'
}