import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { Dialog, DialogActions, DialogContent, DialogContentText, Popover } from '@mui/material';
import { FiCopy, FiMoreHorizontal } from 'react-icons/fi';

import { useFriends, UnfriendType, CreateFriendRequestType } from '../utilities/friendContext';
import { useAnalytics, events } from '../utilities/analyticsContext';
import { JetpackButton } from './JetpackComponents';
import JetpackCard from './JetpackCard';
import { useJetpackSnackbar } from './Snackbar';
import { colors } from '../styles/colors';
import { FriendsObject } from '../types/jetpack/collaboration';

export default function CollaborationPageContextWrapper() {
	return (
		<CollaborationPageDataWrapper />
	);
}

function CollaborationPageDataWrapper() {
	const friendContext = useFriends();

	return (
		<CollaborationPageDisplayComponent
			friends={friendContext.friends}
			friendInviteCode={friendContext.friendInviteCode}
			unfriend={friendContext.unfriend}
			createFriendRequest={friendContext.createFriendRequest}
		/>
	);
}

function CollaborationPageDisplayComponent(props: CollaborationPageProps) {
	return (
		<CollaborationPageContainer>
			<FriendsCard
				friends={props.friends}
				friendInviteCode={props.friendInviteCode}
				unfriend={props.unfriend}
				createFriendRequest={props.createFriendRequest}
			/>
		</CollaborationPageContainer>
	);
}

function FriendsCard(props: FriendsCardProps) {
	return (
		<CollaborationPageCard>
			<h1>Friends</h1>
			{
				props.friendInviteCode ?
				<Fragment>
					<h2>Add a Friend</h2>
					<p>Send this link to anybody to add them as a friend in Jetpack.</p>
					<p>If they don't have an account, they'll be able to sign up for free.</p>
					<FriendRequestLink friendInviteCode={props.friendInviteCode} />
				</Fragment>
				:
				<Fragment>
					<h2>Add a Friend</h2>
					<p>Generate a link to send to send to people to add them as friends.</p>
					<GenerateFriendRequestLink createFriendRequest={props.createFriendRequest} />
				</Fragment>
			}
			{props.friends && Object.keys(props.friends).length > 0 ?
				<Fragment>
					<h2>Friend List</h2>
					<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
						{Object.entries(props.friends).map(([friendUserId, friend]) => {
								return (
									<FriendEntry userId={friendUserId} displayName={friend.displayName || 'Unnamed Friend'} unfriend={props.unfriend} key={friendUserId} />
								);
							})}
					</div>
				</Fragment>
				: null
			}
		</CollaborationPageCard>
	);
}

function FriendEntry(props: {userId: string, displayName: string, unfriend: UnfriendType}) {
	const [popoverAnchor, setPopoverAnchor] = useState<SVGElement | null>(null);
	const [removeFriendConfirmationOpen, setRemoveFriendConfirmationOpen] = useState(false);

	const { enqueueSnackbar } = useJetpackSnackbar();

	const handlePopoverOpen = (event: React.MouseEvent<SVGElement>) => {
		setPopoverAnchor(event.currentTarget);
	}

	const handlePopoverClose = () => {
		setPopoverAnchor(null);
	}

	const popoverOpen = Boolean(popoverAnchor);

	return (
		<div style={{display: 'flex', flexDirection: 'row'}}>
			<FriendMoreButton onClick={handlePopoverOpen} />
			<div>
				{props.displayName}
			</div>
			<Popover
				open={popoverOpen}
				anchorEl={popoverAnchor}
				onClose={handlePopoverClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
			>
				<JetpackButton
					variant='text'
					onClick={() => {
						setRemoveFriendConfirmationOpen(true);
						setPopoverAnchor(null);
					}}
				>
					Unfriend
				</JetpackButton>
			</Popover>
			<Dialog open={removeFriendConfirmationOpen}>
				<DialogContent>
					<DialogContentText>
						{`Remove ${props.displayName} as a friend?`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<JetpackButton
						variant='text'
						onClick={() => setRemoveFriendConfirmationOpen(false)}
					>
						Cancel
					</JetpackButton>
					<JetpackButton
						onClick={(e) => {
							setRemoveFriendConfirmationOpen(false);
							props.unfriend(props.userId)
								.then(() => {
									enqueueSnackbar(`${props.displayName} is no longer your friend.`, {variant: 'success'});
								})
								.catch(error => {
									console.error(error);
									enqueueSnackbar(`Error removing ${props.displayName} from your friends list.`);
								});
						}}
					>
						Remove friend
					</JetpackButton>
				</DialogActions>
			</Dialog>
		</div>
	);
}

const FriendMoreButton = styled(FiMoreHorizontal)`
	position: relative;
	left: -20px;
	margin-right: -16px;
	color: ${colors.gray4};
	:hover {
		color: ${colors.blue};
		cursor: pointer;
	}
`;

function FriendRequestLink(props: {friendInviteCode: string}) {
	const { enqueueSnackbar } = useJetpackSnackbar();
	const { trackEvent } = useAnalytics();

	const url = window.location.origin + `/acceptInvitation?friendRequest=${props.friendInviteCode}`;
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigator.clipboard.writeText(url);
		trackEvent(events.CopiedFriendRequestLink, {invitation_id: props.friendInviteCode});
		enqueueSnackbar('Invite link copied to clipboard', {variant: 'success'});
	}
	
	return (
		<JetpackButton
			variant='contained'
			endIcon={<FiCopy />}
			sx={{
				textTransform: 'none'
			}}
			onClick={handleClick}
		>
			Copy friend request link
		</JetpackButton>
	);
}

function GenerateFriendRequestLink(props: {createFriendRequest: CreateFriendRequestType}) {
	const { enqueueSnackbar } = useJetpackSnackbar();
	const { trackEvent } = useAnalytics();

	const handleClick = () => {
		props.createFriendRequest()
			.then(response => {
				if ('error' in response) {
					throw new Error(response.error);
				}
				const url = window.location.origin + `/acceptInvitation?friendRequest=${response.friendInviteCode}`;
				navigator.clipboard.writeText(url);
				trackEvent(events.CopiedFriendRequestLink, {invitation_id: response.friendInviteCode});
				enqueueSnackbar('Invite link copied to clipboard', {variant: 'success'})
			})
			.catch(error => {
				console.error('(GenerateFriendRequestLink) Error generating friend request link.');
				console.error(error);
			});
	}

	return (
		<JetpackButton
			variant='contained'
			endIcon={<FiCopy />}
			sx={{
				textTransform: 'none'
			}}
			onClick={handleClick}
		>
			Generate friend request link
		</JetpackButton>
	);
}

const CollaborationPageContainer = styled.div`
	width: 100%;
	min-height: 100%;
	padding-top: 24px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	color: ${colors.textPrimary};
	background-color: ${colors.backgroundPrimary};
`;

const CollaborationPageCard = styled(JetpackCard)`
	width: 80%;
	max-width: 576px;
	box-sizing: border-box;
	margin-bottom: 24px;
	padding: 16px;
`;

// --------------------
// Types
// --------------------

type CollaborationPageProps = {
	friends: FriendsObject | undefined;
	friendInviteCode: string | undefined;
	unfriend: UnfriendType;
	createFriendRequest: CreateFriendRequestType;
}

type FriendsCardProps = {
	friends: FriendsObject | undefined;
	friendInviteCode: string | undefined;
	unfriend: UnfriendType;
	createFriendRequest: CreateFriendRequestType;
}