import React, { createContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/remote-config';

import { environmentType, useLocalEmulators } from './environmentUtils';
import { defaultConfig } from '../utilities/feature-context/features';

const FirebaseContext = createContext<FirebaseContextType | undefined>(undefined);

export const functionNames = {
	sendVerificationEmail: 'verifications-sendVerificationEmail',
	confirmEmailAddress: 'verifications-confirmEmailAddress',
	sendVerificationText: 'verifications-sendVerificationText',
	confirmPhoneNumber: 'verifications-confirmPhoneNumber',
	updateDisplayName: 'users-updateDisplayName',
	acceptFriendRequest: 'users-acceptFriendRequest',
	unfriend: 'users-unfriend',
	createFriendRequest: 'users-createFriendRequest',
	removeRegisteredAccount: 'users-removeRegisteredAccount',
	inviteNewUser: 'users-inviteNewUser',
	validateSignupInviteCode: 'users-validateSignupInviteCode',
	getCalendarOAuthSettings: 'users-getCalendarOAuthSettings',
	getUserEmailAddresses: 'users-getUserEmailAddresses'
};

export function FirebaseProvider({children}: ProviderProps) {
	const [isInitialized, setIsInitialized] = useState(false);
	const [Firebase, setFirebase] = useState<firebase.app.App | null>(null);
	const [Auth, setAuth] = useState<firebase.auth.Auth | null>(null);
	const [Firestore, setFirestore] = useState<firebase.firestore.Firestore | null>(null);
	const [Functions, setFunctions] = useState<firebase.functions.Functions | null>(null);
	const [RemoteConfig, setRemoteConfig] = useState<firebase.remoteConfig.RemoteConfig | null>(null);

	useEffect(() => {
		async function initializeFirebase(): Promise<firebase.app.App> {
			const response = await fetch('/__/firebase/init.json');
			const config = await response.json();
			const initializedFirebase = firebase.apps.length ? firebase.app() : firebase.initializeApp(config);
			
			const remoteConfig = initializedFirebase.remoteConfig();
			if (environmentType === 'test' || environmentType === 'localhost') {
				remoteConfig.settings.minimumFetchIntervalMillis = 10000;
			}

			remoteConfig.defaultConfig = defaultConfig;
			remoteConfig.fetchAndActivate()
				.catch(err => {
					console.error('Error fetching and activating Remote Config.');
					console.error(err);
				});

			// Use local emulators for Firebase services
			if (useLocalEmulators) {
				initializedFirebase.auth().useEmulator('http://localhost:9099');
				// We can disable warnings with the following line (https://github.com/firebase/firebase-tools/issues/2773):
				// initializedFirebase.auth().useEmulator('http://localhost:9099', { disableWarnings: true });
				// NOTE: Types are missing (https://github.com/firebase/firebase-js-sdk/pull/4430) and (https://github.com/firebase/firebase-js-sdk/issues/4591).
				initializedFirebase.firestore().useEmulator('localhost', 8080);
				initializedFirebase.functions().useEmulator('localhost', 5001);
			}
			
			return initializedFirebase;
		}
		if (!Firebase) {
			initializeFirebase()
				.then(response => {
					async function setContextState() {
						setFirebase(response);
						setAuth(response.auth());
						setFirestore(response.firestore());
						setFunctions(response.functions());
						setRemoteConfig(response.remoteConfig());
					}
					setContextState();
				})
				.then(result => setIsInitialized(true));
		}
	}, [Firebase]);

	// TODO: Add a function to call an https Firebase Function
	// callFirebaseFunction(functionName: string, data: any)
	// const functionCall = Functions.httpsCallable(functionName);
	// functionCall(data)

	const forProvider = {
		isInitialized,
		Firebase,
		Auth,
		Firestore,
		Functions,
		RemoteConfig
	}

	return (
		<FirebaseContext.Provider value={forProvider}>
			{children}
		</FirebaseContext.Provider>
	)
}

export function useFirebase() {
	const context = React.useContext(FirebaseContext);
	if (context === undefined) {
		throw new Error('useFirebase must be used within a FirebaseProvider');
	}
	return context;
}

// --------------------
// Types
// --------------------

type FirebaseContextType = {
	isInitialized: boolean;
	Firebase: firebase.app.App | null;
	Auth: firebase.auth.Auth | null;
	Firestore: firebase.firestore.Firestore | null;
	Functions: firebase.functions.Functions | null;
	RemoteConfig: firebase.remoteConfig.RemoteConfig | null;
}

type ProviderProps = {children: React.ReactNode};