import React from 'react';
import styled from '@emotion/styled';
import { Card, CardProps } from '@mui/material';
import { colors } from '../styles/colors';

const JetpackCard = styled(({isDepressed, ...rest}: JetpackCardType) => <Card {...rest} />)<JetpackCardType>`
	box-shadow: ${props => (props.isDepressed === true ? 'none' : `-4px 4px 0px 0px ${colors.jetpackMint}`) + ';'};
	border-radius: 8px;
`;

export default JetpackCard;

// --------------------
// Types
// --------------------

type JetpackCardType = {
	isDepressed?: boolean
} & CardProps;