import React from 'react';
import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router';
import { UserProvider } from '../../utilities/userContext';
import JetpackCard from '../JetpackCard';
import { Heading1, Paragraph } from '../JetpackText';
import { JetpackButton } from '../JetpackComponents';
import CalendarOnboarding from './CalendarOnboarding';
import * as ROUTES from '../../constants/routes';
import { colors } from '../../styles/colors';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Onboarding() {
	const routerHistory = useHistory();
	const location = useLocation();
	const query = useQuery();

	const stepNumber = Number(query.get('step')) || 0;

	const steps = [
		<WelcomeIntro handleContinueClick={handleContinueClick} />,
		<CalendarOnboarding handleContinueClick={handleContinueClick} handleBackClick={handleBackClick} />
	];

	function OnboardingStep(props: {stepNumber: number}) {
		return steps[props.stepNumber];
	}

	function handleContinueClick() {
		if (stepNumber < steps.length - 1) {
			const newSearch = new URLSearchParams(location.search);
			newSearch.set('step', (stepNumber + 1).toString());
			routerHistory.push({
				search: newSearch.toString()
			});
		} else {
			// TODO: Remove 'step={stepNumber}' from query params when we redirect.
			
			if (location.search.includes('groupSchedulingInvite')) {
				routerHistory.push({
					pathname: ROUTES.ACCEPT_INVITATION,
					search: location.search
				})
			} else if (location.search.includes('friendRequest')) {
				routerHistory.push({
					pathname: ROUTES.ACCEPT_INVITATION,
					search: location.search
				})
			} else {
				routerHistory.push({
					pathname: ROUTES.HOME,
					search: '?referrer=onboarding'
				});
			}
		}
	}

	function handleBackClick() {
		if (stepNumber > 0) {
			routerHistory.goBack();
		}
	}

	return (
		<UserProvider>
			<OnboardingContainer>
				<OnboardingCard>
					<OnboardingStep stepNumber={stepNumber} />
				</OnboardingCard>
			</OnboardingContainer>
		</UserProvider>
	);
}

function WelcomeIntro(props: {handleContinueClick: () => void}) {
	return (
		<>
			<Heading1>
				Welcome to Jetpack!
			</Heading1>
			<Paragraph style={{textAlign: 'center'}}>
				Jetpack is your second brain. It connects with the services you're already using to make it easy to stay on top of your life.
			</Paragraph>
			<JetpackButton
				onClick={props.handleContinueClick}
				sx={{
					marginTop: '8px'
				}}
			>
				Continue
			</JetpackButton>
		</>
	);
}

export const OnboardingContainer = styled.div`
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background: ${colors.jetpackBackgroundDark};
`;

export const OnboardingCard = styled(JetpackCard)`
	width: 80%;
	max-width: 576px;
	box-sizing: border-box;
	margin: 24px;
	padding: 16px;
	align-items: center;
`;