import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import { GroupSchedulingProvider, useGroupScheduling } from '../../utilities/groupSchedulingContext';
import { GroupSchedulingPageContainer } from './GroupSchedulingComponents';
import { JetpackButton } from '../JetpackComponents';
import { Heading1Gradient } from '../JetpackText';
import { darkTheme } from '../../styles/materialUiTheme';
import * as ROUTES from '../../constants/routes';

// TODO: Prompt user to connect their calendar if they haven't yet.

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function GroupSchedulingInviteLandingPageContextWrapper() {
	const query = useQuery();
	const eventId = query.get('groupSchedulingInvite');

	if (!eventId) {
		return (
			<div>
				Invalid invite link.
			</div>
		);
	} else {
		return (
			<GroupSchedulingProvider
				eventId={eventId}
			>
				<GroupSchedulingInviteLandingPageDataWrapper />
			</GroupSchedulingProvider>
		);
	}
}

function GroupSchedulingInviteLandingPageDataWrapper() {
	const groupSchedulingContext = useGroupScheduling();

	return (
		<GroupSchedulingInviteLandingPageDisplayComponent
			eventId={groupSchedulingContext.eventId}
			title={groupSchedulingContext.title}
			addCurrentUserToEvent={groupSchedulingContext.addCurrentUserToEvent}
		/>
	);
}

function GroupSchedulingInviteLandingPageDisplayComponent(props: GroupSchedulingInviteLandingPageProps) {
	const history = useHistory();
	const location = useLocation();

	return (
		<GroupSchedulingPageContainer>
			<ThemeProvider theme={darkTheme}>
				<Heading1Gradient>
					You're being invited to schedule "{props.title}."
				</Heading1Gradient>
				<p>
					If your calendar is connected to Jetpack, the organizer will be able to see when you're free.
				</p>
				<p>
					If you don't have any connected calendars, you'll be able to manually submit your available time.
				</p>
				<div style={{height: '16px'}} />
				<div style={{display: 'flex', flexDirection: 'row'}}>
					<JetpackButton
						variant='text'
						onClick={() => {
							history.push({
								pathname: ROUTES.HOME
							});
						}}
					>
						Decline
					</JetpackButton>
					<div style={{width: '16px'}} />
					<JetpackButton
						onClick={() => {
							props.addCurrentUserToEvent();
							history.push({
								pathname: ROUTES.GROUP_SCHEDULING + `/${props.eventId}`,
								search: location.search
							});
						}}
					>
						Accept
					</JetpackButton>
				</div>
			</ThemeProvider>
		</GroupSchedulingPageContainer>
	);
}

// --------------------
// Types
// --------------------

type GroupSchedulingInviteLandingPageProps = {
	eventId: string;
	title: string;
	addCurrentUserToEvent: () => void;
}