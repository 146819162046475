import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Collapse, InputBase } from '@mui/material';
import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { FiEdit2, FiCheckCircle, FiCalendar } from 'react-icons/fi';
import Card from './JetpackCard';
import { JetpackButton } from './JetpackComponents';
import { colors } from '../styles/colors';
import { Spark } from '../types/jetpack/spark';

const DEFAULT_DUE_DATE = DateTime.now().plus({ days: 1});

export default function NewSpark(props: NewSparkProps) {
	const [sparkState, setSparkState]: [Spark, any] = useState({isTask: true});
	const [titleText, setTitleText]: [string, any] = useState('');
	const [dueDate, setDueDate]: [Date | null, any] = useState(null);
	const [isEditing, setIsEditing] = useState(false);
	const [datePickerOpen, setDatePickerOpen] = useState(false);

	// Use a formik form? For submit on enter?

	const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setIsEditing(true);
		const textFromForm = e.target.value;
		let newSpark = {...sparkState};
		newSpark['title'] = textFromForm;
		setSparkState(newSpark);
		setTitleText(textFromForm);
	}

	const handleTaskActionClick = () => {
		let newSparkState = {...sparkState};
		if (newSparkState.isTask) {
			newSparkState['isTask'] = false;
			delete newSparkState['dueDate'];
			setDueDate(null);
		} else {
			newSparkState['isTask'] = true;
		}
		setSparkState(newSparkState);
	}

	const handleDueDateChange = (date: any) => {
		let newSpark = {...sparkState};

		if (date === null) {
			// Due date has been cleared

			delete newSpark['dueDate'];

			setDueDate(null);
			setSparkState(newSpark);
		} else {
			// Due date has been set

			const luxonDate = new LuxonUtils().date(date);

			const luxonDateToSave = DateTime.fromObject({
				year: luxonDate.year,
				month: luxonDate.month,
				day: luxonDate.day,
				hour: 12,
				minute: 0,
				zone: 'utc'
			});
			
			const jsDate = luxonDateToSave.toJSDate();

			newSpark['dueDate'] = jsDate;
			newSpark['isTask'] = true;

			setDueDate(jsDate);
			setSparkState(newSpark);
		}
	}

	const handleCancelClick = () => {
		setSparkState({isTask: true});
		setTitleText('');
		setDueDate(null);
		setIsEditing(false);
	}

	const handleCreateClick = () => {
		props.handleCreateSpark(sparkState);
		// TODO: Wait until there's a response
		// TODO: Once spark is created, select it so that the details populate on the right
		// Do this in ".then()" from the context
		setSparkState({});
		setTitleText('');
		setDueDate(null);
		setIsEditing(false);
	}

	return (
		<LocalizationProvider dateAdapter={AdapterLuxon}>
			<NewSparkContainer
				elevation={3}
				onClickCapture={() => setIsEditing(true)}
			>
				<ContainerInner
					isEditing={isEditing}
				>
					<EditIconAndTextArea>
						<FiEdit2 size={24} style={{color: colors.gray3}} />
						<span style={{width: 8}} />
						<SparkTitleTextArea
							placeholder="Add something new..."
							multiline={true}
							value={titleText}
							onChange={(e) => handleTextChange(e)}
							onFocus={() => setIsEditing(true)}
						/>
					</EditIconAndTextArea>
					<DueDateText>{dueDate ? `Due ${DateTime.fromJSDate(dueDate).toRelativeCalendar()}` : null}</DueDateText>
					<Collapse in={isEditing}>
						<ActionAndConfirmButtons>
							<ConfirmButtons>
								<CancelButton
									variant="text"
									color="primary"
									onClick={handleCancelClick}
								>
									Cancel
								</CancelButton>
								<div style={{width: 8}} />
								<CreateButton
									variant="contained"
									color="primary"
									onClick={handleCreateClick}
								>
									Create
								</CreateButton>
							</ConfirmButtons>
							<ActionButtons>
								<ActionIcon
									isActive={!!sparkState.isTask}
									onClick={handleTaskActionClick}
								>
									<FiCheckCircle size={24} />
								</ActionIcon>
								<ActionIcon
									isActive={!!sparkState.dueDate}
									onClick={() => setDatePickerOpen(!datePickerOpen)}
								>
									<FiCalendar size={24} />
								</ActionIcon>
							</ActionButtons>
						</ActionAndConfirmButtons>
					</Collapse>
					<MobileDatePicker
						value={DEFAULT_DUE_DATE}
						onChange={() => null}
						clearable={true}
						open={datePickerOpen}
						onOpen={() => setDatePickerOpen(true)}
						onClose={() => setDatePickerOpen(false)}
						onAccept={handleDueDateChange}
						renderInput={() => <div />}
					/>
				</ContainerInner>
			</NewSparkContainer>
		</LocalizationProvider>
	);
}

const NewSparkContainer = styled(Card)`

`;

const ContainerInner = styled.div<ContainerInnerProps>`
	padding: 8px;
	display: flex;
	flex-direction: column;
	cursor: ${props => props.isEditing ? 'auto': 'pointer'};
`;

const EditIconAndTextArea = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const SparkTitleTextArea = styled(InputBase)`
	width: 100%;
	padding: 0px;
`;

const DueDateText = styled.div`
	color: ${colors.gray3};
	font-style: italic;
`;

const ActionAndConfirmButtons = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const ConfirmButtons = styled.div`
	display: flex;
	flex-direction: row;
`;

const CancelButton = styled(JetpackButton)`
	justify-self: flex-start;
`;

const CreateButton = styled(JetpackButton)`
	justify-self: flex-start;
`;

const ActionButtons = styled.div`
	justify-self: flex-end;
	align-self: center;
	display: flex;
	flex-direction: row;
`;

const ActionIcon = styled.div<ActionIconProps>`
	vertical-align: middle;
	margin-left: 8px;
	color: ${props => props.isActive ? colors.blue : colors.gray3};
	:hover {
		color: ${props => props.isActive ? colors.blueDark : colors.gray1};
		cursor: pointer;
	}
`;

// --------------------
// Types
// --------------------

export interface NewSparkProps {
	handleCreateSpark: (spark: Spark) => void;
}

type ActionIconProps = {
	isActive: any;
}

interface ContainerInnerProps {
	isEditing: boolean;
}