import React, { useState } from 'react';

import { useUser } from '../../utilities/userContext';
import EmailSettings from './EmailSettings';
import PhoneSettings from './PhoneSettings';
import CalendarSettings from './CalendarSettings';
import TimezoneSettings from './TimezoneSettings';
import ProfileSettings from './ProfileSettings';
import { useJetpackSnackbar } from '../Snackbar';
import { Heading1 } from '../JetpackText';
import { SettingsContainer, SettingsCard, RemoveAccountDialog } from './SettingsComponents';
import { AccountTypes } from '../../types/jetpack/user';

import  { useFeature } from '../../utilities/feature-context';

export default function Settings() {
	const user = useUser();
	const features = useFeature();
	const calendarSettingsVisible = features.getBoolean('calendarSettingsVisible');
	const { enqueueSnackbar } = useJetpackSnackbar();

	const [confirmRemoveAccountDialog, setConfirmRemoveAccountDialog] = useState<ConfirmRemoveAccountDialogState>({
		open: false,
		type: 'email',
		accountKey: '',
		accountDisplayName: ''
	});

	function onRemoveAccount(accountType: AccountTypes, accountKey: string, accountDisplayName: string) {
		setConfirmRemoveAccountDialog({
			open: true,
			type: accountType,
			accountKey: accountKey,
			accountDisplayName: accountDisplayName
		});
	}

	// If we allow signin methods other than email/password, some of this will have to be changed.
	// For example, the password reset email will need to be hidden (and that function may need to have better error handling).

	return (
		<SettingsContainer>
			<SettingsCard>
				<Heading1 collapseMarginBottom>{'Settings'}</Heading1>
				<EmailSettings onRemoveAccount={onRemoveAccount} />
				<PhoneSettings onRemoveAccount={onRemoveAccount} />
				{
					calendarSettingsVisible
					? <CalendarSettings onRemoveAccount={onRemoveAccount} />
					: null
				}
				<TimezoneSettings />
				<ProfileSettings />
				<RemoveAccountDialog
					open={confirmRemoveAccountDialog.open}
					type={confirmRemoveAccountDialog.type}
					account={confirmRemoveAccountDialog.accountDisplayName}
					onConfirmClick={() => {
						user.removeRegisteredAccount(confirmRemoveAccountDialog.accountKey, confirmRemoveAccountDialog.type)
							.then(() => {
								enqueueSnackbar('Successfully removed.', {variant: 'success'});
								setConfirmRemoveAccountDialog({
									open: false,
									type: 'email',
									accountKey: '',
									accountDisplayName: ''
								});
							})
							.catch(error => {
								console.error('Error removing account.');
								console.error(error);
							})
					}}
					onCancelClick={() => setConfirmRemoveAccountDialog({
						open: false,
						type: 'email',
						accountKey: '',
						accountDisplayName: ''
					})}
				/>
			</SettingsCard>
		</SettingsContainer>
	);
}

// --------------------
// Types
// --------------------

type ConfirmRemoveAccountDialogState = {
	open: boolean,
	type: AccountTypes,
	accountKey: string,
	accountDisplayName: string
};