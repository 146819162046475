import React, { Fragment, useEffect, useState } from 'react';
import { UserManagerSettings } from 'oidc-client';
import { ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, IconButton, Link, MenuItem, Popover, Select, SelectChangeEvent, Table, TableBody, TableContainer, TableRow, Tooltip } from '@mui/material';
import { FiAlertTriangle } from 'react-icons/fi';

import { useFirebase } from '../../utilities/firebaseContext';
import { useUser } from '../../utilities/userContext';
import { useAnalytics, events } from '../../utilities/analyticsContext';
import { CalendarProvider, useCalendar, CalendarContextType } from '../../utilities/calendarContext';
import { JetpackButton } from '../JetpackComponents';
import { Paragraph } from '../JetpackText';
import { SettingsSectionContainer, SettingsSectionHeader, RemoveAccountButton, SettingsTableCell, TableWrapper } from './SettingsComponents';
import { colors } from '../../styles/colors';
import { CalendarAccount, PrimaryCalendar } from '../../types/jetpack/user';

export default function CalendarSettings(props: CalendarSettingsProps) {
	return (
		<CalendarProvider>
			<CalendarSettingsWithContext onRemoveAccount={props.onRemoveAccount} />
		</CalendarProvider>
	);
}

function CalendarSettingsWithContext(props: CalendarSettingsWithContextProps) {
	const calendarContext = useCalendar();
	const userContext = useUser();
	const [addCalendarPopoverAnchorEl, setAddCalendarPopoverAnchorEl] = useState<Element | null>(null);
	const [oAuthSettings, setOAuthSettings] = useState<OAuthSettings>(null);

	const getOAuthSettings = calendarContext.getOauthSettings;

	const [outlookRequestDialogOpen, setOutlookRequestDialogOpen] = useState(false);
	const [outlookRequestDialogContent, setOutlookRequestDialogContent] = useState<0 | 1>(0);

	const firebaseContext = useFirebase();
	const emailAddress = firebaseContext.Auth?.currentUser?.email || null;

	const analyticsContext = useAnalytics();
	const trackEvent = analyticsContext.trackEvent;

	useEffect(() => {
		let isMounted = true;
		if (!oAuthSettings) {
			getOAuthSettings()
				.then((settings) => {
					if (isMounted) {
						setOAuthSettings(settings);
					}
				});
			}
		return () => {
			isMounted = false;
		}
	}, [oAuthSettings, getOAuthSettings]);

	const calendarProviderDisplayStrings = {
		'google': 'Google',
		'outlook': 'Outlook',
	}

	let calendarAccounts: CalendarAccount[] = [];
	const primaryCalendarMenuItems: React.ReactNode[] = [];
	if (userContext.calendarAccounts) {
		for (const [calendarAccountKey , calendarAccount] of Object.entries(userContext.calendarAccounts)) {
			const calendarAccountDisplayName = `${calendarProviderDisplayStrings[calendarAccount.provider]}: ${calendarAccount.username}`
			calendarAccounts.push(calendarAccount);
			Object.values(calendarAccount.calendars).forEach(calendar => {
				if (calendar.isPrimary) {
					const primaryCalendarJsonString = JSON.stringify({calendarAccount: calendarAccountKey, calendarId: calendar.id});
					primaryCalendarMenuItems.push(
						<MenuItem value={primaryCalendarJsonString} key={primaryCalendarJsonString}>{calendarAccountDisplayName}</MenuItem>
					);
				}
			});
		}
	}

	const handlePrimaryCalendarSelect = (event: SelectChangeEvent) => {
		if (event) {
			const newPrimaryCalendar: PrimaryCalendar = JSON.parse(event.target.value);
			userContext.setPrimaryCalendar(newPrimaryCalendar);
		}
	}

	const handleAddCalendarClick = (event: React.MouseEvent) => {
		setAddCalendarPopoverAnchorEl(event.currentTarget);
	}

	const handleAddCalendarPopoverClose = () => {
		setAddCalendarPopoverAnchorEl(null);
	}
	
	return (
		<SettingsSectionContainer>
			<SettingsSectionHeader>Calendars</SettingsSectionHeader>
			{calendarAccounts.length > 0 ?
				<>
					<TableWrapper>
						<TableContainer style={{width: 'fit-content', maxWidth: 600}}>
							<Table size='small'>
								<TableBody>
									{calendarAccounts.map(calendarAccount => {
										const calendarAccountKey = calendarAccount.provider + '_' + calendarAccount.id;
										return (
											<TableRow key={calendarAccountKey}>
												<RemoveAccountButton
													onClick={() => props.onRemoveAccount('calendar', calendarAccountKey, calendarAccount.username)}
												/>
												<SettingsTableCell>
													{calendarProviderDisplayStrings[calendarAccount.provider] + ': ' + calendarAccount.username}
												</SettingsTableCell>
												{
													calendarAccount.error ?
													<SettingsTableCell style={{alignItems: 'center', justifyContent: 'center', padding: 0}}>
														<CalendarAccountErrorIcon provider={calendarAccount.provider} calendarContext={calendarContext} oAuthSettings={oAuthSettings} />
													</SettingsTableCell>
													: null
												}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</TableWrapper>
					<Link
						onClick={handleAddCalendarClick}
						underline='hover'
						style={{cursor: 'pointer'}}
					>
						Add another calendar
					</Link>
					{calendarAccounts.length > 1?
						<>
							<SettingsSectionHeader>Primary Calendar</SettingsSectionHeader>
							<Paragraph style={{marginTop: 0, textAlign: 'center'}}>Jetpack will use this account to schedule events and reminders.</Paragraph>
							<FormControl style={{width: 'fit-content', minWidth: 192}}>
								<Select
									labelId='primaryCalendarSelector'
									value={JSON.stringify({calendarAccount: userContext.primaryCalendar?.calendarAccount, calendarId: userContext.primaryCalendar?.calendarId})}
									onChange={handlePrimaryCalendarSelect}
								>
									{primaryCalendarMenuItems}
								</Select>
							</FormControl>
						</>
						:
						null
					}
				</>
				:
				<JetpackButton
					onClick={handleAddCalendarClick}
					variant='contained'
				>
					Add calendar
				</JetpackButton>
			}
			<Popover
				id={Boolean(addCalendarPopoverAnchorEl) ? 'add-calendar-popover' : undefined}
				open={Boolean(addCalendarPopoverAnchorEl)}
				anchorEl={addCalendarPopoverAnchorEl}
				onClose={handleAddCalendarPopoverClose}
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				transformOrigin={{vertical: 'top', horizontal: 'center'}}
			>
				<AddCalendarPopoverButton
					disabled={!oAuthSettings}
					onClick={() => {
						handleAddCalendarPopoverClose();
						addCalendar('google', calendarContext, oAuthSettings?.google || null);
					}}
				>
					Google
				</AddCalendarPopoverButton>
				<br />
				<AddCalendarPopoverButton
					disabled={!oAuthSettings}
					onClick={() => {
						handleAddCalendarPopoverClose();
						// addCalendar('outlook', calendarContext, oAuthSettings?.outlook || null);
						setOutlookRequestDialogOpen(true);
						trackEvent(events.ClickedAddOutlookCalendarButton, {});
					}}
				>
					Outlook
				</AddCalendarPopoverButton>
			</Popover>
			<Dialog open={outlookRequestDialogOpen}>
				{
					outlookRequestDialogContent === 0 ?
					<Fragment>
						<DialogContent>
							<DialogContentText>
								{`Outlook calendar integration coming soon!`}
							</DialogContentText>
							<DialogContentText>
								{`Want to be kept in the loop?`}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<JetpackButton
								variant='text'
								onClick={() => setOutlookRequestDialogOpen(false)}
							>
								Cancel
							</JetpackButton>
							<JetpackButton
								variant='contained'
								onClick={() => {
									setOutlookRequestDialogContent(1);
									trackEvent(events.RequestedOutlookCalendarWaitlist, {email_address: emailAddress});
								}}
							>
								Let me know
							</JetpackButton>
						</DialogActions>
					</Fragment>
					:
					<Fragment>
						<DialogContent>
							<DialogContentText>
								{`We'll let you know!`}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<JetpackButton
								variant='contained'
								onClick={() => {
									setOutlookRequestDialogOpen(false);
									setTimeout(() => setOutlookRequestDialogContent(0), 500);
								}}
							>
								Close
							</JetpackButton>
						</DialogActions>
					</Fragment>
				}
			</Dialog>
		</SettingsSectionContainer>
	);
}

function addCalendar(provider: 'google' | 'outlook', calendarContext: CalendarContextType, oAuthSettings: UserManagerSettings | null) {
	let settingsForOAuthFlow: UserManagerSettings | null = null;
	
	if (oAuthSettings) {
		settingsForOAuthFlow = oAuthSettings;
	} else {
		// Haven't gotten OAuth settings yet. Request them and then do the OAuth flow.
		calendarContext.getOauthSettings()
			.then((settings) => {
				if (settings) {
					settingsForOAuthFlow = settings[provider];
				} else {
					throw new Error('Error getting OAuth settings from Google Cloud.');
				}
			})
			.catch((err) => {
				console.error('Error getting OAuth settings from Google Cloud.');
				console.error(err);
			})
	}

	if (!settingsForOAuthFlow) {
		console.error('No settings for OAuth flow. Aborting.');
		alert('Error adding calendar account. Please contact support.');
		return;
	}
	
	switch (provider) {
		case 'google':
			calendarContext.launchGoogleCalendarOAuthFlow(settingsForOAuthFlow);
			break;
		case 'outlook':
			calendarContext.launchOutlookCalendarOAuthFlow(settingsForOAuthFlow);
			break;
		default:
			console.error('Unrecognized provider for OAuth flow.');
			alert('Error adding calendar account. Please contact support.');
	}
}

function CalendarAccountErrorIcon(props: {provider: 'google' | 'outlook', calendarContext: CalendarContextType, oAuthSettings: OAuthSettings}) {
	return (
		<Tooltip title='Click to reconnect calendar account.'>
			<IconButton onClick={() => addCalendar(props.provider, props.calendarContext, props.oAuthSettings ? props.oAuthSettings[props.provider] : null)} size='small' style={{color: colors.orange}}>
				<FiAlertTriangle />
			</IconButton>
		</Tooltip>
	);
}

// --------------------
// Styles
// --------------------

const AddCalendarPopoverButton = (props: ButtonProps) => {
	return (
		<JetpackButton
			variant='text'
			sx={{width: 150}}
			{...props}
		>
			{props.children}
		</JetpackButton>
	);
}

// --------------------
// Types
// --------------------

type CalendarSettingsProps = {
	onRemoveAccount: (accountType: 'calendar', accountKey: string, accountDisplayName: string) => void;
};

type CalendarSettingsWithContextProps = {
	onRemoveAccount: (accountType: 'calendar', accountKey: string, accountDisplayName: string) => void;
};

export type OAuthSettings = {
	google: UserManagerSettings;
	outlook: UserManagerSettings;
} | null;