import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';

import { useFirebase } from '../utilities/firebaseContext';
import { GroupSchedulingEvent } from '../types/jetpack/collaboration';

const CollaborationContext = createContext<CollaborationContextType | undefined>(undefined);

export function CollaborationProvider(props: CollaborationProviderProps) {
	const [groupSchedulingEvents, setGroupSchedulingEvents] = useState<Array<GroupSchedulingEvent>>([]);
	const firebaseContext = useFirebase();
	const auth = firebaseContext.Auth;
	const firestore = firebaseContext.Firestore;

	const userId = auth?.currentUser!.uid;
	
	useEffect(() => {
		let unsubscribeFromGroupSchedulingEvents: () => void;

		subscribeToGroupSchedulingEvents(setGroupSchedulingEvents, userId!, firestore)
			.then(unsubscribe => {
				unsubscribeFromGroupSchedulingEvents = unsubscribe!;
			})

		return () => {
			// Clean up subscriptions on context unmount
			unsubscribeFromGroupSchedulingEvents();
		};
	}, [userId, firestore])

	const forProvider = {
		groupSchedulingEvents
	}

	return (
		<CollaborationContext.Provider value={forProvider}>
			{props.children}
		</CollaborationContext.Provider>
	);
}

export function useCollaboration() {
	const context = useContext(CollaborationContext);
	if (context === undefined) {
		throw new Error('useCollaboration must be used within a CollaborationProvider.');
	}
	return context;
}

async function subscribeToGroupSchedulingEvents(setGroupSchedulingEvents: React.Dispatch<React.SetStateAction<GroupSchedulingEvent[]>>, userId: string, firestore: firebase.firestore.Firestore | null) {
	return firestore?.collection('groupSchedulingEvents')
		.where('users.' + userId, '!=', false)
		.onSnapshot(response => {
			const events: Array<GroupSchedulingEvent> = [];
			response.docs.forEach(doc => {
				const event = doc.data() as GroupSchedulingEvent;
				event.id = doc.id;
				events.push(event);
			})
			setGroupSchedulingEvents(events);
		})
}

// --------------------
// Types
// --------------------

type CollaborationContextType = {
	groupSchedulingEvents: Array<GroupSchedulingEvent>
}

type CollaborationProviderProps = {
	children: React.ReactNode;
}