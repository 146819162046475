import React, { Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { Spark } from '../types/jetpack/spark';
import { colors } from '../styles/colors';
import SparkCard from './SparkCard';

export default function SparkListSection(props: ListSectionProps) {
	let [isCollapsed, setIsCollapsed] = useState(props.collapsedDefault);

	let mainSparks: Array<Spark> = [];
	let completedSparks: Array<Spark> = [];
	
	props.sparks.forEach((spark: Spark) => {
		if (spark.isTask && spark.isDone) {
			completedSparks.push(spark);
		} else {
			mainSparks.push(spark);
		}
	})

	let mainSparkCards = makeSparkCardsFromSparkData(mainSparks, props.onSelectSpark, props.onToggleIsDone);
	let completedSparkCards = makeSparkCardsFromSparkData(completedSparks, props.onSelectSpark, props.onToggleIsDone);

	return (props.sparks.length > 0) ? (
		<SparkListSectionContainer>
			<SectionLabel
				isCollapsed={isCollapsed}
				isSubsection={false}
				text={props.title}
				onClick={() => setIsCollapsed(!isCollapsed)}
			/>
			{isCollapsed ?
				null :
				mainSparkCards
			}
			{isCollapsed ?
				null :
				(completedSparkCards.length > 0 ?
					<CompletedList sparkCards={completedSparkCards} /> :
					null
				)
			}
		</SparkListSectionContainer>
	) : null;
}

function SectionLabel(props: SectionLabelProps) {
	return  (
		!props.isSubsection ?
		<SectionLabelContainer onClick={props.onClick}>
			{props.isCollapsed ? <FiChevronRight size={24} /> : <FiChevronDown size={24} />}
			{props.text}
		</SectionLabelContainer>
		:
		<SubSectionLabelContainer onClick={props.onClick}>
		{props.isCollapsed ? <FiChevronRight size={16} /> : <FiChevronDown size={16} />}
		{props.text}
	</SubSectionLabelContainer>
	);
}

function CompletedList(props: CompletedListProps) {
	let [isCollapsed, setIsCollapsed] = useState(true);
	return (
		<CompletedSubsectionContainer>
			<SectionLabel
				text={'Completed'}
				isCollapsed={isCollapsed}
				isSubsection={true}
				onClick={() => setIsCollapsed(!isCollapsed)}
			/>
			{isCollapsed ? null : props.sparkCards}
		</CompletedSubsectionContainer>
	);
}

function makeSparkCardsFromSparkData(sparks: Array<Spark>, onSelectSpark: ListSectionProps['onSelectSpark'], onToggleIsDone: ListSectionProps['onToggleIsDone']) {
	return sparks.map(spark =>
		<Fragment key={spark.id}>
			<SparkCard
				spark={spark}
				onCardClick={() => {onSelectSpark(spark.id!)}}
				onIconClick={
					spark.isTask
					? () => {onToggleIsDone(spark)}
					: () => {}
				}
			/>
			<div style={{height: 8}} />
		</Fragment>
	);
}

const SparkListSectionContainer = styled.div`
	margin-top: 16px;
`;

const SectionLabelContainer = styled.div`
	margin: 0px 0px 8px 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 18pt;
	font-weight: bold;
	color: ${colors.textLightAccent};
	cursor: pointer;
`;

const SubSectionLabelContainer = styled.div`
	margin: 0px 0px 8px 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 14pt;
	font-weight: bold;
	color: ${colors.textLight};
	cursor: pointer;
`;

const CompletedSubsectionContainer = styled.div`
	padding-left: 16px;
	display: flex;
	flex-direction: column;
`;

// --------------------
// Types
// --------------------

interface ListSectionProps {
	title: string;
	sparks: Array<Spark>;
	collapsedDefault: boolean;
	onSelectSpark: (sparkId: string) => void;
	onToggleIsDone: (spark: Spark) => void;
}

interface SectionLabelProps {
	text: string;
	isCollapsed: boolean;
	isSubsection: boolean;
	onClick: () => void;
}

interface CompletedListProps {
	sparkCards: Array<JSX.Element>;
}