import React from 'react';
import styled from '@emotion/styled';

import GroupSchedulingEventsList from './GroupSchedulingEventsList';
import JetpackCard from '../JetpackCard';
import { colors } from '../../styles/colors';

export default function GroupSchedulingMainPage() {
	return (
		<GroupSchedulingPageContainer>
			<GroupSchedulingPageCard>
				<GroupSchedulingEventsList />
			</GroupSchedulingPageCard>
		</GroupSchedulingPageContainer>
	);
}

const GroupSchedulingPageContainer = styled.div`
	width: 100%;
	min-height: 100%;
	padding-top: 24px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	color: ${colors.textPrimary};
	background-color: ${colors.backgroundPrimary};
`;

const GroupSchedulingPageCard = styled(JetpackCard)`
	width: 80%;
	max-width: 576px;
	box-sizing: border-box;
	margin-bottom: 24px;
	padding: 16px;
`;