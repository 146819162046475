import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase from 'firebase/app';

import { functionNames, useFirebase } from '../utilities/firebaseContext';
import { useUser } from '../utilities/userContext';
import { FriendsObject } from '../types/jetpack/collaboration';

const FriendContext = createContext<FriendContextType | undefined>(undefined);

export function FriendProvider(props: FriendProviderProps) {
	const [friends, setFriends] = useState<FriendsObject | undefined>(undefined);
	const [friendInviteCode, setFriendInviteCode] = useState<string | undefined>(undefined);
	const firebaseContext = useFirebase();
	const auth = firebaseContext.Auth;
	const firestore = firebaseContext.Firestore!;
	const functions = firebaseContext.Functions!;
	const userContext = useUser();

	const userId = auth!.currentUser!.uid;

	useEffect(() => {
		let unsubscribeFromFriends: () => void;

		subscribeToFriends(setFriends, userId, firestore)
			.then(unsubscribe => {
				unsubscribeFromFriends = unsubscribe!;
			});

		getFriendInviteCode(setFriendInviteCode, userId, firestore);

		return () => {
			// Clean up subscriptions on context unmount
			unsubscribeFromFriends();
		};
	}, [userId, firestore]);

	const acceptFriendRequest = async (friendRequestCode: string) => {
		const acceptFriendRequestFirebaseFunction = functions.httpsCallable(functionNames.acceptFriendRequest);

		return acceptFriendRequestFirebaseFunction({friendRequestCode: friendRequestCode})
			.then(response => {
				if (response.data.error) {
					throw new Error(JSON.stringify(response.data.error));
				} else if (response.data.senderDisplayName) {
					return {
						success: true,
						senderDisplayName: response.data.senderDisplayName
					};
				} else {
					return {
						success: true,
						senderDisplayName: null
					}
				}
			})
			.catch(error => {
				console.error('(acceptFriendRequest) Error accepting friend request.');
				console.error(error);
				
				return {
					error: error
				};
			});
	}

	const unfriend = async (friendUserId: string) => {
		const unfriendFirebaseFunction = functions.httpsCallable(functionNames.unfriend);

		return unfriendFirebaseFunction({friendUserId: friendUserId})
			.then(response => {
				if (response.data.error) {
					throw new Error(JSON.stringify(response.data.error));
				} else {
					return {
						success: true
					};
				}
			})
			.catch(error => {
				console.error('(unfriend) Error unfriending user.');
				console.error(error);
				
				return {
					error: error
				};
			});
	}

	const createFriendRequest = async () => {
		const createFriendRequestFirebaseFunction = functions.httpsCallable(functionNames.createFriendRequest);
		
		return createFriendRequestFirebaseFunction({
			fromDisplayName: userContext.user?.displayName || 'Unnamed User'
		})
			.then(async response => {
				if (response.data.error) {
					throw new Error(JSON.stringify(response.data.error));
				} else {
					const response = await getFriendInviteCode(setFriendInviteCode, userId, firestore);

					if (response && response.friendInviteCode && response.friendInviteCode !== null) {
						return {
							success: true,
							friendInviteCode: response.friendInviteCode
						};
					} else {
						throw new Error('(createFriendRequest) No friendInviteCode in getFriendInviteCode response.')
					}
				}
			})
			.catch(error => {
				console.error('(createFriendRequest) Error creating friend request.');
				console.error(error);

				return {
					error: error
				};
			});
	}

	const forProvider = {
		friends,
		friendInviteCode,
		acceptFriendRequest,
		unfriend,
		createFriendRequest
	}

	return (
		<FriendContext.Provider value={forProvider}>
			{props.children}
		</FriendContext.Provider>
	);
}

async function subscribeToFriends(setFriends: React.Dispatch<React.SetStateAction<FriendsObject | undefined>>, userId: string, firestore: firebase.firestore.Firestore) {
	return firestore.collection('friends')
		.doc(userId)
		.onSnapshot(response => {
			const friends = response.data() as FriendsObject;
			setFriends(friends);
		})
}

async function getFriendInviteCode(setFriendInviteCode: React.Dispatch<React.SetStateAction<string | undefined>>, userId: string, firestore: firebase.firestore.Firestore) {
	return firestore.collection('friendRequests')
		.where('from', '==', userId)
		.get()
		.then(response => {
			const multiUseInvite = response.docs.find(invite => !!invite.data()['multiUse']);
			const friendInviteCode = multiUseInvite?.id || null;
			if (friendInviteCode) {
				setFriendInviteCode(friendInviteCode);
			}
			return {
				friendInviteCode: friendInviteCode
			}
		})
		.catch(error => {
			console.error('(getFriendInviteCode) Error getting friend invite code.')
			console.error(error);
		});
}

export function useFriends() {
	const context = useContext(FriendContext);
	if (context === undefined) {
		throw new Error('useFriends must be used within a GroupSchedulingProvider.');
	}
	return context;
}

// --------------------
// Types
// --------------------

type FriendContextType = {
	friends: FriendsObject | undefined;
	friendInviteCode: string | undefined;
	acceptFriendRequest: AcceptFriendRequestType;
	unfriend: UnfriendType;
	createFriendRequest: CreateFriendRequestType;
}

type FriendProviderProps = {
	children: React.ReactNode;
}

export type AcceptFriendRequestType = (friendRequestCode: string) => Promise<{success: boolean, senderDisplayName: string | null} | {error: any}>;

export type UnfriendType = (friendUserId: string) => Promise<{success: boolean} | {error: any}>;

export type CreateFriendRequestType = () => Promise<{success: boolean, friendInviteCode: string} | {error: any}>;