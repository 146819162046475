import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import styled from '@emotion/styled';

import { JetpackButton } from '../JetpackComponents';
import { ExternalLink } from '../JetpackText';
import { darkTheme } from '../../styles/materialUiTheme';
import { colors } from '../../styles/colors';
import * as ROUTES from '../../constants/routes';
import logo_white_words_transparent_bg from '../../assets/logo_white_words_transparent_bg.png';
import calendars_animation_128px from '../../assets/calendars_animation_128px.webm';

export default function GroupSchedulingInviteLoggedOutLandingPage() {
	const history = useHistory();
	const location = useLocation();

	return (
		<GroupSchedulingInviteLoggedOutLandingPageContainer>
			<ThemeProvider theme={darkTheme}>
				<GroupSchedulingInviteLoggedOutLandingContentContainer>
					<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
						<img src={logo_white_words_transparent_bg} alt='Jetpack logo' style={{maxWidth: '240px'}} />
						<video src={calendars_animation_128px} style={{height: '64px'}} autoPlay loop muted />
					</div>
					<p>
						You're being invited to schedule time using Jetpack&mdash;the free, painless way to schedule time with groups.
					</p>
					<div style={{marginBottom: '16px'}}>
						<JetpackButton
							onClick={() => {
								history.push({
									pathname: ROUTES.SIGNUP,
									state: location.state,
									search: location.search
								})
							}}
						>
							Continue
						</JetpackButton>
					</div>
					<div>
						<ExternalLink
							href='https://www.jetpackai.com'
						>
							Learn more
						</ExternalLink>
					</div>
				</GroupSchedulingInviteLoggedOutLandingContentContainer>
			</ThemeProvider>
		</GroupSchedulingInviteLoggedOutLandingPageContainer>
	);
}

const GroupSchedulingInviteLoggedOutLandingPageContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: ${colors.textPrimary};
	background: ${colors.jetpackBackgroundDark};
`;

const GroupSchedulingInviteLoggedOutLandingContentContainer = styled.div`
	width: 80%;
	max-width: 640px;
	align-items: center;
	text-align: center;
	justify-content: center;
`;