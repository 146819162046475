import React, { Fragment, useEffect, useState } from 'react';
import { UserManagerSettings } from 'oidc-client';
import styled from '@emotion/styled';
import { ButtonProps, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, MenuItem, Popover, Select, SelectChangeEvent } from '@mui/material';

import TimezoneSettings from '../SettingsPage/TimezoneSettings';
import { useFirebase } from '../../utilities/firebaseContext';
import { useUser } from '../../utilities/userContext';
import { useAnalytics, events } from '../../utilities/analyticsContext';
import { CalendarProvider, useCalendar, CalendarContextType } from '../../utilities/calendarContext';
import { Heading1, Heading2, Paragraph, SimpleText } from '../JetpackText';
import { JetpackButton } from '../JetpackComponents';
import { CalendarAccount, CalendarAccountsObject, PrimaryCalendar } from '../../types/jetpack/user';

export default function CalendarOnboardingContextWrapper(props: CalendarOnboardingContextWrapperProps) {
	return (
		<CalendarProvider>
			<CalendarOnboardingDataWrapper
				handleContinueClick={props.handleContinueClick}
				handleBackClick={props.handleBackClick}
			/>
		</CalendarProvider>
	);
}

function CalendarOnboardingDataWrapper(props: CalendarOnboardingDataWrapperProps) {
	const firebaseContext = useFirebase();
	const userContext = useUser();
	const calendarContext = useCalendar();

	return (
		<CalendarOnboardingDisplayComponent
			handleContinueClick={props.handleContinueClick}
			handleBackClick={props.handleBackClick}
			emailAddress={firebaseContext.Auth?.currentUser?.email || null}
			calendarAccounts={userContext.calendarAccounts}
			primaryCalendar={userContext.primaryCalendar}
			setPrimaryCalendar={userContext.setPrimaryCalendar}
			calendarContext={calendarContext}
			getOauthSettings={calendarContext.getOauthSettings}
			launchGoogleCalendarOAuthFlow={calendarContext.launchGoogleCalendarOAuthFlow}
			launchOutlookCalendarOAuthFlow={calendarContext.launchOutlookCalendarOAuthFlow}
		/>
	);
}

function CalendarOnboardingDisplayComponent(props: CalendarOnboardingProps) {
	const [addCalendarPopoverAnchorEl, setAddCalendarPopoverAnchorEl] = useState<Element | null>(null);
	const [oAuthSettings, setOAuthSettings] = useState<OAuthSettings>(null);

	const getOAuthSettings = props.getOauthSettings;

	const [outlookRequestDialogOpen, setOutlookRequestDialogOpen] = useState(false);
	const [outlookRequestDialogContent, setOutlookRequestDialogContent] = useState<0 | 1>(0);

	const analyticsContext = useAnalytics();
	const trackEvent = analyticsContext.trackEvent;

	useEffect(() => {
		let isMounted = true;
		if (!oAuthSettings) {
			getOAuthSettings()
				.then((settings) => {
					if (isMounted) {
						setOAuthSettings(settings);
					}
				});
			}
		return () => {
			isMounted = false;
		}
	}, [oAuthSettings, getOAuthSettings]);

	const handleAddCalendarClick = (event: React.MouseEvent) => {
		setAddCalendarPopoverAnchorEl(event.currentTarget);
	}

	const handleAddCalendarPopoverClose = () => {
		setAddCalendarPopoverAnchorEl(null);
	}

	const handlePrimaryCalendarSelect = (event: SelectChangeEvent) => {
		if (event) {
			const newPrimaryCalendar: PrimaryCalendar = JSON.parse(event.target.value);
			props.setPrimaryCalendar(newPrimaryCalendar);
		}
	}
	
	let calendarAccounts: Array<CalendarAccount> = [];
	const primaryCalendarMenuItems: Array<React.ReactNode> = [];

	if (props.calendarAccounts) {
		for (const [calendarAccountKey, calendarAccount] of Object.entries(props.calendarAccounts)) {
			calendarAccounts.push(calendarAccount);

			const calendarAccountDisplayName = `${calendarProviderDisplayStrings[calendarAccount.provider]}: ${calendarAccount.username}`

			Object.values(calendarAccount.calendars).forEach(calendar => {
				if (calendar.isPrimary) {
					const primaryCalendarJsonString = JSON.stringify({calendarAccount: calendarAccountKey, calendarId: calendar.id});
					primaryCalendarMenuItems.push(
						<MenuItem value={primaryCalendarJsonString} key={primaryCalendarJsonString}>{calendarAccountDisplayName}</MenuItem>
					);
				}
			});
		}
	}
	
	return (
		<CalendarOnboardingContainer>
			<Heading1>Let's set up your calendars</Heading1>
			<Paragraph>Jetpack connects to your calendars to find time for things like meeting with others and working on tasks. Connect to all your calendars so that you never double-book yourself.</Paragraph>
			{
				calendarAccounts.map(calendarAccount => {
					return (
						<SimpleText key={calendarAccount.id}>{`${calendarProviderDisplayStrings[calendarAccount.provider]}: ${calendarAccount.username}`}</SimpleText>
					);
				})
			}
			{calendarAccounts.length > 0 ? <div style={{height: 16}} /> : null}
			<JetpackButton
				onClick={handleAddCalendarClick}
			>
				Connect calendar
			</JetpackButton>
			{calendarAccounts.length > 1?
				<>
					<Heading2>Primary Calendar</Heading2>
					<Paragraph style={{marginTop: 0, textAlign: 'center'}}>Jetpack will use this account to schedule events and reminders.</Paragraph>
					<FormControl style={{width: 'fit-content', minWidth: 192}}>
						<Select
							labelId='primaryCalendarSelector'
							value={JSON.stringify({calendarAccount: props.primaryCalendar?.calendarAccount, calendarId: props.primaryCalendar?.calendarId})}
							onChange={handlePrimaryCalendarSelect}
						>
							{primaryCalendarMenuItems}
						</Select>
					</FormControl>
				</>
				:
				null
			}
			<Popover
				id={Boolean(addCalendarPopoverAnchorEl) ? 'add-calendar-popover' : undefined}
				open={Boolean(addCalendarPopoverAnchorEl)}
				anchorEl={addCalendarPopoverAnchorEl}
				onClose={handleAddCalendarPopoverClose}
				anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
				transformOrigin={{vertical: 'top', horizontal: 'center'}}
			>
				<AddCalendarPopoverButton
					disabled={!oAuthSettings}
					onClick={() => {
						handleAddCalendarPopoverClose();
						addCalendar('google', props.calendarContext, oAuthSettings?.google || null);
					}}
				>
					Google
				</AddCalendarPopoverButton>
				<br />
				<AddCalendarPopoverButton
					disabled={!oAuthSettings}
					onClick={() => {
						handleAddCalendarPopoverClose();
						// addCalendar('outlook', props.calendarContext, oAuthSettings?.outlook || null);
						setOutlookRequestDialogOpen(true);
						trackEvent(events.ClickedAddOutlookCalendarButton, {});
					}}
				>
					Outlook
				</AddCalendarPopoverButton>
			</Popover>
			<TimezoneSettings />
			<div style={{height: 24}} />
			<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
				<JetpackButton
					variant='text'
					onClick={props.handleBackClick}
					sx={{
						marginRight: '16px'
					}}
				>
					Back
				</JetpackButton>
				<JetpackButton
					variant={calendarAccounts && calendarAccounts.length ? 'contained' : 'text'}
					onClick={props.handleContinueClick}
				>
					{calendarAccounts && calendarAccounts.length ? 'Continue' : 'Skip'}
				</JetpackButton>
			</div>
			<Dialog open={outlookRequestDialogOpen}>
				{
					outlookRequestDialogContent === 0 ?
					<Fragment>
						<DialogContent>
							<DialogContentText>
								{`Outlook calendar integration coming soon!`}
							</DialogContentText>
							<DialogContentText>
								{`Want to be kept in the loop?`}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<JetpackButton
								variant='text'
								onClick={() => setOutlookRequestDialogOpen(false)}
							>
								Cancel
							</JetpackButton>
							<JetpackButton
								variant='contained'
								onClick={() => {
									setOutlookRequestDialogContent(1);
									trackEvent(events.RequestedOutlookCalendarWaitlist, {email_address: props.emailAddress});
								}}
							>
								Let me know
							</JetpackButton>
						</DialogActions>
					</Fragment>
					:
					<Fragment>
						<DialogContent>
							<DialogContentText>
								{`We'll let you know!`}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<JetpackButton
								variant='contained'
								onClick={() => {
									setOutlookRequestDialogOpen(false);
									setTimeout(() => setOutlookRequestDialogContent(0), 500);
								}}
							>
								Close
							</JetpackButton>
						</DialogActions>
					</Fragment>
				}
			</Dialog>
		</CalendarOnboardingContainer>
	);
}

function addCalendar(provider: 'google' | 'outlook', calendarContext: CalendarContextType, oAuthSettings: UserManagerSettings | null) {
	let settingsForOAuthFlow: UserManagerSettings | null = null;
	
	if (oAuthSettings) {
		settingsForOAuthFlow = oAuthSettings;
	} else {
		// Haven't gotten OAuth settings yet. Request them and then do the OAuth flow.
		calendarContext.getOauthSettings()
			.then((settings) => {
				if (settings) {
					settingsForOAuthFlow = settings[provider];
				} else {
					throw new Error('Error getting OAuth settings from Google Cloud.');
				}
			})
			.catch((err) => {
				console.error('Error getting OAuth settings from Google Cloud.');
				console.error(err);
			})
	}

	if (!settingsForOAuthFlow) {
		console.error('No settings for OAuth flow. Aborting.');
		alert('Error adding calendar account. Please contact support.');
		return;
	}
	
	switch (provider) {
		case 'google':
			calendarContext.launchGoogleCalendarOAuthFlow(settingsForOAuthFlow);
			break;
		case 'outlook':
			calendarContext.launchOutlookCalendarOAuthFlow(settingsForOAuthFlow);
			break;
		default:
			console.error('Unrecognized provider for OAuth flow.');
			alert('Error adding calendar account. Please contact support.');
	}
}

const CalendarOnboardingContainer = styled.div`
	align-items: center;
`;

const calendarProviderDisplayStrings = {
	'google': 'Google',
	'outlook': 'Outlook',
}

const AddCalendarPopoverButton = (props: ButtonProps) => {
	return (
		<JetpackButton
			variant='text'
			sx={{width: 150}}
			{...props}
		>
			{props.children}
		</JetpackButton>
	);
	}

// --------------------
// Types
// --------------------

type CalendarOnboardingContextWrapperProps = {
	handleContinueClick: () => void;
	handleBackClick: () => void;
}

type CalendarOnboardingDataWrapperProps = {
	handleContinueClick: () => void;
	handleBackClick: () => void;
}

type CalendarOnboardingProps = {
	handleContinueClick: () => void;
	handleBackClick: () => void;
	emailAddress: string | null;
	calendarAccounts: CalendarAccountsObject | undefined;
	primaryCalendar: PrimaryCalendar | undefined;
	setPrimaryCalendar: (primaryCalendar: PrimaryCalendar) => Promise<void>;
	calendarContext: CalendarContextType;
	getOauthSettings: () => Promise<OAuthSettings | null>;
	launchGoogleCalendarOAuthFlow: (googleUserManagerSettings: UserManagerSettings) => void;
	launchOutlookCalendarOAuthFlow: (outlookUserManagerSettings: UserManagerSettings) => void;
}

export type OAuthSettings = {
	google: UserManagerSettings;
	outlook: UserManagerSettings;
} | null;