import React, { createContext, useContext, useReducer, useState } from 'react';

import { useFirebase } from '../utilities/firebaseContext';
import { appChatMessageEndpoint } from '../constants/cloudFunctionEndpoints';

const messageEndpoint = appChatMessageEndpoint();

function messageReducer(messages: Array<Message>, action: {newMessage: Message}) {
	return [...messages, action.newMessage];
}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export function ChatProvider({children}: ChatProviderProps) {
	// NOTE: In the future, we could store chats in the database, but for now, let's just store them in context state
	const [messages, dispatchMessageReducer] = useReducer(messageReducer, []);
	const [jetpackIsTyping, setJetpackIsTyping] = useState(false);
	const firebaseContext = useFirebase();
	const auth = firebaseContext.Auth;

	const addMessage = (message: Message) => {
		dispatchMessageReducer({newMessage: message});
	}

	const sendMessage = (message: Message) => {
		addMessage(message);
		setJetpackIsTyping(true);
		const requestData = {
			message: {
				body: message.body
			}
		}
		auth?.currentUser?.getIdToken()
			.then(idToken => {
				const authHeader = 'Bearer ' + idToken;
				const url = messageEndpoint;
				return fetch(url, {
					method: 'POST',
					mode: 'cors',
					headers: {
						Authorization: authHeader,
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(requestData)
				})
			})
			.then(response => {
				setJetpackIsTyping(false);
				return response.json();
			})
			.then(data => {
				if (data.message) {
					addMessage({body: data.message, sender: 'jetpack'})
				}
			})
			.catch(error => {
				console.error('(sendMessageToBackend) Error sending message to backend.');
				console.error(error);
			})
		;
	}

	const forProvider = {
		messages,
		jetpackIsTyping,
		sendMessage
	}

	return (
		<ChatContext.Provider value={forProvider}>
			{children}
		</ChatContext.Provider>
	);
}

export function useChat() {
	const context = useContext(ChatContext);
	if (context === undefined) {
		throw new Error('useChat must be used within a ChatProvider.');
	}
	return context;
}

// --------------------
// Types
// --------------------

type ChatContextType = {
	messages: Array<Message>;
	jetpackIsTyping: boolean;
	sendMessage: (message: Message) => void;
}

type ChatProviderProps = {children: React.ReactNode}

export type Message = {
	body: string;
	sender: 'user' | 'jetpack';
}