import React from 'react';
import { useSparks } from '../utilities/sparkContext';
import SparkList from '../components/SparkList';

export default function SparkListWithData({children}: any) {
	const sparkContext = useSparks();

	return (
		<SparkList
			sparks={sparkContext.sparks}
			onSelectSpark={sparkContext.selectSpark}
			onUpdateSpark={sparkContext.updateSpark}
		/>
	)
}