import React, { createContext, useContext } from 'react';
import { functionNames, useFirebase } from './firebaseContext';

const AdminContext = createContext<AdminContextType | undefined>(undefined);

export function AdminProvider({children}: ProviderProps) {
	const firebaseContext = useFirebase();
	const Functions = firebaseContext.Functions!;

	const inviteNewUser = (emailAddress: string) => {
		// NOTE: This function is used in the Admin page to create an invitation code and send it to an email address
		
		const inviteNewUser = Functions.httpsCallable(functionNames.inviteNewUser);

		return inviteNewUser({
			emailAddress: emailAddress
		})
		.then((result) => {
			return {'success': true}
		})
		.catch((error) => {
			var code = error.code;
			var message = error.message;
			var details = error.details;
			console.error(code);
			console.error(message);
			console.error(details);
			return {'error': error}
		});
	}

	const forProvider = {
		inviteNewUser: inviteNewUser,
	};

	return (
		<AdminContext.Provider value={forProvider}>
			{children}
		</AdminContext.Provider>
	);
}

export function useAdmin() {
	const context = useContext(AdminContext);
	if (context === undefined) {
		throw new Error('useAdmin must be used within an AdminProvider.');
	}
	return context;
}

// --------------------
// Types
// --------------------

type AdminContextType = {
	inviteNewUser: (emailAddress: string) => Promise<{success: boolean} | {error: any}>
};

type ProviderProps = {children: React.ReactNode};