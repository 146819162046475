import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import { CircularProgress, TextField, Typography } from '@mui/material';

import { useAuth, AuthContextType } from '../utilities/authContext';
import { environmentType } from '../utilities/environmentUtils';
import JetpackCard from './JetpackCard';
import { JetpackButton } from './JetpackComponents';
import { Heading1, LinkText, SimpleText } from './JetpackText';
import { colors } from '../styles/colors';
import * as ROUTES from '../constants/routes';

// Used for development and testing. Change to false so that you don't need an invite code to sign up.
const INVITE_CODE_REQUIRED_FOR_LOCAL_TESTING: boolean = false;
// Don't change this one until we're ready to end closed beta. It forces prod to require an invite code.
const INVITE_CODE_REQUIRED: boolean = environmentType === 'prod' ? true : INVITE_CODE_REQUIRED_FOR_LOCAL_TESTING;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LoginSignupPage(props: {initialLoginOrSignup: 'login' | 'signup'}) {
	let auth = useAuth();

	// TODO: Make location state type more robust (store it somewhere and reference it everywhere).
	let location = useLocation<{from: string}>();

	const query = useQuery();
	const inviteCode = query.get('inviteCode');
	const emailAddress = query.get('emailAddress');
	const groupSchedulingInviteEventId = query.get('groupSchedulingInvite');
	const friendRequestInviteCode = query.get('friendRequest');

	const [loginOrSignup, setLoginOrSignup] = useState(props.initialLoginOrSignup);

	// NOTE: We're keeping track of email and password twice: In this higher order component and in formik state.
	// This seems messy and could lead to problems, but it was the simplest way to have email and password persist when switching between login and signup.
	const [email, setEmail] = useState(emailAddress || '');
	const [password, setPassword] = useState('');
	const [fullname, setFullname] = useState('');

	// Do this so that we know if we should go to onboarding next.
	const [nextRoute, setNextRoute] = useState<NextRoute>(ROUTES.HOME);

	if (auth.user) {
		if (nextRoute === ROUTES.ONBOARDING) {
			return <Redirect to={{
				pathname: ROUTES.ONBOARDING,
				search: location.search
			}} />
		} else {
			return <Redirect to={{
				pathname: (location.state && location.state.from) ? location.state.from : ROUTES.HOME,
				search: location.search
			}} />
		}
	} else {
		if (loginOrSignup === 'login') {
			return (
				<LoginSignUpPageContainer>
					<LoginSignUpCard>
						<LoginComponent
							auth={auth}
							email={email}
							password={password}
							setEmail={setEmail}
							setPassword={setPassword}
							setLoginOrSignup={setLoginOrSignup}
						/>
						</LoginSignUpCard>
				</LoginSignUpPageContainer>
			);
		} else {
			return (
				<LoginSignUpPageContainer>
					<LoginSignUpCard>
						<SignupComponent
							auth={auth}
							fullname={fullname}
							email={email}
							password={password}
							inviteCode={inviteCode}
							groupSchedulingInviteEventId={groupSchedulingInviteEventId}
							friendRequestInviteCode={friendRequestInviteCode}
							setFullname={setFullname}
							setEmail={setEmail}
							setPassword={setPassword}
							setLoginOrSignup={setLoginOrSignup}
							setNextRoute={setNextRoute}
						/>
					</LoginSignUpCard>
				</LoginSignUpPageContainer>
			);
		}
	}
}

function LoginComponent(props: LoginComponentProps) {
	let [loginError, setLoginError]: [any, any] = useState(null);

	const formik = useFormik({
		initialValues: {
			email: props.email || '',
			password: props.password || ''
		},
		validate: loginValidation,
		onSubmit: (values) => {
			props.auth.signin(values.email, values.password)
				.catch(error => {
					setLoginError('Incorrect email or password');
					console.error(error);
				});
			formik.setSubmitting(false);
		}
	});

	return (
		<LoginSignUpForm onSubmit={formik.handleSubmit}>
			<Heading1>Log In</Heading1>
			<TextField
				id='email'
				name='email'
				type='email'
				label='Email'
				variant='standard'
				value={props.email || formik.values.email}
				onChange={e => {
					props.setEmail(e.target.value);
					formik.handleChange(e);
				}}
				error={formik.touched.email && Boolean(formik.errors.email)}
				helperText={formik.touched.email && formik.errors.email}
			/>
			<div style={{height: 8}} />
			<TextField
				id='password'
				name='password'
				type='password'
				label='Password'
				variant='standard'
				value={props.password || formik.values.password}
				onChange={e => {
					props.setPassword(e.target.value);
					formik.handleChange(e);
				}}
				error={formik.touched.password && Boolean(formik.errors.password)}
				helperText={formik.touched.password && formik.errors.password}
			/>
			{loginError ?
				<div>
					<div style={{height: 8}} />
					<Typography color='error' variant='body2'>{loginError}</Typography>
					<div style={{height: 4}} />
				</div>
				: <div style={{height: 32}} />
			}
			{
				formik.isSubmitting
				? <CircularProgress />
				: 
				<JetpackButton
					variant="contained"
					color="primary"
					type="submit"
					disabled={formik.isSubmitting}
					onClick={formik.submitForm}
				>
					Log in
				</JetpackButton>
			}
			<div style={{height: 32}} />
			<SimpleText>
				Don't have an account?{' '}
			</SimpleText>
			<SimpleText>
				<LinkText
					text='Sign up'
					onClick={() => props.setLoginOrSignup('signup')}
				/>
				{' '}instead.
			</SimpleText>
		</LoginSignUpForm>
	);
}

function SignupComponent(props: SignupComponentProps) {
	let [signupError, setSignupError] = useState<string | null>(null);

	// NOTE: Accounts can be created from a group scheduling invite, even if the user doesn't have an invite code.
	// TODO: If there's no invite code in the URL query params, give the user a way to sign up for the waitlist.

	const formik = useFormik({
		initialValues: {
			email: props.email || '',
			password: props.password || '',
			fullname: props.fullname || ''
		},
		validate: signUpValidation,
		onSubmit: (values) => {
			props.setNextRoute(ROUTES.ONBOARDING);
			if (INVITE_CODE_REQUIRED === false || props.groupSchedulingInviteEventId || props.friendRequestInviteCode) {
				props.auth.signupWithoutInviteCode(values.fullname, values.email, values.password)
					.catch(error => {
						props.setNextRoute(ROUTES.HOME);
						formik.setSubmitting(false);
						if (error.message) {
							setSignupError(error.message);
						} else if (typeof error === 'string') {
							setSignupError(error);
						} else {
							console.error(error);
							setSignupError('Signup error. Please contact the Jetpack team for help.');
						}
					});
			} else {
				props.auth.signup(values.fullname, values.email, values.password, props.inviteCode ? props.inviteCode: '')
					.catch(error => {
						props.setNextRoute(ROUTES.HOME);
						formik.setSubmitting(false);
						if (error.message) {
							setSignupError(error.message);
						} else if (typeof error === 'string') {
							setSignupError(error);
						} else {
							console.error(error);
							setSignupError('Signup error. Please contact the Jetpack team for help.');
						}
					});
			}
		}
	});

	return (
		<LoginSignUpForm onSubmit={formik.handleSubmit}>
			<Heading1>Sign Up</Heading1>
			<TextField
				id='fullname'
				name='fullname'
				type='text'
				label='Full Name'
				variant='standard'
				value={props.fullname || formik.values.fullname}
				onChange={e => {
					props.setFullname(e.target.value);
					formik.handleChange(e);
				}}
				error={formik.touched.fullname && Boolean(formik.errors.fullname)}
				helperText={formik.touched.fullname && formik.errors.fullname}
			/>
			<div style={{height: 8}} />
			<TextField
				id='email'
				name='email'
				type='email'
				label='Email'
				variant='standard'
				value={props.email || formik.values.email}
				onChange={e => {
					props.setEmail(e.target.value);
					formik.handleChange(e);
				}}
				error={formik.touched.email && Boolean(formik.errors.email)}
				helperText={formik.touched.email && formik.errors.email}
			/>
			<div style={{height: 8}} />
			<TextField
				id='password'
				name='password'
				type='password'
				label='Password'
				variant='standard'
				value={props.password || formik.values.password}
				onChange={e => {
					props.setPassword(e.target.value);
					formik.handleChange(e);
				}}
				error={formik.touched.password && Boolean(formik.errors.password)}
				helperText={formik.touched.password && formik.errors.password}
			/>
			{signupError ?
				<div>
					<div style={{height: 8}} />
					<Typography color='error' variant='body2'>{signupError}</Typography>
					<div style={{height: 4}} />
				</div>
				: <div style={{height: 32}} />
			}
			{
				formik.isSubmitting
				? <CircularProgress />
				: 
				<JetpackButton
					variant="contained"
					color="primary"
					type="submit"
					disabled={formik.isSubmitting}
					onClick={formik.submitForm}
				>
					Sign up
				</JetpackButton>
			}
			<div style={{height: 32}} />
			<SimpleText>
				Already have an account?{' '}
			</SimpleText>
			<SimpleText>
				<LinkText
					text='Log in'
					onClick={() => props.setLoginOrSignup('login')}
				/>
				{' '}instead.
			</SimpleText>
		</LoginSignUpForm>
	);
}

function loginValidation(values: {email: string, password: string}) {
	const errors: Partial<FormValues> = {};
	if (!values.email) {
		errors.email = 'Required';
	} if (!values.password) {
		errors.password = 'Required';
	} if (
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = 'Invalid email address';
	}
	return errors;
}

function signUpValidation(values: {fullname: string, email: string, password: string}) {
	const errors: Partial<FormValues> = {};
	if (!values.fullname) {
		errors.fullname = 'Required';
	}	if (!values.email) {
		errors.email = 'Required';
	} if (!values.password) {
		errors.password = 'Required';
	} if (
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = 'Invalid email address';
	} if (values.password.length < 8) {
		errors.password = 'Must be 8 characters or longer';
	}
	return errors;
}

const LoginSignUpPageContainer = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: ${colors.jetpackBackgroundDark};
`;

const LoginSignUpCard = styled(JetpackCard)`
	width: 80%;
	max-width: 320px;
	padding: 48px 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const LoginSignUpForm = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

// --------------------
// Types
// --------------------

type LoginComponentProps = {
	auth: AuthContextType;
	email: string;
	password: string;
	setEmail: React.Dispatch<React.SetStateAction<string>>;
	setPassword: React.Dispatch<React.SetStateAction<string>>;
	setLoginOrSignup: React.Dispatch<React.SetStateAction<"login" | "signup">>;
}

type SignupComponentProps = {
	auth: AuthContextType;
	fullname: string;
	email: string;
	password: string;
	inviteCode: string | null;
	groupSchedulingInviteEventId: string | null;
	friendRequestInviteCode: string | null;
	setFullname: React.Dispatch<React.SetStateAction<string>>;
	setEmail: React.Dispatch<React.SetStateAction<string>>;
	setPassword: React.Dispatch<React.SetStateAction<string>>;
	setNextRoute: React.Dispatch<React.SetStateAction<NextRoute>>;
	setLoginOrSignup: React.Dispatch<React.SetStateAction<"login" | "signup">>;
}

type FormValues = {
	fullname?: string;
	email: string;
  password: string;
}

type NextRoute = typeof ROUTES.HOME | typeof ROUTES.ONBOARDING;