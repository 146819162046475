import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, FormControl, Input, InputLabel } from '@mui/material';

import { SettingsSectionHeader } from './SettingsComponents';
import { JetpackButton } from '../JetpackComponents';
import { LinkText, Paragraph } from '../JetpackText';
import { useAuth } from '../../utilities/authContext';
import { useUser } from '../../utilities/userContext';
import { updateApp } from '../../serviceWorkerRegistration';
import * as ROUTES from '../../constants/routes';
import { colors } from '../../styles/colors';

export default function ProfileSettings() {
	const authContext = useAuth();
	const userContext = useUser();
	const history = useHistory();

	const user = userContext.user;

	const [changeNameDialogOpen, setChangeNameDialogOpen] = useState(false);
	
	const [passwordResetFeedback, setPasswordResetFeedback] = useState<PasswordResetFeedback>({
		type: 'none',
		message: ''
	});

	function resetPassword() {
		authContext.sendPasswordResetEmail()
		.then(() => {
			setPasswordResetFeedback({
				type: 'success',
				message: 'Password reset email sent. Please check your inbox.'
			});
		})
		.catch((error) => {
			setPasswordResetFeedback({
				type: 'error',
				message: 'Error sending password reset email.'
			});
			console.error('Error sending password reset email.');
			console.error(error);
		});
	}

	return (
		<Fragment>
			<SettingsSectionHeader>Profile</SettingsSectionHeader>
			<Paragraph style={{marginTop: 0}}>{authContext.user && user ? `Logged in as ${user.displayName} (${authContext.user.email})` : `Not logged in.`}</Paragraph>
			<LinkText
				onClick={() => setChangeNameDialogOpen(true)}
			>
				Edit name
			</LinkText>
			<LinkText
				onClick={() => resetPassword()}
			>
				Reset password
			</LinkText>
			{PasswordResetFeedbackMessage(passwordResetFeedback.type, passwordResetFeedback.message)}
			<LinkText
				onClick={() => {
					updateApp();
				}}
			>
				Update app
			</LinkText>
			<LinkText
				onClick={() => {
					authContext.signout();
					history.push(ROUTES.HOME);
				}}
			>
				Log out
			</LinkText>
			{changeNameDialogOpen ?
				<ChangeNameDialog
					open={changeNameDialogOpen}
					currentName={user?.displayName || ''}
					changeName={(newName: string) => {
						userContext.updateDisplayName(newName);
						setChangeNameDialogOpen(false);
					}}
					closeDialog={() => {setChangeNameDialogOpen(false)}}
				/>
				: null
			}
		</Fragment>
	);
}

function ChangeNameDialog(props: {open: boolean, currentName: string, changeName: (newName: string) => void, closeDialog: () => void}) {
	const [newName, setNewName] = useState(props.currentName);

	const onConfirm = () => {
		props.changeName(newName);
	}
	
	const onCancel = () => {
		setNewName('');
		props.closeDialog();
	}
	
	return (
		<Dialog
			open={props.open}
		>
			<DialogContent>
				<FormControl variant='standard'>
					<InputLabel htmlFor='name-input'>New name</InputLabel>
					<Input
						id='name-input'
						value={newName}
						onChange={(e) => setNewName(e.target.value)}
					/>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<JetpackButton variant='text' onClick={onCancel}>Cancel</JetpackButton>
				<JetpackButton variant='contained' onClick={onConfirm}>Update name</JetpackButton>
			</DialogActions>
		</Dialog>
	);
}

function PasswordResetFeedbackMessage(type: 'success' | 'error' | 'none', message: string) {
	switch (type) {
		case 'none':
			// return <div style={{height: 16}} />;
			return null;
		case 'success':
			return (
				<Paragraph style={{color: colors.green}}>
					{message}
				</Paragraph>
			);
			case 'error':
				return (
					<Paragraph style={{color: colors.red}}>
						{message}
					</Paragraph>
				);
	}
}

// --------------------
// Types
// --------------------

type PasswordResetFeedback = {
	type: 'success' | 'error' | 'none';
	message: string;
}