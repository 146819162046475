import React, { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { SxProps } from '@mui/system';

import { useFriends } from '../utilities/friendContext';

export default function FriendPicker(props: FriendPickerProps) {
	const [inputValue, setInputValue] = useState<{key: string, label: string} | null>(null);
	const friendContext = useFriends();
	const friendsObject = friendContext.friends;
	const friends = friendsObject ?
		Object.entries(friendsObject).map(([userId, friend]) => {
			return {
				key: userId,
				label: friend.displayName || 'No name found'
			};
		})
		: [];

	// TODO: If user doesn't have any friends, add a button to take them to the collaboration page (so they can add friends).

	const handleSelect = (event: any, value: {key: string, label: string} | null) => {
		if (value && value.key && value.label) {
			props.onSelectFriend(value.key, value.label);
			setInputValue(null);
		}
	}
		
	return (
		<Autocomplete
			id='friend-picker-autocomplete'
			value={inputValue}
			blurOnSelect={true}
			onChange={handleSelect}
			options={friends}
			renderInput={(params) => <TextField {...params} label={props.label} />}
			sx={{ width: 'min(300px, 90%)' }}
			autoComplete
			isOptionEqualToValue={(option, value) => {return !!(option.key === value.key)}}
		/>
	);
}

// --------------------
// Types
// --------------------

type FriendPickerProps = {
	label: string;
	sx?: SxProps;
	onSelectFriend: (friendUserId: string, friendDisplayName: string) => void;
}