import React, { useEffect } from 'react';
import { UserManager } from 'oidc-client';
import { CircularProgress } from '@mui/material';

import { functionNames, useFirebase } from '../utilities/firebaseContext';
import { environmentType } from '../utilities/environmentUtils';
import { colors } from '../styles/colors';

export default function OutlookCalendarOAuthRedirect() {
	return (
		<OutlookCalendarOAuthRedirectWithContext />
	);
}

function OutlookCalendarOAuthRedirectWithContext() {
	const firebaseContext = useFirebase();
	const Functions = firebaseContext.Functions!;
	const path = window.location.href;

	useEffect(() => {
		const getUserManagerSettings = Functions.httpsCallable(functionNames.getCalendarOAuthSettings);

		getUserManagerSettings({environmentType: environmentType})
			.then((settings) => {
				new UserManager(settings.data['outlook'] as Oidc.UserManagerSettings)
					.signinPopupCallback(path);
			})
			.catch(error => {
				console.log('Error getting or setting UserManagerSettings.');
				console.error(error);
			});
	})

	return (
		<div style={{
			height: '100%',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: colors.backgroundPrimary,
			color: colors.textPrimary
		}}>
			<CircularProgress />
			<div style={{height: '16px'}} />
			{'Adding calendar account'}
		</div>
	);
}