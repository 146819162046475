import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useUser } from '../../utilities/userContext';
import { SettingsSectionContainer, SettingsSectionHeader } from './SettingsComponents';
import { timezones } from '../../constants/timezones';

const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export default function TimezoneSettings(props: TimezoneSettingsProps) {
	const userContext = useUser();
	const [timezone, setTimezone] = useState(userContext.user?.settings?.timezone || DEFAULT_TIMEZONE);

	// If timezone is not set, set it to the computer's local time
	useEffect(() => {
		if (!userContext.user) {
			// User has not been loaded yet. Do nothing.
		} else if (!userContext.user.settings || !userContext.user.settings.timezone) {
			// User exists, but doesn't have a timezone setting.
			userContext.setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIMEZONE);
		} else {
			setTimezone(userContext.user.settings.timezone);
		}
	}, [userContext]);

	const initialTimezoneForAutocomplete = timezones.find(el => el.iana === timezone);

	const handleTimezoneChangeAutocomplete = (event: any, value: {key: string, label: string, iana: string, group: string}) => {
		const newTimezone = value ? value.iana : DEFAULT_TIMEZONE;
		setTimezone(newTimezone);
		userContext.setTimezone(newTimezone);
	}

	if (props.invisible) {
		return <div />;
	} else {
		return (
			<SettingsSectionContainer>
				<SettingsSectionHeader>Timezone</SettingsSectionHeader>
				<Autocomplete
					id='timezone-autocomplete'
					value={initialTimezoneForAutocomplete}
					onChange={handleTimezoneChangeAutocomplete}
					options={timezones}
					groupBy={(option) => option.group}
					renderInput={(params) => <TextField {...params} label={null} />}
					sx={{ width: 'min(300px, 90%)' }}
					autoComplete
					autoHighlight
					disableClearable
					isOptionEqualToValue={(option, value) => {return !!(option.iana === value.iana)}}
				/>
			</SettingsSectionContainer>
		);
	}
}

// --------------------
// Types
// --------------------

type TimezoneSettingsProps = {
	invisible?: boolean;
};