export const HOME = '/';
export const LOGIN = '/login';
export const SIGNUP = '/signup';
export const ONBOARDING = '/welcome';
export const ACCEPT_INVITATION = '/acceptInvitation';
export const SPARKS = '/sparks';
export const COLLABORATION = '/collaboration';
export const GROUP_SCHEDULING = '/groupScheduling';
export const GROUP_SCHEDULING_INVITE = '/groupSchedulingInvite';
export const GROUP_SCHEDULING_INVITE_WELCOME = '/groupSchedulingInviteWelcome';
export const SETTINGS = '/settings';
export const CONFIRM_EMAIL = '/confirmEmail';
export const ADMIN = '/admin';
export const GOOGLE_CALENDAR_OAUTH_REDIRECT = '/googleCalendarOAuthRedirect';
export const OUTLOOK_CALENDAR_OAUTH_REDIRECT = '/outlookCalendarOAuthRedirect';