import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import { DateTime } from 'luxon';

import { useFirebase, functionNames } from './firebaseContext';
import { useUser } from './userContext';
import { useAnalytics, events } from './analyticsContext';
import { getFreeTimesFromResponses } from './scheduleHelpers';
import { groupSchedulingUpdateGroupResponsesEndpoint } from '../constants/cloudFunctionEndpoints';
import { GroupSchedulingEvent, GroupSchedulingEventUsers, GroupSchedulingEventResponses, TimeIntervalEpochSeconds } from '../types/jetpack/collaboration';
import * as ROUTES from '../constants/routes';

export const DAY_START_HOUR_DEFAULT = 9
export const DAY_END_HOUR_DEFAULT = 17
const INCLUDE_WEEKENDS_DEFAULT = false;
const updateGroupResponsesEndpoint = groupSchedulingUpdateGroupResponsesEndpoint();

const GroupSchedulingOwnerContext = createContext<GroupSchedulingOwnerContextType | undefined>(undefined);

export function GroupSchedulingOwnerProvider(props: GroupSchedulingProviderProps) {
	const { trackEvent } = useAnalytics();
	const [eventId, setEventId] = useState<string | undefined>(props.eventId || undefined);
	const [groupSchedulingEvent, setGroupSchedulingEvent] = useState<GroupSchedulingEvent | undefined>(undefined);
	const [resultFreeTimes, setResultFreeTimes] = useState<Array<TimeIntervalEpochSeconds>>([]);
	const firebaseContext = useFirebase();
	const Auth = firebaseContext.Auth;
	const Firestore = firebaseContext.Firestore!;
	const Functions = firebaseContext.Functions!;

	const history = useHistory();

	const userId = Auth?.currentUser?.uid!;

	const userContext = useUser();
	const displayName = userContext.user?.displayName;

	useEffect(() => {
		// Subscribe to event document to get updates in real time.
		let unsubscribeFromEvent: () => void;

		if (eventId) {
			subscribeToEvent(setGroupSchedulingEvent, setResultFreeTimes, eventId, userId, Firestore, history)
				.then(unsubscribe => {
					unsubscribeFromEvent = unsubscribe!;
				});
		}

		return () => {
			// Clean up subscriptions on context unmount.
			if (unsubscribeFromEvent) {
				unsubscribeFromEvent();
			}
		}
	}, [eventId, userId, Firestore, history])

	useEffect(() => {
		// Update participant responses.

		// Check both eventId and groupSchedulingEvent because otherwise, this will trigger twice: Once when the component loads but before the groupSchedulingEvent is loaded from the subscription, and once after the groupSchedulingEvent is loaded.
		if (eventId && groupSchedulingEvent) {
			Auth?.currentUser?.getIdToken()
				.then(idToken => {
					const requestData = {
						eventId: eventId
					};
					const authHeader = 'Bearer ' + idToken;
					const url = updateGroupResponsesEndpoint;
					return fetch(url, {
						method: 'POST',
						mode: 'cors',
						headers: {
							Authorization: authHeader,
							'Content-Type': 'application/json'
						},
						body: JSON.stringify(requestData)
					})
				})
				.catch(error => console.error(error));
		}
	
		// Make sure to update responses when the start or end date change
	}, [groupSchedulingEvent, groupSchedulingEvent?.availableStart, groupSchedulingEvent?.availableEnd, Auth?.currentUser, eventId])

	const createEvent = async () => {	
		// Default interval goes from next Monday to next Friday.
		const availableStartInit = DateTime.now().plus({ days: 7 }).set({ weekday: 1, hour: DAY_START_HOUR_DEFAULT, minute: 0, second: 0, millisecond: 0 }).toSeconds();
		const availableEndInit = DateTime.fromSeconds(availableStartInit).set({ weekday: 5, hour: DAY_END_HOUR_DEFAULT, minute: 0, second: 0, millisecond: 0 }).toSeconds();

		const availableIntervalsInit = makeAvailableIntervalsArray(availableStartInit, availableEndInit, DAY_START_HOUR_DEFAULT, DAY_END_HOUR_DEFAULT, INCLUDE_WEEKENDS_DEFAULT);

		return Firestore!.collection('groupSchedulingEvents')
			.add({
				schemaVersion: 1,
				title: 'New group event',
				users: {
					[userId]: {
						isOwner: true,
						displayName: displayName
					}
				},
				availableStart: availableStartInit,
				availableEnd: availableEndInit,
				dayStartHour: DAY_START_HOUR_DEFAULT,
				dayEndHour: DAY_END_HOUR_DEFAULT,
				includeWeekends: INCLUDE_WEEKENDS_DEFAULT,
				availableIntervals: availableIntervalsInit
			})
			.then(response => {
				trackEvent(events.CreatedGroupSchedulingEvent, {event_id: response.id});
				return response.id;
			})
			.catch(error => console.error(error));
	}

	const updateTitle = (newTitle: string) => {
		setGroupSchedulingEvent({...groupSchedulingEvent, ...{title: newTitle}});
		Firestore!.collection('groupSchedulingEvents').doc(eventId)
			.update({
				title: newTitle
			})
			.catch(error => console.error(error));
	}

	const updateAvailableStart = (newValue: number) => {
		setGroupSchedulingEvent({...groupSchedulingEvent, ...{availableStart: newValue}});
		const availableIntervals = makeAvailableIntervalsArray(newValue, groupSchedulingEvent?.availableEnd, groupSchedulingEvent?.dayStartHour, groupSchedulingEvent?.dayEndHour, groupSchedulingEvent?.includeWeekends);

		// Delete the old timestamp object first in a batch commit because otherwise, Firestore will keep values athat aren't updated (so you can't make the object smaller).
		const batch = Firestore!.batch();
		const docRef = Firestore!.collection('groupSchedulingEvents').doc(eventId);
		batch.update(docRef, {
			availableIntervals: firebase.firestore.FieldValue.delete()
		})
		batch.update(docRef, {
			availableStart: newValue,
			availableIntervals: availableIntervals
		})
		batch.commit()
			.catch(error => {
				console.error(error);
			});
	}

	const updateAvailableEnd = (newValue: number) => {
		setGroupSchedulingEvent({...groupSchedulingEvent, ...{availableEnd: newValue}});
		const availableIntervals = makeAvailableIntervalsArray(groupSchedulingEvent?.availableStart, newValue, groupSchedulingEvent?.dayStartHour, groupSchedulingEvent?.dayEndHour, groupSchedulingEvent?.includeWeekends);

		// Delete the old timestamp object first in a batch commit because otherwise, Firestore will keep values athat aren't updated (so you can't make the object smaller).
		const batch = Firestore!.batch();
		const docRef = Firestore!.collection('groupSchedulingEvents').doc(eventId);
		batch.update(docRef, {
			availableIntervals: firebase.firestore.FieldValue.delete()
		})
		batch.update(docRef, {
			availableEnd: newValue,
			availableIntervals: availableIntervals
		})
		batch.commit()
			.catch(error => {
				console.error(error);
			});
	}

	const updateDayStartHour = (newValue: number) => {
		// Multiply and divide by 1000 because Date() constructor needs epoch milliseconds (not seconds).
		const newAvailableStart = new Date(groupSchedulingEvent?.availableStart! * 1000).setHours(newValue || DAY_START_HOUR_DEFAULT) / 1000;

		setGroupSchedulingEvent({...groupSchedulingEvent, ...{dayStartHour: newValue}});
		const availableIntervals = makeAvailableIntervalsArray(newAvailableStart, groupSchedulingEvent?.availableEnd, newValue, groupSchedulingEvent?.dayEndHour, groupSchedulingEvent?.includeWeekends);

		// Delete the old timestamp object first in a batch commit because otherwise, Firestore will keep values athat aren't updated (so you can't make the object smaller).
		const batch = Firestore!.batch();
		const docRef = Firestore!.collection('groupSchedulingEvents').doc(eventId);
		batch.update(docRef, {
			availableIntervals: firebase.firestore.FieldValue.delete()
		})
		batch.update(docRef, {
			dayStartHour: newValue,
			availableStart: newAvailableStart,
			availableIntervals: availableIntervals
		})
		batch.commit()
			.catch(error => {
				console.error(error);
			});
	}

	const updateDayEndHour = (newValue: number) => {
		// Multiply and divide by 1000 because Date() constructor needs epoch milliseconds (not seconds).
		const newAvailableEnd = new Date(groupSchedulingEvent?.availableEnd! * 1000).setHours(newValue || DAY_END_HOUR_DEFAULT) / 1000;

		setGroupSchedulingEvent({...groupSchedulingEvent, ...{dayEndHour: newValue}});
		const availableIntervals = makeAvailableIntervalsArray(groupSchedulingEvent?.availableStart, newAvailableEnd, groupSchedulingEvent?.dayStartHour, newValue, groupSchedulingEvent?.includeWeekends);

		// Delete the old timestamp object first in a batch commit because otherwise, Firestore will keep values athat aren't updated (so you can't make the object smaller).
		const batch = Firestore!.batch();
		const docRef = Firestore!.collection('groupSchedulingEvents').doc(eventId);
		batch.update(docRef, {
			availableIntervals: firebase.firestore.FieldValue.delete()
		})
		batch.update(docRef, {
			dayEndHour: newValue,
			availableEnd: newAvailableEnd,
			availableIntervals: availableIntervals
		})
		batch.commit()
			.catch(error => {
				console.error(error);
			});
	}

	const updateIncludeWeekends = (newValue: boolean) => {
		setGroupSchedulingEvent({...groupSchedulingEvent, ...{includeWeekends: newValue}});
		const availableIntervals = makeAvailableIntervalsArray(groupSchedulingEvent?.availableStart, groupSchedulingEvent?.availableEnd, groupSchedulingEvent?.dayStartHour, groupSchedulingEvent?.dayEndHour, newValue);

		// Delete the old timestamp object first in a batch commit because otherwise, Firestore will keep values athat aren't updated (so you can't make the object smaller).
		const batch = Firestore!.batch();
		const docRef = Firestore!.collection('groupSchedulingEvents').doc(eventId);
		batch.update(docRef, {
			availableIntervals: firebase.firestore.FieldValue.delete()
		})
		batch.update(docRef, {
			includeWeekends: newValue,
			availableIntervals: availableIntervals
		})
		batch.commit()
			.catch(error => {
				console.error(error);
			});
	}

	const addUser = (newUserId: string, newUserDisplayName: string) => {
		Firestore?.collection('groupSchedulingEvents').doc(eventId)
			.update({
				[`users.${newUserId}`]: {
					isOwner: false,
					displayName: newUserDisplayName
				}
			})
			.then(() => {
				trackEvent(events.AddedFriendToGroupSchedulingEvent, {event_id: eventId, friend_id: newUserId});
			})
			.catch(error => {
				console.error('(addUser) Error adding user to group scheduling event.');
				console.error(error);
			})
	}

	const createCalendarEventLink = async (provider: 'google' | 'outlook', title: string, startTime: number, endTime: number, timezone: string, guestUserIds: Array<string>) => {
		if (provider === 'outlook') {
			console.error('(createCalendarEventLink) Outlook is not yet supported for creating calendar events.');
			return 'error';
		}
		
		const getUserEmailAddresses = Functions?.httpsCallable(functionNames.getUserEmailAddresses);
		const guestEmailAddresses: Array<string> = await getUserEmailAddresses({userIds: guestUserIds})
			.then(response => response.data)
			.then(data => {
				if (data['emailAddresses']) {
					return data['emailAddresses'];
				} else {
					return [];
				}
			})
			.catch(err => {
				console.error('(createCalendarEventLink) Error getting participant email addresses.');
				console.error(err);
			});

		const baseUrl = 'https://calendar.google.com/calendar/render?';

		const startTimeForGoogle = DateTime.fromSeconds(startTime).toUTC().toFormat("yyyyMMdd'T'HHmm'00Z'");
		const endTimeForGoogle = DateTime.fromSeconds(endTime).toUTC().toFormat("yyyyMMdd'T'HHmm'00Z'");

		const queryParams = {
			action: 'TEMPLATE',
			text: encodeURIComponent(title),
			dates: startTimeForGoogle + '/' + endTimeForGoogle,
			ctz: timezone,
			details: encodeURIComponent('Event created with Jetpack'),
			add: guestEmailAddresses.map((email) => encodeURIComponent(email)).join(',')
		}

		const queryStringsArray = Object.entries(queryParams).map(([key, value]) => {
			return `${key}=${value}`;
		});

		const url = baseUrl + queryStringsArray.join('&');

		return url;
	}

	const deleteEvent = () => {
		Firestore?.collection('groupSchedulingEvents').doc(eventId)
			.delete()
			.then(() => {
				trackEvent(events.DeletedGroupSchedulingEvent, {event_id: eventId});
			})
			.catch(error => {
				console.error(error);
			})
	}
	
	const forProvider = {
		eventId,
		title: groupSchedulingEvent?.title || '',
		availableStart: groupSchedulingEvent?.availableStart,
		availableEnd: groupSchedulingEvent?.availableEnd,
		dayStartHour: groupSchedulingEvent?.dayStartHour,
		dayEndHour: groupSchedulingEvent?.dayEndHour,
		includeWeekends: groupSchedulingEvent?.includeWeekends,
		users: groupSchedulingEvent?.users,
		responses: groupSchedulingEvent?.responses,
		resultFreeTimes,
		createEvent,
		setEventId,
		updateTitle,
		updateAvailableStart,
		updateAvailableEnd,
		updateDayStartHour,
		updateDayEndHour,
		updateIncludeWeekends,
		addUser,
		createCalendarEventLink,
		deleteEvent
	}

	return (
		<GroupSchedulingOwnerContext.Provider value={forProvider}>
			{props.children}
		</GroupSchedulingOwnerContext.Provider>
	);
}

export function useGroupSchedulingOwner() {
	const context = useContext(GroupSchedulingOwnerContext);
	if (context === undefined) {
		throw new Error('useGroupScheduling must be used within a GroupSchedulingProvider.');
	}
	return context;
}

async function subscribeToEvent(setGroupSchedulingEvent: React.Dispatch<React.SetStateAction<GroupSchedulingEvent | undefined>>, setResultFreeTimes: React.Dispatch<React.SetStateAction<Array<TimeIntervalEpochSeconds>>>, eventId: string, userId: string, Firestore: firebase.firestore.Firestore, history: any) {
	return Firestore.collection('groupSchedulingEvents')
		.doc(eventId)
		.onSnapshot(response => {
			if (!response.exists) {
				history.replace(ROUTES.COLLABORATION);
			}
			const data = response.data() as GroupSchedulingEvent;

			if (!data || !data.users || !data.users[userId] || !data.users[userId].isOwner) {
				history.replace(ROUTES.GROUP_SCHEDULING + '/' + eventId);
			} else {
				setGroupSchedulingEvent(data);
			}

			if (data.responses && data.availableIntervals) {
				const responseFreeTimes = getFreeTimesFromResponses(data.availableIntervals, data.responses);
				if (responseFreeTimes && responseFreeTimes.length > 0) {
					setResultFreeTimes(responseFreeTimes);
				}
			}
		});
}

function makeAvailableIntervalsArray(start?: number, end?: number, dayStartHour?: number, dayEndHour?: number, includeWeekends?: boolean) {
	if (!start) {
		console.error('(makeAvailableIntervalsArray) Missing start date.');
		return;
	}
	if (!end) {
		console.error('(makeAvailableIntervalsArray) Missing end date.');
		return;
	}
	if (dayStartHour === undefined) {
		console.error('(makeAvailableIntervalsArray) Missing day start hour.');
		return;
	}
	if (dayEndHour === undefined) {
		console.error('(makeAvailableIntervalsArray) Missing day end hour.');
		return;
	}
	if (includeWeekends === undefined) {
		console.error('(makeAvailableIntervalsArray) Missing includeWeeneds.');
		return;
	}
	
	const availableIntervals: Array<TimeIntervalEpochSeconds> = [];

	let stepper = start;
	const SECONDS_IN_DAY = 60*60*24;

	while (stepper < end) {
		const intervalStart = DateTime.fromSeconds(stepper).set({ hour: dayStartHour, minute: 0, second: 0, millisecond: 0 });
		const intervalEnd = DateTime.fromSeconds(stepper).set({ hour: dayEndHour, minute: 0, second: 0, millisecond: 0 });

		if (!includeWeekends && (intervalStart.weekday === 6 || intervalStart.weekday === 7) && (intervalEnd.weekday === 6 || intervalEnd.weekday === 7)) {
			stepper += SECONDS_IN_DAY;
			continue;
		}

		availableIntervals.push({start: intervalStart.toSeconds(), end: intervalEnd.toSeconds()});
		stepper += SECONDS_IN_DAY;
	}

	return availableIntervals;
}

// --------------------
// Types
// --------------------

type GroupSchedulingOwnerContextType = {
	eventId?: string;
	title: string;
	availableStart: number | undefined;
	availableEnd: number | undefined;
	dayStartHour: number | undefined;
	dayEndHour: number | undefined;
	includeWeekends: boolean | undefined;
	users: GroupSchedulingEventUsers | undefined;
	responses: GroupSchedulingEventResponses | undefined;
	resultFreeTimes: Array<TimeIntervalEpochSeconds>;
	createEvent: () => Promise<string | void>;
	setEventId: React.Dispatch<React.SetStateAction<string | undefined>>;
	updateTitle: (newTitle: string) => void;
	updateAvailableStart: (newValue: number) => void;
	updateAvailableEnd: (newValue: number) => void;
	updateDayStartHour: (newValue: number) => void;
	updateDayEndHour: (newValue: number) => void;
	updateIncludeWeekends: (newValue: boolean) => void;
	addUser: (newUserId: string, newUserDisplayName: string) => void;
	createCalendarEventLink: (provider: 'google' | 'outlook', title: string, startTime: number, endTime: number, timezone: string, guestUserIds: Array<string>) => Promise<string>;
	deleteEvent: () => void;
}

type GroupSchedulingProviderProps = {
	children: React.ReactNode;
	eventId?: string;
}