import React, { createContext, useContext } from 'react';
import { UserManager, UserManagerSettings } from 'oidc-client';
import { useUser } from './userContext';
import { functionNames, useFirebase } from './firebaseContext';
import { Calendar, CalendarAccount } from '../types/jetpack/user';
import { environmentType } from './environmentUtils';

const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

export function CalendarProvider({children}: ProviderProps) {
	const userContext = useUser();
	const firebaseContext = useFirebase();
	const Functions = firebaseContext.Functions!;

	const launchGoogleCalendarOAuthFlow = async (googleUserManagerSettings: UserManagerSettings) => {
		if (googleUserManagerSettings) {
			const googleUserManager = new UserManager(googleUserManagerSettings);
			let refreshToken = '';
			let accessToken = '';
			let googleId = '';
			let emailAddress = '';
			googleUserManager.signinPopup()
				.then(result => {
					// In the future, if we need to, we can store the access token, expiration time, and refresh token in the client to use later
					accessToken = result.access_token;
					refreshToken = result.refresh_token || '';
					return(result);
				})
				.then(result => {
					// Get the user's Google profile info
					const authHeader = 'Bearer ' + result.access_token;
					const profileUrl = 'https://www.googleapis.com/oauth2/v2/userinfo';
					return fetch(profileUrl, {
						method: 'GET',
						headers: {
							Authorization: authHeader
						}
					})
				})
				.then(response => response.json())
				.then(data => {
					googleId = data.id;
					emailAddress = data.email;
					return data;
				})
				.then(data => {
					const calendarListUrl = 'https://www.googleapis.com/calendar/v3/users/me/calendarList';
					return fetch(calendarListUrl, {
						method: 'GET',
						headers: {
							Authorization: 'Bearer ' + accessToken
						}
					});
				})
				.then(response => response.json())
				.then(data => {
					const calendarList = data['items'];
					let calendars: {[key: string]: Calendar} = {};

					calendarList.forEach((calendarReceived: any) => {
						// For now, only add the primary calendar
						// In the future, we could add other calendars with the option to enable/disable them
						if (!!calendarReceived.primary) {
							let calendar: Calendar = {
								id: calendarReceived.id,
								name: calendarReceived.summary,
								isPrimary: !!calendarReceived.primary
							};
							calendars[calendarReceived.id] = calendar;
						}
					});

					const calendarAccount: CalendarAccount = {
						id: googleId,
						provider: 'google',
						username: emailAddress,
						refreshToken: refreshToken,
						calendars: calendars
					};
					return userContext.addCalendarAccount(calendarAccount);
				})
				.catch(error => {
					console.error('Error with Google OIDC signin popup.');
					console.error(error);
				});
		} else {
			console.error('Failed to get UserManagerSettings for a Google account from getUserManagerSettings.');
		}
	}

	const launchOutlookCalendarOAuthFlow = async (outlookUserManagerSettings: UserManagerSettings) => {
		if (outlookUserManagerSettings) {
			const outlookUserManager = new UserManager(outlookUserManagerSettings);
			let refreshToken = '';
			let accessToken = '';
			let userId = '';
			let username = '';
			outlookUserManager.signinPopup()
				.then(result => {
					accessToken = result.access_token;
					refreshToken = result.refresh_token || '';
					if (refreshToken === '') {
						console.error('No refresh token received from Outlook.');
					}
					return(result);
				})
				.then(result => {
					const profileUrl = 'https://graph.microsoft.com/v1.0/me/'
					return fetch(profileUrl, {
						method: 'GET',
						headers: {
							Authorization: 'Bearer ' + accessToken
						}
					})
				})
				.then(response => response.json())
				.then(data => {
					userId = data.id;
					username = data.userPrincipalName;
					return data;
				})
				.then(data => {
					const calendarListUrl = 'https://graph.microsoft.com/v1.0/me/calendars'
					return fetch(calendarListUrl, {
						method: 'GET',
						headers: {
							Authorization: 'Bearer ' + accessToken
						}
					});
				})
				.then(response => response.json())
				.then(data => {
					const calendarList = data['value']
					let calendars: {[key: string]: Calendar} = {};

					calendarList.forEach((calendarReceived: any) => {
						// For now, only add the default calendar
						// In the future, we could add other calendars with the option to enable/disable them
						if (!!calendarReceived.isDefaultCalendar) {
							let calendar: Calendar = {
								id: calendarReceived.id,
								name: calendarReceived.name,
								owner: calendarReceived.owner.address,
								isPrimary: !!calendarReceived.isDefaultCalendar
							};
							calendars[calendarReceived.id] = calendar;
						}
					});

					let originBase = '';
					switch (environmentType) {
						case 'prod':
							originBase = 'https://app.jetpackai.com/';
							break;
						case 'test':
							originBase = 'https://test.jetpackai.com/';
							break;
						case 'localhost':
							originBase = 'http://localhost:3000/';
							break;
						default:
							console.error('(launchOutlookCalendarOAuthFlow) Error getting environment from URL.');
							originBase = 'https://app.jetpackai.com/';
					}

					const calendarAccount: CalendarAccount = {
						id: userId,
						provider: 'outlook',
						username: username,
						refreshToken: refreshToken,
						refreshTokenOrigin: originBase + 'outlookCalendarOAuthRedirect',
						calendars: calendars
					};
					return userContext.addCalendarAccount(calendarAccount);
				})
				.catch(error => {
					console.error('Error with Outlook OIDC signin popup.');
					console.error(error);
				});
		} else {
			console.error('Failed to get UserManagerSettings for an Outlook account from getUserManagerSettings.');
		}
	}

	const getOauthSettings = async () => {
		const getUserManagerSettings = Functions.httpsCallable(functionNames.getCalendarOAuthSettings);

		const googleUserManagerSettings = await getUserManagerSettings({environmentType: environmentType})
			.then((settings) => {
				return settings.data as OAuthSettings;
			})
			.catch(error => {
				console.error('Error getting or setting UserManagerSettings.');
				console.error(error);
				return null;
			});

		return googleUserManagerSettings;
	}

	const forProvider = {
		launchGoogleCalendarOAuthFlow: launchGoogleCalendarOAuthFlow,
		launchOutlookCalendarOAuthFlow: launchOutlookCalendarOAuthFlow,
		getOauthSettings: getOauthSettings
	};

	return (
		<CalendarContext.Provider value={forProvider}>
			{children}
		</CalendarContext.Provider>
	);
}

export function useCalendar() {
	const context = useContext(CalendarContext);
	if (context === undefined) {
		throw new Error('useCalendar must be used within a CalendarProvider.');
	}
	return context;
}

// --------------------
// Types
// --------------------

export type CalendarContextType = {
	launchGoogleCalendarOAuthFlow: (googleUserManagerSettings: UserManagerSettings) => void;
	launchOutlookCalendarOAuthFlow: (outlookUserManagerSettings: UserManagerSettings) => void;
	getOauthSettings: () => Promise<OAuthSettings | null>;
};

type ProviderProps = {children: React.ReactNode};

export type OAuthSettings = {
	google: Oidc.UserManagerSettings;
	outlook: Oidc.UserManagerSettings;
}