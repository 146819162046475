import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import { ThemeProvider} from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import { theme } from './styles/materialUiTheme';
import LoginSignupPage from './components/LoginSignupPage';
import GroupSchedulingInviteLoggedOutLandingPage from './components/GroupScheduling/GroupSchedulingInviteLoggedOutLandingPage';
import Onboarding from './components/Onboarding';
import LoggedInApp from './components/LoggedInApp';
import { AnalyticsProvider } from './utilities/analyticsContext';
import { FirebaseProvider, useFirebase } from './utilities/firebaseContext';
import { FeatureProvider } from './utilities/feature-context';
import { AuthProvider, useAuth } from './utilities/authContext';
import { JetpackSnackbarProvider } from './components/Snackbar';
import * as ROUTES from './constants/routes';
import { colors } from './styles/colors';

function App() {
  return (
    <FirebaseProvider>
      <AnalyticsProvider>
        <FeatureProvider>
          <AppWithData />
        </FeatureProvider>
      </AnalyticsProvider>
    </FirebaseProvider>
  );
}

function AppWithData() {
  const firebase = useFirebase();
  if (!firebase.isInitialized) {
    return null;
  } else return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <JetpackSnackbarProvider>
          <AppRouter />
        </JetpackSnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

function AppRouter() {
  const auth = useAuth();
  return (!auth.user && auth.loading) ? <LoadingScreen /> : (
    <Router>
      <Switch>
        <Route path={ROUTES.LOGIN}>
          <LoginSignupPage initialLoginOrSignup='login' />
        </Route>
        <Route path={ROUTES.SIGNUP}>
          <LoginSignupPage initialLoginOrSignup='signup' />
        </Route>
        <Route path={ROUTES.GROUP_SCHEDULING_INVITE_WELCOME} component={GroupSchedulingInviteLoggedOutLandingPage} />
        <Route path={ROUTES.ONBOARDING}>
          <Onboarding />
        </Route>
        <PrivateRoute>
          <LoggedInApp />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

function PrivateRoute({ children, ...rest }: any) {
  let auth = useAuth();

  // TODO: Make location state type more robust (store it somewhere and reference it everywhere)
  let location = useLocation<{from: string}>();

  if (auth.user) {
    // Logged in.
    return (
      <Route
        {...rest}
        render={() => children}
      />
    );
  } else {
    // Not logged in. Store path to location state and redirect to login screen.
    if (location.search.includes('groupSchedulingInvite')) {
      return (
        <Redirect
          to={{
            pathname: ROUTES.GROUP_SCHEDULING_INVITE_WELCOME,
            state: { from: location.pathname },
            search: location.search
          }}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: ROUTES.LOGIN,
          state: { from: location.pathname },
          search: location.search
        }}
      />
    );
  }
}

function LoadingScreen() {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: colors.jetpackBackgroundDark
      }}
    >
      <CircularProgress color='primary' />
    </div>
  )
}

export default App;