import React from 'react';
import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';

import { colors } from '../styles/colors';

export const Heading1 = styled.h1<TextProps>`
	color: ${props => {
		if (props.colorType) {
			return getTextColor(props.colorType);
		} else {
			return '';
		}
	}};
	margin-top: ${props => props.collapseMarginTop ? '0' : ''};
	margin-bottom: ${props => props.collapseMarginBottom ? '0' : ''};
`;

export const Heading1Gradient = styled.h1<GradientHeadingProps>`
	color: transparent;
	background: linear-gradient(45deg, ${colors.jetpackMint}, ${colors.jetpackAqua});
	background-clip: text;
	-webkit-background-clip: text;
	width: fit-content;
	margin-top: ${props => props.collapseMarginTop ? '0' : ''};
	margin-bottom: ${props => props.collapseMarginBottom ? '0' : ''};
`;

export const Heading2 = styled.h2<TextProps>`
	color: ${props => getTextColor(props.colorType)};
`;

export const Heading3 = styled.h3<TextProps>`
	color: ${props => getTextColor(props.colorType)};
`;

export const Paragraph = styled.p<TextProps>`
	color: ${props => getTextColor(props.colorType)};
`;

export const SimpleText = styled.p<TextProps>`
	color: ${props => getTextColor(props.colorType)};
	margin: 0;
	padding: 0;
`;

export const Link = (props: {url: string, text?: string, children?: React.ReactNode}) => {
	return (
		<MuiLink
			to={props.url}
			component={RouterLink}
			underline='hover'
			sx={{
				cursor: 'pointer'
			}}
		>
			{props.text ? props.text : null}
			{props.children ? props.children : null}	
		</MuiLink>
	);
}

export const LinkText = (props: {onClick?: () => void, text?: string, children?: React.ReactNode}) => {
	return (
		<MuiLink
			onClick={props.onClick}
			underline='hover'
			sx={{
				cursor: 'pointer'
			}}
		>
			{props.text ? props.text : null}
			{props.children ? props.children : null}
		</MuiLink>
	);
}

export const ExternalLink = (props: {href?: string, children?: React.ReactNode}) => {
	return (
		<MuiLink
			href={props.href}
			target='_blank'
			rel='noopener'
			underline='hover'
			sx={{
				cursor: 'pointer'
			}}
		>
			{props.children}
		</MuiLink>
	);
}

const getTextColor = (colorType: TextProps['colorType']) => {
	switch (colorType) {
		case 'dark':
			return colors.textDark;
		case 'darkAccent':
			return colors.textDarkAccent;
		case 'light':
			return colors.textLight;
		case 'lightAccent':
			return colors.textLightAccent;
		default:
			return;
	}
}

// --------------------
// Types
// --------------------

type TextProps = {
	colorType?: 'dark' | 'darkAccent' | 'light' | 'lightAccent';
	collapseMarginTop?: boolean;
	collapseMarginBottom?: boolean;
}

type GradientHeadingProps = {
	collapseMarginTop?: boolean;
	collapseMarginBottom?: boolean;
}