import React, { createContext, useContext, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useFirebase } from './firebaseContext';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { Spark } from '../types/jetpack/spark';

const SparkContext = createContext<SparkContextType | undefined>(undefined);

export function SparkProvider({children}: ProviderProps) {
	const firebaseContext = useFirebase();
	const Firebase = firebaseContext.Firebase!;
	const Auth = firebaseContext.Auth!;
	const Firestore = firebaseContext.Firestore!;
	
	useEffect(() => {
		subscribeToSparkUpdates(populateSparksFromFirebase, Firebase, Auth, Firestore);
		return(unsubscribeFromSparkUpdates);
	}, [Firebase, Auth, Firestore]);

	// TODO: Do something fancier here if we don't want to replace the entire state every time one spark is updated
	// Maybe use React Query (https://react-query.tanstack.com/)
	const [sparks, setSparks] = useState<Array<Spark>>([]);
	const [selectedSparkId, setSelectedSparkId] = useState<string | undefined>(undefined);
	const [sparkDetailOpenState, setSparkDetailOpenState] = useState(false);
	
	const createSpark = (spark: Spark) => {
		// TODO: Do I return this as a promise so that the calling function can handle the error if needed?
		Firestore.collection('sparks').add({
			userId: Auth.currentUser?.uid,
			creator: Auth.currentUser?.uid,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			modified: firebase.firestore.FieldValue.serverTimestamp(),
			isDeleted: false,
			...spark
		})
		.then(response => {
			// TODO: Handle response
		})
		.catch(error => {
			// TODO: Handle error
			console.log('Error creating spark:');
			console.log(error);
		})
	};

	const updateSpark = (spark: Spark) => {
		if (!spark.id) {
			console.log('Error: No spark ID for attempted update.');
			// TODO: Handle the error
		} else {
			Firestore.collection('sparks').doc(spark.id).update({
				...spark,
				modified: firebase.firestore.FieldValue.serverTimestamp()
			})
			.then(response => {
				// TODO: Handle response
			})
			.catch(error => {
				// TODO: Handle error
				console.log('Error updating spark:');
				console.log(error);
			})
		}
	};

	const updateSparkDebounced = debounce(updateSpark, 1500);

	const deleteField = (sparkId: string, fieldName: string) => {
		const docRef = Firestore.collection('sparks').doc(sparkId);
		const removalObject: any = {};
		removalObject[fieldName] = firebase.firestore.FieldValue.delete();
		docRef.update(removalObject)
		.then(response => {
			// TODO: Handle response on success
		})
		.catch(error => {
			// TODO: Handle error
			console.log(`Error deleting field: ${fieldName}`);
			console.log(error);
		});
	}

	const deleteSpark = (sparkId: string) => {
		closeSparkDetail();
		if (!sparkId) {
			console.log('Error: No spark ID for attempted delete.');
			// TODO: Handle the error
		}
		// Do this on a timeout to give detail a chance to animate away (especially on mobile)
		setTimeout(() => {
			Firestore.collection('sparks').doc(sparkId).update({
				isDeleted: true,
				modified: firebase.firestore.FieldValue.serverTimestamp()
			})
			.then(response => {
				// TODO: Handle response
				selectSpark(null);
			})
			.catch(error => {
				// TODO: Handle error
				console.log('Error deleting spark:');
				console.log(error);
			});
		}, 250);
	}

	const populateSparksFromFirebase = (sparks: Array<Spark>) => {
		// TODO: Do something fancier here if we don't want to replace the entire state every time one spark is updated
		// Maybe use React Query (https://react-query.tanstack.com/)
		setSparks(sparks);
	}

	const selectSpark = (sparkId: string | null) => {
		if (!sparkId) {
			setSelectedSparkId(undefined);
		} else if (sparks) {
			const foundSpark = sparks.find((thisSpark: Spark) => {
				return thisSpark.id === sparkId;
			});
			if (foundSpark) {
				setSelectedSparkId(foundSpark.id);
				openSparkDetail();
			} else {
				setSelectedSparkId(undefined);
			}
		}
		// Note: This whole thing could be simplified by just naively setting selectedSparkId to the sparkId passed to this function
	}

	const isSparkDetailOpen = () => {
		return sparkDetailOpenState;
	}

	const openSparkDetail = () => {
		setSparkDetailOpenState(true);
	}

	const closeSparkDetail = () => {
		setSparkDetailOpenState(false);
	}

	const forProvider = {
		sparks,
		selectedSparkId,
		createSpark,
		updateSpark,
		updateSparkDebounced,
		deleteField,
		deleteSpark,
		populateSparksFromFirebase,
		selectSpark,
		isSparkDetailOpen,
		openSparkDetail,
		closeSparkDetail
	};

	return (
		<SparkContext.Provider value={forProvider}>
			{children}
		</SparkContext.Provider>
	);
}

export function useSparks() {
	const context = useContext(SparkContext);
	if (context === undefined) {
		throw new Error('useSparks must be used within a SparkProvider');
	}
	return context;
}

let unsubscribe: () => void = () => {
	console.log(`Can't unsubscribe from spark listener: No Firebase listener to unsubscribe from.`);
};

async function subscribeToSparkUpdates(populateSparks: (sparks: Array<Spark>) => void, Firebase: firebase.app.App, Auth: firebase.auth.Auth, Firestore: firebase.firestore.Firestore) {
	const sparkConverter = {
		toFirestore: convertSparkToFirestore,
		fromFirestore: convertSparkFromFirestore
	};

	unsubscribe = Firestore.collection('sparks')
		.where('userId', '==', Auth.currentUser?.uid)
		.where('isDeleted', '!=', true)		// Note: Could make this == false; wouldn't have to use a composite Firestore index
		.withConverter(sparkConverter)
		.onSnapshot((querySnapshot) => {
			let receivedSparks: Array<firebase.firestore.DocumentData> = [];

			querySnapshot.forEach((doc) => {
				const snapshotOptions: firebase.firestore.SnapshotOptions = {
					serverTimestamps: 'estimate'
				} as firebase.firestore.SnapshotOptions;

				const receivedSpark = {
					...doc.data(snapshotOptions),
					id: doc.id
				};

				receivedSparks.push(receivedSpark);
			});

			populateSparks(receivedSparks);
		});
}

function unsubscribeFromSparkUpdates() {
	unsubscribe();
}

function convertSparkToFirestore(spark: Spark): firebase.firestore.DocumentData {
	return spark;
}

function convertSparkFromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): Spark {
	const data = snapshot.data(options)!;

	if (data.created) data.created = data.created.toDate();
	if (data.modified) data.modified = data.modified.toDate();
	if (data.dueDate) data.dueDate = data.dueDate.toDate();
	
	return data as Spark;
}

// --------------------
// Types
// --------------------

type SparkContextType = {
	sparks: Array<Spark>,
	selectedSparkId: string | undefined,
	createSpark: (spark: Spark) => void,
	updateSpark: (spark: Spark) => void,
	updateSparkDebounced: (spark: Spark) => void,
	deleteField: (sparkId: string, fieldName: string) => void,
	deleteSpark: (sparkId: string) => void,
	populateSparksFromFirebase: (sparks: Array<Spark>) => void,
	selectSpark: (sparkId: string | null) => void,
	isSparkDetailOpen: () => boolean,
	openSparkDetail: () => void,
	closeSparkDetail: () => void
};

type ProviderProps = {children: React.ReactNode};