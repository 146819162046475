import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { IconButton, InputBase, CardProps } from '@mui/material';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import { FaArrowCircleUp, FaCompressArrowsAlt, FaInfo } from 'react-icons/fa';

import { useChat, Message } from '../utilities/chatContext';
import JetpackCard from './JetpackCard';
import { ExternalLink } from './JetpackText';
import TypingIndicator from '../assets/TypingIndicator.json';
import { environmentType } from '../utilities/environmentUtils';
import { colors } from '../styles/colors';

// Change the boolean at the end to enable/disable test messages in the localhost environment.
// Useful for working on layout.
const showTestMessages = (environmentType === 'localhost') && false;

export default function HomePageDataWrapper() {
	const chat = useChat();

	return (
		<HomePageDisplayComponent
			messages={chat.messages}
			jetpackIsTyping={chat.jetpackIsTyping}
			sendMessage={chat.sendMessage}
		/>
	);
}

export function HomePageDisplayComponent(props: HomePageProps) {
	const bottomOfMessageAreaRef = useRef<null | HTMLDivElement>(null);
	useEffect(() => {
		scrollToBottom()
	}, [props.messages]);

	function scrollToBottom() {
		bottomOfMessageAreaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
	}

	function handleUserSendChat(userMessage: string) {
		if (userMessage) {
			props.sendMessage({body: userMessage, sender: 'user'});
		}
	}

	return (
		<HomePageContainer>
			<InfoBox />
			<MessagesArea>
				<Messages>
					{
						showTestMessages ?
						messagesForTesting.map((m, index) => {
							return <MessageView key={index} body={m.body} sender={m.sender} />;
						})
						: null
					}
					{
						props.messages.map((m, index) => {
							return <MessageView key={index} body={m.body} sender={m.sender} />;
						})
					}
					{
						props.jetpackIsTyping ?
						<TypingIndicatorBubble />
						: null
					}
					<div ref={bottomOfMessageAreaRef} style={{marginTop: '8px'}} />
				</Messages>
			</MessagesArea>
			<ComposeArea onUserSendChat={handleUserSendChat} />
		</HomePageContainer>
	);
}

function InfoBox() {
	const [isExpanded, setIsExpanded] = useState(true);

	return (
		<InfoBoxCard isExpanded={isExpanded}>
			{
				isExpanded ?
				<Fragment>
					<CollapseIcon handleCollapseClick={() => setIsExpanded(false)} />
					<h3>Welcome to Jetpack</h3>
					<p>Start by sending a message to Jetpack.</p>
					<p>Try something like "Remind me to buy milk tomorrow."</p>
					<p>See the <ExternalLink href='https://jetpackai.notion.site/Jetpack-Guide-bbb4243cda6549d2bc841b3b7d9c800d'>Jetpack Guide</ExternalLink> for more things you can do.</p>
				</Fragment>
				:
				<Fragment>
					<IconButton color='primary' size='small' onClick={() => setIsExpanded(true)}>
						<FaInfo/>
					</IconButton>
				</Fragment>
			}
		</InfoBoxCard>
	);
}

function CollapseIcon(props: {handleCollapseClick: () => void}) {
	return(
		<IconButton
			onClick={props.handleCollapseClick}
			size='small'
			sx={{
				position: 'absolute',
				top: '8px',
				right: '8px',
				color: colors.gray4
			}}
		>
			<FaCompressArrowsAlt
			/>
		</IconButton>
	);
}

function MessageView(props: MessageViewProps) {
	const messageViewStyle: React.CSSProperties = {
		alignSelf: props.sender === 'user' ? 'flex-end' : 'flex-start',
		backgroundColor: props.sender === 'user' ? colors.blue : colors.gray2
	};

	const composedStyle: React.CSSProperties = {...messageViewStyle, ...props.style};

	return (
		<MessageBubble
			style={composedStyle}
		>
			{props.body}
			{props.children}
		</MessageBubble>
	);
}

function TypingIndicatorBubble() {
	return (
		<MessageView key={'jetpack-typing'} sender='jetpack' style={{height: '18px'}}>
			<Lottie
				loop
				play
				animationData={TypingIndicator}
				style={{
					height: '48px',
					marginTop: '-16px',
				}}
			/>
		</MessageView>
	);
}

function ComposeArea(props: ComposeBarProps) {
	const [inputText, setInputText] = useState('');

	const handleChangeText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		e.preventDefault();
		setInputText(e.target.value);
	}

	const handleUserSendChat = (textToSend: string) => {
		props.onUserSendChat(textToSend);
		setInputText('');
	}
	
	const handleKeyPress = (e: string) => {
		if (e === 'Enter') {
			handleUserSendChat(inputText);
		}
	}

	return (
		<ComposeAreaContainer>
			<ComposeBarContainer>
				<ComposeBarInput
					placeholder='Send a message to Jetpack'
					value={inputText}
					onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeText(e)}
					onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => handleKeyPress(e.key)}
					inputProps={{
						style: {
							padding: 0
						}
					}}
				/>
				<IconButton
					onClick={() => handleUserSendChat(inputText)}
				>
				<FaArrowCircleUp
					size={32}
					color={colors.jetpackMint}
					style={{
						paddingRight: 4
					}}
				/>
				</IconButton>
			</ComposeBarContainer>
		</ComposeAreaContainer>
	);
}

const HomePageContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	color: ${colors.textPrimary};
	background-color: ${colors.backgroundPrimary};
`;

const InfoBoxCard = styled(({isExpanded, ...rest}: InfoBoxCardProps) => <JetpackCard {...rest} />)<InfoBoxCardProps>`
	position: absolute;
	top: 16px;
	z-index: 1;
	align-self: center;
	text-align: center;

	width: ${props => props.isExpanded ? '80%' : 'fit-content'};
	max-width: ${props => props.isExpanded ? '576px' : 'fit-content'};
	border-radius: ${props => props.isExpanded ? null: '16px'};
	padding: ${props => props.isExpanded ? '0px 16px': '2px'};
	aspect-ratio: ${props => props.isExpanded ? 'auto' : 1};
`;

const MessagesArea = styled.div`
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	overflow-y: hidden;
`;

const Messages = styled.div`
	width: 100%;
	padding: 0 12px 0px 16px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
`;

const MessageBubble = styled.div`
	width: fit-content;
	max-width: 80%;
	padding: 8px 16px;
	margin: 8px 0;
	border-radius: 16px;
	color: ${colors.textPrimary};
	box-shadow: -4px 4px 0px 0px ${colors.jetpackMint};
	white-space: pre-wrap;
`;

const ComposeAreaContainer = styled.div`
	padding: 12px 12px 16px 16px;
	border-top: 1px solid ${colors.gray2};
`;

const ComposeBarContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 32px;
	background-color: ${colors.gray1};
	box-shadow: -4px 4px 0px 0px ${colors.jetpackMint};
`;

const ComposeBarInput = styled(InputBase)`
	width: 100%;
	margin: 0 16px;
	color: ${colors.textPrimary};
`;

// --------------------
// Types
// --------------------

type HomePageProps = {
	messages: Array<Message>;
	jetpackIsTyping: boolean;
	sendMessage: (message: Message) => void;
}

type InfoBoxCardProps = {
	isExpanded: boolean;
} & CardProps;

type MessageViewProps = {
	body?: string;
	sender: 'user' | 'jetpack';
	style?: React.CSSProperties;
	children?: React.ReactNode;
}

type ComposeBarProps = {
	onUserSendChat: (userMessage: string) => void;
}

// --------------------
// For development and testing
// --------------------

const messagesForTesting: Array<Message> = [
	{
		body: 'Test1',
		sender: 'user'
	},
	{
		body: 'Comfirmed',
		sender: 'jetpack'
	},
	{
		body: 'Test2',
		sender: 'user'
	},
	{
		body: 'Comfirmed',
		sender: 'jetpack'
	},
	{
		body: 'Test3',
		sender: 'user'
	},
	{
		body: 'Comfirmed',
		sender: 'jetpack'
	},
	{
		body: 'Test4',
		sender: 'user'
	},
	{
		body: 'Comfirmed',
		sender: 'jetpack'
	},
	{
		body: 'Test5',
		sender: 'user'
	},
	{
		body: 'Comfirmed',
		sender: 'jetpack'
	},
	{
		body: 'Test6',
		sender: 'user'
	},
	{
		body: 'Comfirmed',
		sender: 'jetpack'
	},
	{
		body: 'Test7',
		sender: 'user'
	},
	{
		body: 'Comfirmed',
		sender: 'jetpack'
	},
	{
		body: 'Test8',
		sender: 'user'
	},
	{
		body: 'Comfirmed',
		sender: 'jetpack'
	},
];