// Change this boolean to toggle whether or not to use local emulators when the front end is running on localhost.
// Leave this set to true until you're ready for data to persist and be accessible to others.
// This should be set to true when merging PRs.
const USE_EMULATORS_FOR_LOCALHOST: boolean = true;

function getEnvironmentFromUrl() {
	const windowHost = window.location.host;
	if (windowHost.startsWith('app')) {
		return 'prod';
	} else if (windowHost.startsWith('test')) {
		return 'test';
	} else if (windowHost.startsWith('localhost')) {
		return 'localhost';
	} else {
		console.error(`getEnvironmentFromUrl Error: window.location.host should start with 'app,' 'test,' or 'localhost.' Actual window.location.host: ${window.location.host}`);
		return 'error';
	}
}

export const environmentType = getEnvironmentFromUrl();

export const useLocalEmulators = (environmentType === 'localhost' && USE_EMULATORS_FOR_LOCALHOST) ? true : false;