import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { FaHome, FaRegCalendarAlt, FaCog, FaListUl, FaUserFriends } from 'react-icons/fa';
import { motion } from 'framer-motion';
import * as ROUTES from '../constants/routes';
import { colors, componentColors } from '../styles/colors';

const desktopVariants = {
	minimized: {
		width: 56,
		transition: {
			type: 'spring',
			mass: 2,
			stiffness: 300,
			damping: 30
		}
	},
	expanded: {
		width: '100%',
		transition: {
			type: 'spring',
			mass: 2,
			stiffness: 300,
			damping: 30
		}
	}
}

const mobileVariants = {
	minimized: {
		width: 'auto'
	},
	expanded: {
		width: 'auto'
	}
}

export default function Navbar(props: NavbarProps) {
	let [expanded, setExpanded] = useState(props.isMobile);
	
	return (
		<motion.div
			initial='minimized'
			whileHover='expanded'
			animate='rest'
			style={{
				height: '100%'
			}}
		>
			<NavbarArea
				onMouseOver={() => setExpanded(true)}
				onMouseLeave={() => setExpanded(false)}
				isMobile={props.isMobile}
				expanded={expanded}
				variants={props.isMobile ? mobileVariants : desktopVariants}
				transition={{
					type: 'spring',
					stiffness: 100
				}}
			>
				<JetpackNavLink
					to={ROUTES.HOME}
					exactPath
					label={'Home'}
					icon={<FaHome />}
					closeMobileNav={props.closeMobileNav}
				/>
				<JetpackNavLink
					to={ROUTES.SPARKS}
					label={'Sparks'}
					icon={<FaListUl />}
					closeMobileNav={props.closeMobileNav}
				/>
				<JetpackNavLink
					to={ROUTES.GROUP_SCHEDULING}
					label={'Scheduling'}
					icon={<FaRegCalendarAlt />}
					closeMobileNav={props.closeMobileNav}
				/>
				<JetpackNavLink
					to={ROUTES.COLLABORATION}
					label={'Collaboration'}
					icon={<FaUserFriends />}
					closeMobileNav={props.closeMobileNav}
				/>
				<JetpackNavLink
					to={ROUTES.SETTINGS}
					label={'Settings'}
					icon={<FaCog />}
					closeMobileNav={props.closeMobileNav}
				/>
			</NavbarArea>
		</motion.div>
	);
}

function JetpackNavLink(props: JetpackNavLinkProps) {
	const iconProps = {
		size: 24,
		style: {
			width: 24,
			paddingRight: 16
		}
	};

	const icon = React.cloneElement(props.icon, iconProps)

	return (
		<NavLink
			to={props.to}
			exact={!!props.exactPath}
			style={(isActive: boolean) => ({
				width: '100%',
				color: isActive ? colors.jetpackMint : colors.gray4,
				textDecoration: 'none'
			})}
			onClick={() => {
				props.closeMobileNav && props.closeMobileNav()
			}}
		>
			<EntryContentWrapper>
				<EntryContent>
					{icon}
					<NavbarEntryLabel>{props.label}</NavbarEntryLabel>
				</EntryContent>
			</EntryContentWrapper>
		</NavLink>
	);
}

const NavbarArea = styled(motion.div)<NavbarAreaProps>`
	height: 100%;
	box-sizing: border-box;
	padding-top: 16px;
	background: ${componentColors.navbarBackground};
	display: flex;
	flex-direction: column;
	align-items: baseline;
	white-space: nowrap;
	overflow: hidden;
`;

const EntryContentWrapper = styled.div`
	width: 100%;
	cursor: pointer;
	:hover {
		background: ${colors.black};
	}
	display: flex;
	justify-content: flex-start;
`;

const EntryContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 8px 16px 8px 16px;
	cursor: pointer;
`;

const NavbarEntryLabel = styled.div`
	flex-grow: 1;
	font-size: 18px;
`;

// --------------------
// Types
// --------------------

export interface NavbarProps {
	isMobile: boolean;
	closeMobileNav?: () => void;
}

interface NavbarAreaProps {
	isMobile: boolean;
	expanded: boolean;
}

interface JetpackNavLinkProps {
	to: string;
	exactPath?: boolean;
	label: string;
	icon: React.ReactElement;
	closeMobileNav?: () => void;
}