import React, { Fragment, useRef, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';
import { ThemeProvider} from '@mui/material/styles';
import { Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, IconButton, InputBase, Link as MuiLink, NativeSelect, TextareaAutosize } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { MuiTextFieldProps } from '@mui/lab/internal/pickers/PureDateInput';
import { FiCalendar, FiCopy, FiEdit2, FiUsers, FiX } from 'react-icons/fi';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import { FaCalendarDay } from 'react-icons/fa';

import { GroupSchedulingOwnerProvider, useGroupSchedulingOwner, DAY_START_HOUR_DEFAULT, DAY_END_HOUR_DEFAULT } from '../../utilities/groupSchedulingOwnerContext';
import { useUser } from '../../utilities/userContext';
import { useFriends } from '../../utilities/friendContext';
import { GroupSchedulingPageContainer, GroupSchedulingSection, GroupSchedulingSectionHeading } from './GroupSchedulingComponents';
import FriendPicker from '../FriendPicker';
import { JetpackButton } from '../JetpackComponents';
import { Link } from '../JetpackText';
import { useJetpackSnackbar } from '../Snackbar';
import * as ROUTES from '../../constants/routes';
import { darkTheme } from '../../styles/materialUiTheme';
import { colors } from '../../styles/colors';
import { GroupSchedulingEventUsers, GroupSchedulingEventResponses, TimeIntervalEpochSeconds } from '../../types/jetpack/collaboration';

const DEFAULT_MEETING_DURATION_SECONDS = 1800;

export default function GroupSchedulingOwnerPageContextWrapper() {
	let { eventId } = useParams<GroupSchedulingRouterParams>();

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
			<GroupSchedulingOwnerProvider
				eventId={eventId}
			>
				<GroupSchedulingOwnerPageDataWrapper />
			</GroupSchedulingOwnerProvider>
		</LocalizationProvider>
	);
}

function GroupSchedulingOwnerPageDataWrapper() {
	const groupSchedulingOwnerContext = useGroupSchedulingOwner();
	let history = useHistory();

	const handleDeleteEvent = () => {
		groupSchedulingOwnerContext.deleteEvent();
		history.push(ROUTES.COLLABORATION)
	}

	return (
		<GroupSchedulingOwnerPageDisplayComponent
			eventId={groupSchedulingOwnerContext.eventId}
			title={groupSchedulingOwnerContext.title || ''}
			availableStart={groupSchedulingOwnerContext.availableStart}
			availableEnd={groupSchedulingOwnerContext.availableEnd}
			dayStartHour={groupSchedulingOwnerContext.dayStartHour}
			dayEndHour={groupSchedulingOwnerContext.dayEndHour}
			includeWeekends={groupSchedulingOwnerContext.includeWeekends}
			users={groupSchedulingOwnerContext.users}
			responses={groupSchedulingOwnerContext.responses}
			resultFreeTimes={groupSchedulingOwnerContext.resultFreeTimes}
			updateTitle={groupSchedulingOwnerContext.updateTitle}
			updateAvailableStart={groupSchedulingOwnerContext.updateAvailableStart}
			updateAvailableEnd={groupSchedulingOwnerContext.updateAvailableEnd}
			updateDayStartHour={groupSchedulingOwnerContext.updateDayStartHour}
			updateDayEndHour={groupSchedulingOwnerContext.updateDayEndHour}
			updateIncludeWeekends={groupSchedulingOwnerContext.updateIncludeWeekends}
			addUser={groupSchedulingOwnerContext.addUser}
			createCalendarEventLink={groupSchedulingOwnerContext.createCalendarEventLink}
			deleteEvent={handleDeleteEvent}
		/>
	);
}

export function GroupSchedulingOwnerPageDisplayComponent(props: GroupSchedulingOwnerPageProps) {
	const titleRef = useRef<HTMLInputElement>();
	const [titleIsFocused, setTitleIsFocused] = useState(false);
	const [startPickerOpen, setStartPickerOpen] = useState(false);
	const [endPickerOpen, setEndPickerOpen] = useState(false);
	const [inviteModalOpen, setInviteModalOpen] = useState(false);
	const [deleteEventConfirmationOpen, setDeleteEventConfirmationOpen] = useState(false);
	const [meetingDurationSeconds, setMeetingDurationSeconds] = useState(DEFAULT_MEETING_DURATION_SECONDS);

	const userContext = useUser();
	const friendContext = useFriends();
	const numberOfFriends = friendContext.friends ? Object.keys(friendContext.friends).length : 0;

	const handleDayStartHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newStartHour = Number(event.target.value);
		props.updateDayStartHour(newStartHour);
	}

	const handleDayEndHourChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newEndHour = Number(event.target.value);
		props.updateDayEndHour(newEndHour);
	}

	const handleIncludeWeekendsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.updateIncludeWeekends(event.target.checked);
	}

	return (
		<GroupSchedulingPageContainer>
			<ThemeProvider theme={darkTheme}>
				<TitleContainer>
					<TitleEditIcon
						size={30}
						onClick={() => {
							titleRef.current?.focus();
						}}
					/>
					<InputBase
						value={props.title}
						placeholder={titleIsFocused ? '' : 'Enter event title here'}
						onChange={(e) => {
							props.updateTitle(e.target.value)
						}}
						onFocus={(e) => {
							setTitleIsFocused(true);
							e.target.select();
						}}
						onBlur={() => setTitleIsFocused(false)}
						sx={{
							fontSize: 30,
							fontWeight: 'bold',
							width: '100%'
						}}
						multiline={true}
						inputComponent={TitleInputComponent}
						inputProps={{
							ref: titleRef
						}}
					/>
				</TitleContainer>
				<div
					style={{fontStyle: 'italic'}}
				>
					Your event
				</div>

				<div style={{height: '16px'}} />

				<TopButtons>
					<JetpackButton
						onClick={() => setInviteModalOpen(true)}
						endIcon={<FiUsers />}
					>
						Invite
					</JetpackButton>

					<div style={{height: '8px', width: '8px'}} />

					{props.eventId ?
						<LinkToParticipantPage
							eventId={props.eventId}
						/>
						: null
					}
				</TopButtons>
				
				<GroupSchedulingSection>
					<ResultsResponsiveGrid>
						<EventOptionsSection>
							<GroupSchedulingSectionHeading>Event Options</GroupSchedulingSectionHeading>
							<div>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									Duration:
									<div style={{width: '.5rem'}} />
									<NativeSelect
										value={meetingDurationSeconds}
										onChange={(e) => setMeetingDurationSeconds(Number(e.target.value))}
										inputProps={{
											name: 'meeting-duration-seconds-select',
											id: 'meeting-duration-seconds-select'
										}}
									>
										<option value={15*60}>15 minutes</option>
										<option value={30*60}>30 minutes</option>
										<option value={45*60}>45 minutes</option>
										<option value={60*60}>1 hour</option>
										<option value={90*60}>1 hour, 30 minutes</option>
										<option value={120*60}>2 hours</option>
										<option value={150*60}>2 hours, 30 minutes</option>
										<option value={180*60}>3 hours</option>
									</NativeSelect>
								</div>
								<DatePicker
									value={props.availableStart ? jsDateFromEpochSeconds(props.availableStart) : null}
									onChange={(newValue) => {
										if (newValue) {
											props.updateAvailableStart(epochSecondsFromJSDate(new Date(newValue.setHours(props.dayStartHour || DAY_START_HOUR_DEFAULT, 0))));
										}
									}}
									open={startPickerOpen}
									onClose={() => setStartPickerOpen(false)}
									renderInput={(renderInputProps) => RenderInputForDatePicker(renderInputProps, 'Start date', props.availableStart, () => setStartPickerOpen(true))}
									components={{OpenPickerIcon: IconForDatePicker}}
									clearable={false}
								/>
								<DatePicker
									value={props.availableEnd ? jsDateFromEpochSeconds(props.availableEnd) : null}
									onChange={(newValue) => {
										if (newValue) {
											props.updateAvailableEnd(epochSecondsFromJSDate(new Date(newValue.setHours(props.dayEndHour || DAY_END_HOUR_DEFAULT, 0))));
										}
									}}
									open={endPickerOpen}
									onClose={() => setEndPickerOpen(false)}
									renderInput={(renderInputProps) => RenderInputForDatePicker(renderInputProps, 'End date', props.availableEnd, () => setEndPickerOpen(true))}
									components={{OpenPickerIcon: IconForDatePicker}}
									clearable={false}
								/>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									No earlier than:
									<div style={{width: '.5rem'}} />
									<NativeSelect
										value={props.dayStartHour}
										onChange={handleDayStartHourChange}
										inputProps={{
											name: 'day-start-hour-select',
											id: 'day-start-hour-select'
										}}
									>
										<option value={0}>12:00 am</option>
										<option value={1}>1:00 am</option>
										<option value={2}>2:00 am</option>
										<option value={3}>3:00 am</option>
										<option value={4}>4:00 am</option>
										<option value={5}>5:00 am</option>
										<option value={6}>6:00 am</option>
										<option value={7}>7:00 am</option>
										<option value={8}>8:00 am</option>
										<option value={9}>9:00 am</option>
										<option value={10}>10:00 am</option>
										<option value={11}>11:00 am</option>
										<option value={12}>12:00 pm</option>
										<option value={13}>1:00 pm</option>
										<option value={14}>2:00 pm</option>
										<option value={15}>3:00 pm</option>
										<option value={16}>4:00 pm</option>
										<option value={17}>5:00 pm</option>
										<option value={18}>6:00 pm</option>
										<option value={19}>7:00 pm</option>
										<option value={20}>8:00 pm</option>
										<option value={21}>9:00 pm</option>
										<option value={22}>10:00 pm</option>
										<option value={23}>11:00 pm</option>
									</NativeSelect>
								</div>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									No later than:
									<div style={{width: '.5rem'}} />
									<NativeSelect
										value={props.dayEndHour}
										onChange={handleDayEndHourChange}
										inputProps={{
											name: 'day-end-hour-select',
											id: 'day-end-hour-select'
										}}
									>
										<option value={1}>1:00 am</option>
										<option value={2}>2:00 am</option>
										<option value={3}>3:00 am</option>
										<option value={4}>4:00 am</option>
										<option value={5}>5:00 am</option>
										<option value={6}>6:00 am</option>
										<option value={7}>7:00 am</option>
										<option value={8}>8:00 am</option>
										<option value={9}>9:00 am</option>
										<option value={10}>10:00 am</option>
										<option value={11}>11:00 am</option>
										<option value={12}>12:00 pm</option>
										<option value={13}>1:00 pm</option>
										<option value={14}>2:00 pm</option>
										<option value={15}>3:00 pm</option>
										<option value={16}>4:00 pm</option>
										<option value={17}>5:00 pm</option>
										<option value={18}>6:00 pm</option>
										<option value={19}>7:00 pm</option>
										<option value={20}>8:00 pm</option>
										<option value={21}>9:00 pm</option>
										<option value={22}>10:00 pm</option>
										<option value={23}>11:00 pm</option>
										<option value={24}>12:00 am</option>
									</NativeSelect>
								</div>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									Include weekends
									<Checkbox
										value={props.includeWeekends}
										onChange={handleIncludeWeekendsChange}
									/>
								</div>
							</div>
							<JetpackButton
								variant='outlined'
								color='error'
								sx={{
									marginTop: '16px'
								}}
								onClick={() => setDeleteEventConfirmationOpen(true)}
							>
								Delete event
							</JetpackButton>
							<Dialog open={deleteEventConfirmationOpen}>
								<DialogContent>
									Delete this event? Responses will be lost. This cannot be undone.
								</DialogContent>
								<DialogActions>
									<JetpackButton
										variant='text'
										onClick={() => setDeleteEventConfirmationOpen(false)}
									>
										Cancel
									</JetpackButton>
									<JetpackButton
										variant='outlined'
										color='error'
										onClick={(e) => {
											setDeleteEventConfirmationOpen(false);
											props.deleteEvent();
										}}
									>
										Delete event
									</JetpackButton>
								</DialogActions>
							</Dialog>
							{/* <GroupSchedulingSectionHeading>Results</GroupSchedulingSectionHeading>
							{props.responses && Object.keys(props.responses).length && props.users && Object.keys(props.users).length ?
								<Fragment>
									<ResponsesReceived responses={props.responses} users={props.users} currentUserId={userContext.userId} />
									<div>{numberOfFreeTimesFoundText(props.resultFreeTimes)}</div>
									{props.resultFreeTimes?.length ?
										<ul>
										{props.resultFreeTimes.map((interval, index) => {
											return (
												<li key={index}>{DateTime.fromSeconds(interval.start).toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' })}: {DateTime.fromSeconds(interval.start).toLocaleString(DateTime.TIME_SIMPLE).toLocaleLowerCase()} - {DateTime.fromSeconds(interval.end).toLocaleString(DateTime.TIME_SIMPLE).toLocaleLowerCase()}</li>
											);
										})}
										</ul>
									: null}
								</Fragment>
								: <div style={{paddingBottom: '16px'}}>No results received yet</div>
							} */}
						</EventOptionsSection>
						<ResultsSection>
							<GroupSchedulingSectionHeading>Results</GroupSchedulingSectionHeading>
							{props.responses && Object.keys(props.responses).length && props.users && Object.keys(props.users).length ?
								<ResponsesReceived responses={props.responses} users={props.users} currentUserId={userContext.userId} />
								: <div style={{paddingBottom: '16px'}}>No responses received yet</div>
							}
							<div>
								<AddToCalendarButtons
									durationSeconds={meetingDurationSeconds}
									resultFreeTimes={props.resultFreeTimes}
									meetingTitle={props.title}
									participantUserIds={props.users ? Object.keys(props.users) : []}
									timezoneString={userContext.user?.settings?.timezone || ''}
									createCalendarEventLink={props.createCalendarEventLink}
								/>
							</div>
						</ResultsSection>
					</ResultsResponsiveGrid>
				</GroupSchedulingSection>

				<Dialog
					open={inviteModalOpen}
					onClose={() => setInviteModalOpen(false)}
				>
					<div style={{padding: '0 24px 24px 24px'}}>
						<div style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between'
						}}>
							<GroupSchedulingSectionHeading>Invite</GroupSchedulingSectionHeading>
							<IconButton
								onClick={() => setInviteModalOpen(false)}
							>
								<FiX size={24} />
							</IconButton>
						</div>
						{numberOfFriends === 0 ?
							<div>
								<Link url={ROUTES.COLLABORATION}>Add friends to get their availability instantly.</Link>
								<p>If they have their calendar connected to Jetpack, you won't need to wait for them to respond.</p>
							</div>
							:
							<div>
								<p>Add <Link url={ROUTES.COLLABORATION}>friends</Link> to this event. If they have their calendar connected to Jetpack, you won't need to wait for them to respond.</p>
								<FriendPicker
									label='Add friend'
									onSelectFriend={props.addUser}
									sx={{
										alignSelf: 'center'
									}}
								/>
							</div>
						}
						<div style={{height: 16}} />
						{props.users && Object.keys(props.users).length ?
							<Fragment>
								People added to this event:
								<ul>
									{
										Object.entries(props.users).map(([friendUserId, user]) => {
											if (!(friendUserId === userContext.userId) && user.displayName) {
												return (
													<li key={friendUserId}>{user.displayName}</li>
												);
											} else if (friendUserId === userContext.userId) {
												return (
													<li key={friendUserId}>You</li>
												);
											} else return null;
										})
									}
								</ul>
							</Fragment>
							: null
						}
						<div>Send this link to your friends for them to enter their availability.</div>
						<div>If they don't have a Jetpack account, they can sign up for free.</div>
						<div style={{marginTop: '16px'}}>
							<InviteLink
								eventId={props.eventId!}
							/>
						</div>
						<div style={{marginTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
							<JetpackButton
								variant='text'
								onClick={() => setInviteModalOpen(false)}
							>
								Done
							</JetpackButton>
						</div>
					</div>
				</Dialog>

			</ThemeProvider>
		</GroupSchedulingPageContainer>
	);
}

const TopButtons = styled.div`
	box-sizing: border-box;
	width: 100%;
	
	@media (min-width: 768px) {
		display: flex;
		flex-direction: 'row'
	}
`;

const ResultsResponsiveGrid = styled.div`
	box-sizing: border-box;
	width: 100%;
	
	@media (min-width: 768px) {
		display: grid;
		/* grid-template-columns: repeat(auto-fill, minmax(400px, 50%)); */
		grid-template-columns: repeat(2, 1fr);
	}
`;

const EventOptionsSection = styled.div`

`;

const ResultsSection = styled.div`

`;

const ResponsesReceived = (props: {responses: GroupSchedulingEventResponses, users: GroupSchedulingEventUsers, currentUserId?: string}) => {
	const responseUserIdArray = Object.entries(props.responses)
		.map(([userId, response]) => {
			// Filter out response objects that don't actually have a calendar response or manual response.
			if (response.gotCalendarResponse || (response.manualBusyIntervals && response.manualBusyIntervals.length > 0) || (response.manualFreeIntervals && response.manualFreeIntervals.length > 0)) {
				return userId;
			} else return '';
		})
		.reduce((accumulatedResults: Array<string>, userId) => {
			if (userId) {
				accumulatedResults.push(userId)
			}
			return accumulatedResults;
		}, []);
	
	const responseUsers = responseUserIdArray.map(responseUserId => {
		if (responseUserId === props.currentUserId) {
			return {
				displayName: 'You',
				userId: responseUserId
			};
		}
		return {
			displayName: props.users[responseUserId].displayName || 'Unnamed user',
			userId: responseUserId
		};
	})
	.sort((a, b) => {
		if (a.displayName.toLowerCase() <= b.displayName.toLowerCase()) {
			return -1;
		} else {
			return 1;
		}
	});

	return (
		<Fragment>
			<div>{numberOfResponsesReceivedText(props.responses)}</div>
			<ul>
				{responseUsers.length ?
					responseUsers.map(responseUser => <li key={responseUser.userId}>{`${responseUser.displayName}`}</li>)
					: null
				}
			</ul>
		</Fragment>
	);
}

const LinkToParticipantPage = (props: {eventId: string}) => {
	const url = ROUTES.GROUP_SCHEDULING + `/${props.eventId}`;

	return (
		<RouterLink
			to={url}
			style={{
				textDecoration: 'none'
			}}
		>
			<JetpackButton
				variant='text'
				endIcon={<FiCalendar />}
			>
				Edit your own availability
			</JetpackButton>
		</RouterLink>
	);
}

function AddToCalendarButtons(props: {durationSeconds: number, resultFreeTimes: Array<TimeIntervalEpochSeconds>, meetingTitle: string, participantUserIds: Array<string>, timezoneString: string, createCalendarEventLink: (provider: 'google' | 'outlook', title: string, startTime: number, endTime: number, timezone: string, guestUserIds: Array<string>) => Promise<string>}) {
	const meetingOptions: Array<TimeIntervalEpochSeconds> = [];

	props.resultFreeTimes.forEach(interval => {
		const intervalStart = interval.start;
		const intervalEnd = interval.end;
		const remainderMinutes = DateTime.fromSeconds(intervalStart).minute % 30;
		let stepperStart = intervalStart + remainderMinutes*60;
		let stepperEnd = stepperStart + props.durationSeconds;

		while (stepperEnd <= intervalEnd) {
			meetingOptions.push({start: stepperStart, end: stepperEnd});
			stepperStart = stepperEnd;
			stepperEnd = stepperStart + props.durationSeconds;
		}
	})

	if (meetingOptions.length < 1) {
		return null;
	}

	return (
		<div style={{display: 'flex', flexDirection: 'column'}}>
			<div style={{marginBottom: '8px'}}>
				Free times:
			</div>
			{/* <div style={{height: '200px', overflowY: 'scroll'}}> */}
			{meetingOptions.map(meeting => {
				return (
					<AddToCalendarButton
						key={meeting.start}
						start={meeting.start}
						end={meeting.end}
						meetingTitle={props.meetingTitle}
						participantUserIds={props.participantUserIds}
						timezoneString={props.timezoneString}
						createCalendarEventLink={props.createCalendarEventLink}
					/>
				);
			})}
		</div>
	);
}

function AddToCalendarButton(props: {start: number, end: number, meetingTitle: string, participantUserIds: Array<string>, timezoneString: string, createCalendarEventLink: (provider: 'google' | 'outlook', title: string, startTime: number, endTime: number, timezone: string, guestUserIds: Array<string>) => Promise<string>}) {
	const [thinking, setThinking] = useState(false);
	return (
		<JetpackButton
			sx={{margin: '4px 0px'}}
			key={props.start}
			onClick={() => {
				props.createCalendarEventLink('google', props.meetingTitle, props.start, props.end, props.timezoneString, props.participantUserIds)
					.then(result => {
						setThinking(false);
						window.open(result, '_blank', 'noopener,noreferrer');
					})
			}}
		>
			{thinking ?
				<CircularProgress size='24px' />
				: `${DateTime.fromSeconds(props.start).toLocaleString({ weekday: 'short', month: 'short', day: 'numeric' })}: ${DateTime.fromSeconds(props.start).toLocaleString(DateTime.TIME_SIMPLE).toLocaleLowerCase()} - ${DateTime.fromSeconds(props.end).toLocaleString(DateTime.TIME_SIMPLE).toLocaleLowerCase()}`
			}
		</JetpackButton>
	);
}

const InviteLink = (props: {eventId: string}) => {
	const { enqueueSnackbar } = useJetpackSnackbar();
	const url = window.location.origin + `/acceptInvitation?groupSchedulingInvite=${props.eventId}`;
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		navigator.clipboard.writeText(url);
		enqueueSnackbar('Invite link copied to clipboard', {variant: 'success'});
	}
	
	return (
		<JetpackButton
			variant='contained'
			endIcon={<FiCopy />}
			sx={{
				textTransform: 'none'
			}}
			onClick={handleClick}
		>
			Copy invite link
		</JetpackButton>
	);
}

function numberOfResponsesReceivedText(responses: GroupSchedulingEventResponses | undefined) {
	const NO_RESPONSES_TEXT = 'No responses received';
	const ONE_RESPONSE_TEXT = '1 response received:';

	if (!responses) {
		return NO_RESPONSES_TEXT;
	}

	let numberOfResponses = 0;
	Object.values(responses).forEach(response => {
		// Only count response objects that actually have a calendar response or manual response.

		if (response.gotCalendarResponse || (response.manualBusyIntervals && response.manualBusyIntervals.length) || (response.manualFreeIntervals && response.manualFreeIntervals.length)) {
			numberOfResponses += 1;
		}
	});

	if (numberOfResponses === 0) {
		return NO_RESPONSES_TEXT;
	} else if (numberOfResponses === 1) {
		return ONE_RESPONSE_TEXT;
	} else {
		return `${numberOfResponses} responses received:`;
	}
}

// function numberOfFreeTimesFoundText(freeTimes: Array<TimeIntervalEpochSeconds>) {
// 	const NO_FREE_TIMES_TEXT = 'No free times found';
// 	const ONE_FREE_TIME_TEXT = '1 free time found:';

// 	switch (freeTimes.length) {
// 		case 0:
// 			return NO_FREE_TIMES_TEXT;
// 		case 1:
// 			return ONE_FREE_TIME_TEXT;
// 		default:
// 			return `${freeTimes.length} free times found:`;
// 	}
// }

const RenderInputForDatePicker = ({ inputRef, inputProps, InputProps }: MuiTextFieldProps, dateLabel: string, dateEpochSeconds: number | undefined, openPicker: () => void) => {
	return (
		<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
			{`${dateLabel}:`}<div style={{width: '.5rem'}} />
			<MuiLink
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					cursor: 'pointer'
				}}
				underline='hover'
				ref={inputRef}
				onClick={() => openPicker()}
			>
				{`${dateEpochSeconds ? DateTime.fromSeconds(dateEpochSeconds).toLocaleString(DateTime.DATE_FULL) : 'No date selected.'}`}
				<IconButton
					sx={{
						color: colors.blueLight
					}}
				>
					<FaCalendarDay
						size={16}
					/>
				</IconButton>
			</MuiLink>
		</div>
	);
}

function IconForDatePicker() {
	return (
		<FaCalendarDay
			size={16}
			color={colors.blueLight}
		/>
	);
}

function epochSecondsFromJSDate(jsDate: Date) {
	// Divide by 1000 because Date is stored in epoch milliseconds.
	return Math.floor(jsDate.getTime() / 1000);
}

function jsDateFromEpochSeconds(epochSeconds: number) {
	// Multiply by 1000 because Date constructor accepts epoch milliseconds.
	return new Date(epochSeconds * 1000);
}

const TitleContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const TitleInputComponent = styled(TextareaAutosize)`
	width: 100%;
	:focus::placeholder {
		color: 'transparent'
	}
`;

const TitleEditIcon = styled(FiEdit2)`
	margin-right: 16px;
	color: ${colors.gray3};
	cursor: pointer;
	:hover {
		color: ${colors.blueLight}
	}
`;

// --------------------
// Types
// --------------------

type GroupSchedulingRouterParams = {
	eventId: string;
}

type GroupSchedulingOwnerPageProps = {
	eventId?: string;
	title: string;
	availableStart: number | undefined;
	availableEnd: number | undefined;
	dayStartHour: number | undefined;
	dayEndHour: number | undefined;
	includeWeekends: boolean | undefined;
	users: GroupSchedulingEventUsers | undefined;
	responses: GroupSchedulingEventResponses | undefined;
	resultFreeTimes: Array<TimeIntervalEpochSeconds>;
	updateTitle: (newTitle: string) => void;
	updateAvailableStart: (newValue: number) => void;
	updateAvailableEnd: (newValue: number) => void;
	updateDayStartHour: (newValue: number) => void;
	updateDayEndHour: (newValue: number) => void;
	updateIncludeWeekends: (newValue: boolean) => void;
	addUser: (newUserId: string, newUserDisplayName: string) => void;
	createCalendarEventLink: (provider: 'google' | 'outlook', title: string, startTime: number, endTime: number, timezone: string, guestUserIds: Array<string>) => Promise<string>;
	deleteEvent: () => void;
}