import React from 'react';
import styled from '@emotion/styled';

import JetpackCard from '../JetpackCard';
import { Heading1Gradient } from '../JetpackText';
import { colors } from '../../styles/colors';

export const GroupSchedulingPageContainer = styled.div`
	width: 100%;
	min-height: 100%;
	padding: 16px 32px 32px 32px;
	box-sizing: border-box;
	color: ${colors.textPrimary};
	background-color: ${colors.backgroundPrimary};
`;

export const GroupSchedulingSection = styled.div`
	width: 100%;
	box-sizing: border-box;
	text-align: left;
`;

export function GroupSchedulingSectionDivider() {
	return (
		<GroupSchedulingSectionDividerContainer>
			<GroupSchedulingSectionDividerLine />
		</GroupSchedulingSectionDividerContainer>
	);
}

const GroupSchedulingSectionDividerContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const GroupSchedulingSectionDividerLine = styled.div`
	height: 1px;
	width: 90%;
	margin-top: 32px;
	box-sizing: content-box;
	background-color: ${colors.gray2};
	align-self: center;
`;

export const GroupSchedulingCard = styled(JetpackCard)`
	width: 80%;
	max-width: 576px;
	margin-bottom: 24px;
	padding: 0px 16px 16px 16px;
	text-align: center;
`;

export function GroupSchedulingSectionHeading(props: {children: React.ReactNode}) {
	return (
		<Heading1Gradient>
			{props.children}
		</Heading1Gradient>
	);
}