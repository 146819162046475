import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import styled from '@emotion/styled';
import { useUser } from '../utilities/userContext';
import { JetpackButton } from './JetpackComponents';
import * as ROUTES from '../constants/routes';
import { colors } from '../styles/colors';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function ConfirmEmail() {
	const [state, setState] = useState<'pending' | 'success' | 'error'>('pending');
	const history = useHistory();
	const user = useUser();
	const query = useQuery();
	const code = query.get('code');

	useEffect(() => {
		// Only do this once (if we're in the "pending" state)
		if (state === 'pending') {
			if (!code) {
				// TODO: Handle the case where there's no code in the query parameters.
			} else {
				user.confirmEmailAddress(code)
					.then((result) => {
						// console.log(result);
						// TODO: I should probably handle errors with .catch()
						if (result.result === 'success') {
							// Success
							setState('success');
						} else {
							// Failure
							setState('error');
							console.error(result);
						}
					});
			}
		}
	}, [state, code, user]);

	let displayComponent: React.ReactElement = <CircularProgress />;
	switch (state) {
		case 'pending':
			displayComponent = <CircularProgress />;
			break;
		case 'success':
			displayComponent =
			<ConfirmEmailContent>
				<p>Successfully confirmed your email address.</p>
				<CallToAction variant='contained' color='primary' onClick={() => history.push(ROUTES.HOME)}>Home</CallToAction>
			</ConfirmEmailContent>;
			break;
		case 'error':
			displayComponent =
				<ConfirmEmailContent>
					<p>Error confirming email address.</p>
					<p>Try resending the verification email from the settings page.</p>
					<CallToAction variant='contained' color='primary' onClick={() => history.push(ROUTES.SETTINGS)}>Settings</CallToAction>
				</ConfirmEmailContent>;
			break;
	}
	
	return (
		<ConfirmEmailContainer>
			{displayComponent}
		</ConfirmEmailContainer>
	);
}

const ConfirmEmailContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: linear-gradient(45deg, ${colors.jetpackMintLight}, ${colors.jetpackAquaLight});
`;

const ConfirmEmailContent = styled.div`
	margin: 24px;
	align-items: center;
	text-align: center;
`;

const CallToAction = styled(JetpackButton)``;