import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { FiCircle, FiCheckCircle, FiFileText } from 'react-icons/fi';
import JetpackCard from './JetpackCard';
import { colors } from '../styles/colors';
import { Spark } from '../types/jetpack/spark';

export default function SparkCard(props: SparkCardProps) {
	let [isClicking, setIsClicking] = useState(false);

	return (
		<SparkCardContainer
			elevation={isClicking ? 1 : 3}
			onMouseDown={() => setIsClicking(true)}
			onTouchStart={() => setIsClicking(true)}
			onMouseUp={() => setIsClicking(false)}
			onMouseLeave={() => {if (isClicking) setIsClicking(false)}}
			onTouchEnd={() => setIsClicking(false)}
			onTouchCancel={() => setIsClicking(false)}
			onClick={() => {
				props.onCardClick();
			}}
			isDepressed={isClicking}
		>
			<SparkIcon
				isTask={props.spark.isTask}
				isDone={props.spark.isDone}
				onClick={() => {
					props.onIconClick();
				}}
			/>
			<div style={{width: 8}} />
			<TitleAndDueDate>
			<TitleText isDone={props.spark.isDone}>{props.spark.title}</TitleText>
				<DueDateText>{props.spark.dueDate ? `Due ${DateTime.fromJSDate(props.spark.dueDate).toRelativeCalendar({ unit: 'days' })}` : null}</DueDateText>
			</TitleAndDueDate>
		</SparkCardContainer>
	)
}

function SparkIcon(props: SparkIconProps) {
	return (
		<div
			onClick={e => {
				e.stopPropagation();
				props.onClick();
			}}
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
		>
			{props.isTask ?
				props.isDone ?
					<FiCheckCircle />
						: <FiCircle />
					: <FiFileText />}
		</div>
	);
}

const SparkCardContainer = styled(JetpackCard)`
	padding: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
`;

const TitleAndDueDate = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const TitleText = styled.div<TitleTextProps>`
	color: ${props => props.isDone ? colors.gray3 : colors.textDark};
	text-decoration-line: ${props => props.isDone ? `line-through` : `none`};
`;

const DueDateText = styled.div`
	color: ${colors.gray3};
	font-style: italic;
`;

// --------------------
// Types
// --------------------

export interface SparkCardProps {
	spark: Spark;
	onCardClick: () => void;
	onIconClick: () => void;
}

interface SparkIconProps {
	isTask?: boolean;
	isDone?: boolean;
	onClick: () => void;
}

interface TitleTextProps {
	isDone?: boolean;
}