import React from 'react';
import { Button, ButtonProps } from '@mui/material';

import { colors } from '../styles/colors';

export const JetpackButton = (props: ButtonProps) => {
	const combinedProps: ButtonProps = {
		...props,
		...{variant: props.variant || 'contained'},
		...{sx: {
			...{
				width: 'fit-content',
				textTransform: 'none',
				borderRadius: '8px',
				background: props.variant === 'contained' || !props.variant ? `linear-gradient(45deg, ${colors.jetpackMint}, ${colors.jetpackAqua})` : '',
				color: props.variant === 'contained' || !props.variant ? colors.gray1 : '',
				fontWeight: 'bold',
				boxShadow: 'none'
			},
			...props.sx,
		}}
	}

	return (
		<Button
			{...combinedProps}
			// disableElevation
		>
			{props.children}
		</Button>
	);
}