import React from 'react';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';
import { Spark } from '../types/jetpack/spark';
import SparkListSection from './SparkListSection';

export default function SparkList(props: SparkListProps) {
	const handleToggleIsDone = (spark: Spark) => {
		const updatedSpark: Spark = {...spark};
		updatedSpark['isDone'] = spark?.isDone ? false : true;
		props.onUpdateSpark(updatedSpark);
	}

	let todaySparks: Array<Spark> = [];
	let thisWeekSparks: Array<Spark> = [];
	let pastDueSparks: Array<Spark> = [];
	let otherSparksWithDate: Array<Spark> = [];
	let otherSparksNoDateTask: Array<Spark> = [];
	let otherSparksNoDate: Array<Spark> = [];

	props.sparks.forEach((spark: Spark) => {
		if (!spark.dueDate) {
			// Spark doesn't have a due date
			if (spark.isTask) {
				otherSparksNoDateTask.push(spark);
			} else {
				otherSparksNoDate.push(spark);
			}
		} else {
			// Spark has a due date
			const sparkDueDate = DateTime.fromJSDate(spark.dueDate);
			const today = DateTime.local();
			if (sparkDueDate.startOf('day') < today.startOf('day')) {
				// Spark is past due
				pastDueSparks.push(spark);
			}	else if (sparkDueDate.hasSame(today, 'day')) {
				// Spark is due today
				todaySparks.push(spark);
			} else if (sparkDueDate <= today.plus({weeks: 1})) {
				// Spark is due within a week
				thisWeekSparks.push(spark);
			} else {
				// Spark has a due date
				otherSparksWithDate.push(spark);
			}
		}
	});

	// Today
	const todaySection = <SparkListSection
		title={'Today'}
		sparks={sortSparks(todaySparks, 'dueDate', 'asc')}
		collapsedDefault={false}
		onSelectSpark={props.onSelectSpark}
		onToggleIsDone={handleToggleIsDone}
	/>;

	// This week
	const thisWeekSection = <SparkListSection
		title={'This Week'}
		sparks={sortSparks(thisWeekSparks, 'dueDate', 'asc')}
		collapsedDefault={false}
		onSelectSpark={props.onSelectSpark}
		onToggleIsDone={handleToggleIsDone}
	/>;

	// Other sparks
	const otherSection = <SparkListSection
		title={'Other Sparks'}
		sparks={sortSparks(otherSparksWithDate, 'dueDate', 'asc').concat(sortSparks(otherSparksNoDateTask, 'modified', 'desc')).concat(sortSparks(otherSparksNoDate, 'modified', 'desc'))}
		collapsedDefault={false}
		onSelectSpark={props.onSelectSpark}
		onToggleIsDone={handleToggleIsDone}
	/>;

	// Past due sparks
	const pastDueSection = <SparkListSection
		title={'Past Due'}
		sparks={sortSparks(pastDueSparks, 'dueDate', 'asc')}
		collapsedDefault={true}
		onSelectSpark={props.onSelectSpark}
		onToggleIsDone={handleToggleIsDone}
	/>;

	return (
		<SparkListContainer>
			{pastDueSection}
			{todaySection}
			{thisWeekSection}
			{otherSection}
		</SparkListContainer>
	)
}

function sortSparks(sparks: Array<Spark>, fieldName: keyof Spark, direction: 'asc' | 'desc') {
	return sparks.sort((a: Spark, b: Spark) => {
		if (a[fieldName] && b[fieldName]) {
			if (direction === 'asc') return (b[fieldName]! < a[fieldName]!) ? 1: -1;
			else return (b[fieldName]! > a[fieldName]!) ? 1: -1;
		} else {
			if (direction === 'asc') return b[fieldName] ? (b[fieldName] ? 1: -1) : (a[fieldName] ? -1 : 1);
			else return a[fieldName] ? (a[fieldName] ? 1: -1) : (b[fieldName] ? -1 : 1);
		}
	})
}

const SparkListContainer = styled.div`
	
`;

// --------------------
// Types
// --------------------

export interface SparkListProps {
	sparks: Array<Spark>;
	onSelectSpark: (sparkId: string) => void;
	onUpdateSpark: (spark: Spark) => void;
}