import React, { createContext, useContext, useEffect, useState } from 'react';
import * as crypto from 'crypto';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import { useFirebase, functionNames } from './firebaseContext';
import { events, useAnalytics } from './analyticsContext';
import { AccountTypes, User, CalendarAccountsObject, CalendarAccount, PrimaryCalendar } from '../types/jetpack/user';

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({children}: ProviderProps) {
	const firebaseContext = useFirebase();
	const Firebase = firebaseContext.Firebase!;
	const Auth = firebaseContext.Auth!;
	const Firestore = firebaseContext.Firestore!;
	const Functions = firebaseContext.Functions!;

	const analyticsContext = useAnalytics();

	const [state, setState] = useState<StateType>({
		loading: true,
		user: null
	});

	useEffect(() => {
		subscribeToUserUpdates(populateUserFromFirebase, Firebase, Auth, Firestore);
		return(unsubscribeFromUserUpdates);
	}, [Firebase, Auth, Firestore]);

	const populateUserFromFirebase = (user: User) => {
		setState({loading: false, user: user});
	}

	const updateDisplayName = async (newName: string) => {
		const updateDisplayNameFirebaseFunction = Functions.httpsCallable(functionNames.updateDisplayName);

		return updateDisplayNameFirebaseFunction({newName: newName})
			.then(response => {
				if ('error' in response.data) {
					throw new Error(response.data.error);
				} else {
					return {
						success: true
					}
				}
			})
			.then(result => {
				analyticsContext.trackEvent(events.UpdatedDisplayName, {});
				return result;
			})
			.catch(error => {
				console.error('(updateDisplayName) Error updating display name.');
				console.error(error);
				return {
					error: JSON.stringify(error)
				};
			})
	}

	const addRegisteredEmailAddress = async (emailAddress: string) => {
		return Firestore.collection('users')
			.doc(Auth.currentUser!.uid)
			.update({
				pendingEmailAddresses: firebase.firestore.FieldValue.arrayUnion(emailAddress)
			})
			.then(() => {
				sendVerificationEmail(emailAddress);
				return(`Verification email sent to ${emailAddress}.`);
			})
			.then(result => {
				const hashedEmailAddress = crypto.createHash('sha256').update(emailAddress).digest('hex');
				analyticsContext.trackEvent(events.AddedRegisteredEmailAddress, {email_address_hash: hashedEmailAddress});
				return result;
			})
			.catch((error) => {
				console.error('Error adding a registered email.');
				console.error(error);
				return error;
			});
	}

	const addRegisteredPhoneNumber = async (phoneNumber: string) => {
		return Firestore.collection('users')
			.doc(Auth.currentUser!.uid)
			.update({
				pendingPhoneNumber: phoneNumber
			})
			.then(() => {
				sendVerificationText(phoneNumber);
				return(`Verification text sent to ${phoneNumber}.`);
			})
			.then(result => {
				const hashedPhoneNumber = crypto.createHash('sha256').update(phoneNumber).digest('hex');
				analyticsContext.trackEvent(events.AddedRegisteredPhoneNumber, {phone_number_hash: hashedPhoneNumber});
				return result;
			})
			.catch((error) => {
				console.error('Error adding a registered phone.');
				console.error(error);
				return error;
			});
	}

	const removeRegisteredAccount = async (account: string, accountType: AccountTypes) => {
		const removeRegisteredAccount = Functions.httpsCallable(functionNames.removeRegisteredAccount);

		if (!Auth.currentUser || !Auth.currentUser.uid) {
			// TODO: User not signed in. Can't confirm address.
			// This shouldn't happen. If it does, it's a code issue.
			console.error(`Couldn't get user ID when trying to call confirmEmailAddress.`);
		}

		return removeRegisteredAccount({
			account: account,
			accountType: accountType
		})
		.then((result) => {
			switch (accountType) {
				case 'calendar':
					analyticsContext.trackEvent(events.RemovedCalendarAccount, {calendar_account_id: account});
					break;
				case 'email':
					const hashedEmailAddress = crypto.createHash('sha256').update(account).digest('hex');
					analyticsContext.trackEvent(events.RemovedRegisteredEmailAddress, {email_address_hash: hashedEmailAddress});
					break;
				case 'phone':
					const hashedPhoneNumber = crypto.createHash('sha256').update(account).digest('hex');
					analyticsContext.trackEvent(events.RemovedRegisteredPhoneNumber, {phone_number_hash: hashedPhoneNumber});
					break;
			}
			return {result: 'success'};
		})
		.catch((error: firebase.functions.HttpsError) => {
			var code = error.code;
			var message = error.message;
			var details = error.details;
			console.error(code);
			console.error(message);
			console.error(details);
			// TODO: Handle different types of errors: 'invalid-argument', 'not-found', and 'internal'
			return {result: 'error', code: error.code, message: error.message, details: error.details};
		});
	}

	const sendVerificationEmail = (emailAddress: string) => {
		const sendVerificationEmail = Functions.httpsCallable(functionNames.sendVerificationEmail);

		sendVerificationEmail({
			emailAddress: emailAddress
		})
		.then((result) => {
			// TODO: Confirm that the verification email was sent.
		})
		.catch((error) => {
			var code = error.code;
			var message = error.message;
			var details = error.details;
			console.error(code);
			console.error(message);
			console.error(details);
		});
	}

	const sendVerificationText = (phoneNumber: string) => {
		const sendVerificationText = Functions.httpsCallable(functionNames.sendVerificationText);

		sendVerificationText({
			phoneNumber: phoneNumber
		})
		.then((result) => {
			// TODO: Confirm that the verification text was sent.
		})
		.catch((error) => {
			var code = error.code;
			var message = error.message;
			var details = error.details;
			console.error(code);
			console.error(message);
			console.error(details);
		});
	}

	const confirmEmailAddress = async (code: string) => {
		const confirmEmailAddress = Functions.httpsCallable(functionNames.confirmEmailAddress);

		if (!Auth.currentUser || !Auth.currentUser.uid) {
			// TODO: User not signed in. Can't confirm address.
			// This shouldn't happen. If it does, it's a code issue.
			console.error(`Couldn't get user ID when trying to call confirmEmailAddress.`);
		}

		return confirmEmailAddress({
			userId: Auth.currentUser!.uid,
			code: code
		})
		.then((result) => {
			// TODO: Give the user confirmation that the verification email was sent.
			return {result: 'success'};
		})
		.catch((error: firebase.functions.HttpsError) => {
			var code = error.code;
			var message = error.message;
			var details = error.details;
			console.error(code);
			console.error(message);
			console.error(details);
			// TODO: Handle different types of errors: 'invalid-argument', 'not-found', and 'internal'
			return {result: 'error', code: error.code, message: error.message, details: error.details};
		});
	}

	const confirmPhoneNumber = async (code: string) => {
		const confirmPhoneNumber = Functions.httpsCallable(functionNames.confirmPhoneNumber);

		if (!Auth.currentUser || !Auth.currentUser.uid) {
			// TODO: User not signed in. Can't confirm phone number.
			// This shouldn't happen. If it does, it's a code issue.
			console.error(`Couldn't get user ID when trying to call confirmPhoneNumber.`);
		}

		return confirmPhoneNumber({
			code: code
		})
		.then((result) => {
			// TODO: Give the user confirmation that the verification code was sent.
			return {result: 'success'};
		})
		.catch((error: firebase.functions.HttpsError) => {
			var code = error.code;
			var message = error.message;
			var details = error.details;
			console.error(code);
			console.error(message);
			console.error(details);
			// TODO: Handle different types of errors: 'invalid-argument', 'not-found', and 'internal'
			return {result: 'error', code: error.code, message: error.message, details: error.details};
		});
	}

	const addCalendarAccount = async (calendarAccount: CalendarAccount) => {
		const calendarAccountKey = calendarAccount.provider + '_' + calendarAccount.id;

		const docUpdate: any = {
			['calendarAccounts.' + calendarAccountKey]: calendarAccount
		};

		// If the user doesn't have a primary calendar in Firestore, add one
		// Note that the way we're checking depends on this provider's state being in sync with Firestore data on the server
		let newPrimaryCalendar: PrimaryCalendar | undefined;
		if (!state.user?.primaryCalendar || !(Object.keys(state.user?.calendarAccounts as Object).includes(state.user?.primaryCalendar?.calendarAccount))) {
			for (let key in calendarAccount.calendars) {
				const calendar = calendarAccount.calendars[key];
				if (calendar.isPrimary) {
					newPrimaryCalendar = {
						calendarAccount: calendarAccountKey,
						calendarId: calendar.id
					};
				}
			}
		}

		if (newPrimaryCalendar) {
			docUpdate.primaryCalendar = newPrimaryCalendar;
		}

		return Firestore.collection('users')
			.doc(Auth.currentUser!.uid)
			.update(docUpdate)
			.then(() => {
				analyticsContext.trackEvent(events.AddedCalendarAccount, {provider: calendarAccount.provider, calendar_account_id: calendarAccountKey});
			})
			.catch(err => {
				// TODO: Handle error adding calendar account
				console.error('Error adding calendar account.');
				console.error(err);
			});
	}

	const setPrimaryCalendar = async (primaryCalendar: PrimaryCalendar) => {
		return Firestore.collection('users')
			.doc(Auth.currentUser!.uid)
			.update({
				primaryCalendar: primaryCalendar
			})
			.then(() => {
				const newProvider = primaryCalendar.calendarAccount.split('_')[0];
				analyticsContext.trackEvent(events.SetPrimaryCalendarAccount, {provider: newProvider, calendar_account_id: primaryCalendar.calendarAccount});
			})
			.catch(err => {
				// TODO: Handle error setting primary calendar
				console.error('Error setting primary calendar.');
				console.error(err);
			});
	}

	const setTimezone = async (timezone: string) => {
		return Firestore.collection('users')
			.doc(Auth.currentUser!.uid)
			.update({
				'settings.timezone': timezone
			})
			.catch(err => {
				// TODO: Handle error setting timezone
				console.error('Error setting timezone.');
				console.error(err);
			});
	}

	const getRoles = () => {
		if (!state.user) {
			console.warn('Trying to get roles without a user.');
		}
		return state.user?.roles ? state.user.roles : [];
	}

	const forProvider = {
		loading: state.loading,
		user: state.user,
		userId: state.user?.userId,
		registeredEmailAddresses: state.user?.registeredEmailAddresses,
		pendingEmailAddresses: state.user?.pendingEmailAddresses,
		registeredPhoneNumber: state.user?.registeredPhoneNumber,
		pendingPhoneNumber: state.user?.pendingPhoneNumber,
		calendarAccounts: state.user?.calendarAccounts,
		primaryCalendar: state.user?.primaryCalendar,
		updateDisplayName: updateDisplayName,
		addRegisteredEmailAddress: addRegisteredEmailAddress,
		addRegisteredPhoneNumber: addRegisteredPhoneNumber,
		removeRegisteredAccount: removeRegisteredAccount,
		sendVerificationEmail: sendVerificationEmail,
		confirmEmailAddress: confirmEmailAddress,
		sendVerificationText: sendVerificationText,
		confirmPhoneNumber: confirmPhoneNumber,
		addCalendarAccount: addCalendarAccount,
		setPrimaryCalendar: setPrimaryCalendar,
		setTimezone: setTimezone,
		getRoles: getRoles
	};

	return (
		<UserContext.Provider value={forProvider}>
			{children}
		</UserContext.Provider>
	);
}

export function useUser() {
	const context = useContext(UserContext);
	if (context === undefined) {
		throw new Error('useUser must be used within a UserProvider.');
	}
	return context;
}

let unsubscribe: () => void = () => {
	// This code will only be executed if subscribeToUserUpdates hasn't run yet (that function reassigns unsubscribe())
	console.error(`Can't unsubscribe from user listener: No User listener to unsubscribe from.`);
};

async function subscribeToUserUpdates(populateUser: (user: User) => void, Firebase: firebase.app.App, Auth: firebase.auth.Auth, Firestore: firebase.firestore.Firestore) {
	const userConverter = {
		toFirestore: convertUserToFirestore,
		fromFirestore: convertUserFromFirestore
	};
	
	unsubscribe = Firestore.collection('users')
		.doc(Auth.currentUser?.uid)
		.withConverter(userConverter)
		.onSnapshot((querySnapshot) => {
			const data = querySnapshot.data();
			if (data) {
				populateUser(data);
			}
		})
}

function unsubscribeFromUserUpdates() {
	unsubscribe();
}

function convertUserToFirestore(user: User): firebase.firestore.DocumentData {
	return user;
}

function convertUserFromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): User {
	const data = snapshot.data(options);

	return data as User;
}

// --------------------
// Types
// --------------------

export type UserContextType = {
	loading: boolean,
	user: User | null,
	userId: string | undefined,
	registeredEmailAddresses: Array<string> | undefined,
	pendingEmailAddresses: Array<string> | undefined,
	registeredPhoneNumber: string | undefined,
	pendingPhoneNumber: string | undefined,
	calendarAccounts: CalendarAccountsObject | undefined;
	primaryCalendar: PrimaryCalendar | undefined;
	updateDisplayName: (newName: string) => Promise<{success: boolean} | {error: any}>,
	addRegisteredEmailAddress: (emailAddress: string) => Promise<any>,
	addRegisteredPhoneNumber: (phoneNumber: string) => Promise<any>,
	removeRegisteredAccount: (account: string, accountType: AccountTypes) => Promise<any>,
	sendVerificationEmail: (emailAddress: string) => void,
	confirmEmailAddress: (code: string) => Promise<{
		result: string,
		code?: firebase.functions.FunctionsErrorCode,
		message?: string,
		details?: any
	}>,
	sendVerificationText: (phoneNumber: string) => void,
	confirmPhoneNumber: (code: string) => Promise<{
		result: string,
		code?: firebase.functions.FunctionsErrorCode,
		message?: string,
		details?: any
	}>,
	addCalendarAccount: (calendarAccount: CalendarAccount) => Promise<void>,
	setPrimaryCalendar: (primaryCalendar: PrimaryCalendar) => Promise<void>,
	setTimezone: (timezone: string) => Promise<void>,
	getRoles: () => Array<string>
};

type StateType = {
	loading: boolean,
	user: User | null
};

type ProviderProps = {children: React.ReactNode};