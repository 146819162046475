import React, { useState } from 'react';
import { Link, Table, TableBody, TableContainer, TableRow, TextField } from '@mui/material';
import { useUser } from '../../utilities/userContext';
import { JetpackButton } from '../JetpackComponents';
import { RemoveAccountButton, SettingsSectionContainer, SettingsSectionHeader, SettingsTableCell, TableWrapper } from './SettingsComponents';

export default function EmailSettings(props: EmailSettingsProps) {
	const user = useUser();
	const [state, setState] = useState<EmailSettingsStateType>({
		newRegisteredEmailAddress: {
			visible: false,
			inputValue: ''
		}
	});
	return (
		<SettingsSectionContainer>
			<SettingsSectionHeader>Email Addresses</SettingsSectionHeader>
				{(user.registeredEmailAddresses && user.registeredEmailAddresses?.length > 0) || (user.pendingEmailAddresses && user.pendingEmailAddresses?.length > 0)  || state.newRegisteredEmailAddress.visible ?
					<>
						<TableWrapper>
							<TableContainer style={{width: 'fit-content', maxWidth: '100%'}}>
								<Table size='small'>
									<TableBody>
										{user.registeredEmailAddresses?.map(emailAddress => {
											return (
												<TableRow key={emailAddress}>
													<RemoveAccountButton
														onClick={() => props.onRemoveAccount('email', emailAddress, emailAddress)}
													/>
													<SettingsTableCell>
														{emailAddress}
													</SettingsTableCell>
													<SettingsTableCell>
														<em>Verified</em>
													</SettingsTableCell>
												</TableRow>
											);
										})}
										{user.pendingEmailAddresses?.map(emailAddress => {
											return (
												<TableRow key={emailAddress}>
													<RemoveAccountButton
														onClick={() => props.onRemoveAccount('email', emailAddress, emailAddress)}
													/>
													<SettingsTableCell>
														{emailAddress}
													</SettingsTableCell>
													<SettingsTableCell>
														<em>
															{'Pending '}
															<Link
																onClick={() => {
																	user.sendVerificationEmail(emailAddress);
																	alert('Verification email sent. Please check your inbox.');
																}} 
																underline='hover'
																style={{cursor: 'pointer'}}
															>
																(resend)
															</Link>
														</em>
													</SettingsTableCell>
												</TableRow>
											);
										})}
										{state.newRegisteredEmailAddress.visible ?
											<TableRow>
												<SettingsTableCell />	
												<SettingsTableCell>
													<TextField
														variant='standard'
														placeholder='Enter email address'
														onChange={(e) => setState({...state, newRegisteredEmailAddress: {...state.newRegisteredEmailAddress, inputValue: e.target.value}})}
														value={state.newRegisteredEmailAddress.inputValue}
													/>
												</SettingsTableCell>
												<SettingsTableCell>
													<Link
														onClick={() => {
															if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(state.newRegisteredEmailAddress.inputValue)) {
																// TODO: Handle invalid email address more gracefully.
																alert('Invalid email address.');
															} else {
																user.addRegisteredEmailAddress(state.newRegisteredEmailAddress.inputValue);
																setState({...state, newRegisteredEmailAddress: {...state.newRegisteredEmailAddress, inputValue: '', visible: false}});
																// TODO: Do this feedback more gracefully.
																alert('Verification email sent. Please check your inbox.');
															}
														}}
														underline='hover'
														style={{cursor: 'pointer'}}
													>
														Add
													</Link>
												</SettingsTableCell>
											</TableRow>
											:
											null
										}
									</TableBody>
								</Table>
							</TableContainer>
						</TableWrapper>
						{state.newRegisteredEmailAddress.visible ?
							<Link
								onClick={() => setState({...state, newRegisteredEmailAddress: {...state.newRegisteredEmailAddress, inputValue: '', visible: false}})}
								underline='hover'
								style={{cursor: 'pointer'}}
							>
								Cancel
							</Link>
							:
							<Link
								onClick={() => setState({...state, newRegisteredEmailAddress: {...state.newRegisteredEmailAddress, visible: true}})}
								underline='hover'
								style={{cursor: 'pointer'}}
							>
								Add another email address
							</Link>
						}
					</>
					:
					<JetpackButton
						onClick={() => setState({...state, newRegisteredEmailAddress: {...state.newRegisteredEmailAddress, visible: true}})}
						variant='contained'
						color='primary'
					>
						Add email address
					</JetpackButton>
				}
			</SettingsSectionContainer>
	);
}

// --------------------
// Types
// --------------------

type EmailSettingsProps = {
	onRemoveAccount: (accountType: 'email', accountKey: string, accountDisplayName: string) => void;
};

type EmailSettingsStateType = {
	newRegisteredEmailAddress: {
		visible: boolean,
		inputValue: string
	}
};