import { environmentType, useLocalEmulators } from '../utilities/environmentUtils';

export const appChatMessageEndpoint = () => {
	switch (environmentType) {
		case 'prod':
			return 'https://us-central1-jetpackai-prod-01.cloudfunctions.net/inbound_messages-on_app_receive_app_chat';
		case 'test':
			return 'https://us-central1-jetpackai-test-01.cloudfunctions.net/inbound_messages-on_app_receive_app_chat';
		case 'localhost':
			if (useLocalEmulators) {
				return 'http://localhost:8081';
			} else {
				return 'https://us-central1-jetpackai-test-01.cloudfunctions.net/inbound_messages-on_app_receive_app_chat';
			}
		default:
			console.error('(chatContext) Encountered unhandled environment type while attempting to set chat endpoint.');
			return 'https://us-central1-jetpackai-prod-01.cloudfunctions.net/inbound_messages-on_app_receive_app_chat';
	}
}

export const groupSchedulingGetBusyTimesEndpoint = () => {
	switch (environmentType) {
		case 'prod':
			return 'https://us-central1-jetpackai-prod-01.cloudfunctions.net/group_scheduling-get_busy_times';
		case 'test':
			return 'https://us-central1-jetpackai-test-01.cloudfunctions.net/group_scheduling-get_busy_times';
		case 'localhost':
			if (useLocalEmulators) {
				return 'http://localhost:8082';
			} else {
				return 'https://us-central1-jetpackai-test-01.cloudfunctions.net/group_scheduling-get_busy_times';
			}
		default:
			console.error('(groupSchedulingContext) Encountered unhandled environment type while attempting to set get_busy_times endpoint.');
			return 'https://us-central1-jetpackai-prod-01.cloudfunctions.net/group_scheduling-get_busy_times';
	}
}

export const groupSchedulingUpdateGroupResponsesEndpoint = () => {
	switch (environmentType) {
		case 'prod':
			return 'https://us-central1-jetpackai-prod-01.cloudfunctions.net/group_scheduling-update_group_scheduling_responses';
		case 'test':
			return 'https://us-central1-jetpackai-test-01.cloudfunctions.net/group_scheduling-update_group_scheduling_responses';
		case 'localhost':
			if (useLocalEmulators) {
				return 'http://localhost:8083';
			} else {
				return 'https://us-central1-jetpackai-test-01.cloudfunctions.net/group_scheduling-update_group_scheduling_responses';
			}
		default:
			console.error('(groupSchedulingContext) Encountered unhandled environment type while attempting to set update_group_scheduling_responses endpoint.');
			return 'https://us-central1-jetpackai-prod-01.cloudfunctions.net/group_scheduling-update_group_scheduling_responses';
	}
}