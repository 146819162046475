import { GroupSchedulingEventResponses, TimeIntervalEpochSeconds } from '../types/jetpack/collaboration';

function isIntervalBusy(intervalToCheck: TimeIntervalEpochSeconds, busyInterval: TimeIntervalEpochSeconds) {
	// Checks if an interval overlaps with a busy interval.
	if (
		// busyInterval is contained in intervalToCheck
		(intervalToCheck.start <= busyInterval.start && busyInterval.end <= intervalToCheck.end)
		// busyInterval.end is inside intervalToCheck (not including touching intervalToCheck.start because we don't care if the two intervals simply touch)
		|| (intervalToCheck.start < busyInterval.end && busyInterval.end <= intervalToCheck.end)
		// busyInterval.start is inside intervalToCheck (not including touching intervalToCheck.end because we don't care if the two intervals simply touch)
		|| (busyInterval.start <= intervalToCheck.start && intervalToCheck.start < busyInterval.end)
	) {
		return true;
	} else {
		return false;
	}
}

function doIntervalsOverlapOrTouch(interval1: TimeIntervalEpochSeconds, interval2: TimeIntervalEpochSeconds) {
	if ((interval1.start <= interval2.start && interval2.start <= interval1.end) || (interval1.start <= interval2.end && interval2.end <= interval1.end) || (interval2.start <= interval1.start && interval1.start <= interval2.end)) {
		return true;
	} else {
		return false;
	}
}

function isIntervalCompletelyContained(innerInterval: TimeIntervalEpochSeconds, outerInterval: TimeIntervalEpochSeconds) {
	if (innerInterval.start >= outerInterval.start && innerInterval.end <= outerInterval.end) {
		return true;
	} else {
		return false;
	}
}

export function sortAndUnionTimeIntervals(intervalArray: Array<TimeIntervalEpochSeconds>) {
	const newIntervalArray: Array<TimeIntervalEpochSeconds> = [];

	function intervalSortFunction(interval1: TimeIntervalEpochSeconds, interval2: TimeIntervalEpochSeconds) {
		if (interval1.start < interval2.start) return -1;
		else if (interval1.start > interval2.start) return 1;
		else return 0;
	}

	if (intervalArray.length <= 1) {
		intervalArray.forEach(interval => {
			newIntervalArray.push({...interval});
		})
		return newIntervalArray;
	}

	// Sort intervalArray in place
	intervalArray.sort(intervalSortFunction);

	newIntervalArray.push(intervalArray[0]);
	for (let interval of intervalArray) {
		const lastIntervalOfNewArray = newIntervalArray[newIntervalArray.length - 1];
		if (doIntervalsOverlapOrTouch(lastIntervalOfNewArray, interval)) {
			newIntervalArray[newIntervalArray.length - 1] = {
				start: Math.min(interval.start, lastIntervalOfNewArray.start),
				end: Math.max(interval.end, lastIntervalOfNewArray.end)
			}
		} else {
			newIntervalArray.push({...interval});
		}
	}

	return newIntervalArray;
}

export function subtractTimeIntervals(busyIntervals: Array<TimeIntervalEpochSeconds>, freeIntervals: Array<TimeIntervalEpochSeconds>) {
	// Subtracts busy times from free times.
	// This function may also be used backwards (with freeIntervals being busy intervals, and busyIntervals being free intervals).
	// The first argument is the one that will be subtracted (tool), and the second argument is the one that will be subtracted from (target).
	// We just default to calling the tool the busy interval and the target the free interval because it's easier to visualize.
	// The analogy is that all your time starts out free, and you subtract busy times where you have commitments to get your actual free time.

	function minExcludeNull(a: number, b: number | null): number {
		// First value must exist; second value may be null
		if (a && b) {
			return Math.min(a, b);
		} else {
			// Since a cannot be null, b must be null
			return a;
		}
	}

	const newIntervals: Array<TimeIntervalEpochSeconds> = [];
	if (freeIntervals.length < 1) {
		return []
	}
	let counter = freeIntervals[0].start;
	let freeIndex = 0;
	let busyIndex = 0;

	while (counter < freeIntervals[freeIntervals.length - 1].end && freeIndex < freeIntervals.length) {
		const freeInterval = freeIntervals[freeIndex];
		const busyInterval = busyIndex < busyIntervals.length ? busyIntervals[busyIndex] : null;

		if (freeInterval.start > counter) {
			counter = freeInterval.start;
		}
		if (busyInterval && busyInterval.end <= counter) {
			busyIndex += 1;
			continue;
		}
		if (freeInterval.end <= counter) {
			freeIndex += 1;
			continue;
		}

		if (busyInterval && busyInterval.start <= counter && counter < busyInterval.end) {
			// We're in a busy interval. Move to the end of the busy interval.
			counter = busyInterval.end;
			continue;
		} else if (freeInterval.start <= counter && counter < freeInterval.end) {
			// We're in a free interval, which lasts until the free interval ends or the next busy interval starts, whichever is first.
			const nextCounter = minExcludeNull(freeInterval.end, busyInterval ? busyInterval.start : null);
			newIntervals.push({start: counter, end: nextCounter});
			counter = nextCounter;
			continue;
		} else {
			// We're in neither a free interval nor a busy interval. Move to the next free interval.
			// This shouldn't happen because it should be caught above.
			console.warn('(subtractTimeIntervals) Warning: Counter ended loop outside of free and busy intervals. This shouldn\'t happen.');
			counter = freeInterval.start;
			continue;
		}
	}
	
	return newIntervals;
}

export function intersectTimeIntervals(intervalArray1: Array<TimeIntervalEpochSeconds>, intervalArray2: Array<TimeIntervalEpochSeconds>) {
	let result: Array<TimeIntervalEpochSeconds> = [];
	let index1 = 0;
	let index2 = 0;

	while (index1 < intervalArray1.length && index2 < intervalArray2.length) {
		const interval1 = intervalArray1[index1];
		const interval2 = intervalArray2[index2];

		if (interval1.end <= interval2.start) {
			index1++;
			continue;
		}
		if (interval2.end <= interval1.start) {
			index2++;
			continue;
		}

		const intersectedIntervals: TimeIntervalEpochSeconds = {start: Math.max(interval1.start, interval2.start), end: Math.min(interval1.end, interval2.end)};
		if (intersectedIntervals.end > intersectedIntervals.start) {
			result.push(intersectedIntervals);
		}

		if (interval1.end < interval2.end) {
			index1++;
		} else {
			index2++;
		}
	}

	return result;
}

export function checkIfIntervalIsFree(intervalToCheck: TimeIntervalEpochSeconds, arrayOfFreeIntervals: Array<TimeIntervalEpochSeconds>) {
	// Returns true if the interval **is completely contained** by a free interval.
	for (const freeInterval of arrayOfFreeIntervals) {
		if (isIntervalCompletelyContained(intervalToCheck, freeInterval)) {
			return true;
		}
	}
	return false;
}

export function checkIfIntervalIsBusy(intervalToCheck: TimeIntervalEpochSeconds, arrayOfBusyIntervals: Array<TimeIntervalEpochSeconds>) {
	// Returns true if the interval **overlaps at all** with a busy interval.
	for (const busyInterval of arrayOfBusyIntervals) {
		if (isIntervalBusy(intervalToCheck, busyInterval)) {
			return true;
		}
	}
	return false;
}

export function getFreeTimesFromResponses(availableIntervals: Array<TimeIntervalEpochSeconds>, responses: GroupSchedulingEventResponses) {
	const allResponses = Object.values(responses)
		.filter(response => {
			// Filter out response objects that don't actually have a calendar response or manual response.
			if (response.gotCalendarResponse || (response.manualBusyIntervals && response.manualBusyIntervals.length > 0) || (response.manualFreeIntervals && response.manualFreeIntervals.length > 0)) {
				return true;
			} else return false;
		});

	if (allResponses.length) {
		const accumulatedResponses = allResponses
			.map(response => {
				// If we have calendarBusyIntervals: (availableIntervals - calendarBusyIntervals) - manualBusyIntervals + manualFreeIntervals
				// If we don't, just look at manualFreeIntervals.
				const step1 = response.gotCalendarResponse && response.calendarBusyIntervals ? subtractTimeIntervals(response.calendarBusyIntervals, availableIntervals) : [];
				const step2 = response.manualBusyIntervals?.length ? subtractTimeIntervals(response.manualBusyIntervals, step1) : step1;
				const step3 = response.manualFreeIntervals?.length ? sortAndUnionTimeIntervals([...response.manualFreeIntervals, ...step2]) : step2;

				// Get the intersection of the result and availableIntervals so that we don't return manualFreeIntervals that are outside of the allowed availableIntervals.
				const step4 = intersectTimeIntervals(step3, availableIntervals);

				return step4;
			})
			.reduce((accumulatedResponses, currentResponse) => {
				return intersectTimeIntervals(accumulatedResponses, currentResponse);
			});

		return sortAndUnionTimeIntervals(accumulatedResponses);
	} else {
		return [];
	}
}