import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { useUser } from '../../utilities/userContext';
import { CollaborationProvider, useCollaboration } from '../../utilities/collaborationContext';
import { GroupSchedulingOwnerProvider, useGroupSchedulingOwner } from '../../utilities/groupSchedulingOwnerContext';
import * as ROUTES from '../../constants/routes';
import { Link } from '../JetpackText';
import { JetpackButton } from '../JetpackComponents';
import { GroupSchedulingEvent } from '../../types/jetpack/collaboration';

export default function GroupSchedulingEventsListContextWrapper() {
	return (
		<CollaborationProvider>
			<GroupSchedulingOwnerProvider>
				<GroupSchedulingEventsListDataWrapper />
			</GroupSchedulingOwnerProvider>
		</CollaborationProvider>
	);
}

function GroupSchedulingEventsListDataWrapper() {
	const userContext = useUser();
	const collaborationContext = useCollaboration();
	const groupSchedulingOwnerContext = useGroupSchedulingOwner();
	const history = useHistory();
	
	const handleCreateEventClick = () => {
		groupSchedulingOwnerContext.createEvent()
		.then(eventId => {
			history.push(ROUTES.GROUP_SCHEDULING + `/${eventId}/owner`);
		})
		.catch(error => console.error(error));
	}

	return (
		<GroupSchedulingEventsListDisplayComponent
			userId={userContext.userId!}
			groupSchedulingEvents={collaborationContext.groupSchedulingEvents}
			handleCreateEventClick={handleCreateEventClick}
		/>
	);
}

function GroupSchedulingEventsListDisplayComponent(props: GroupSchedulingEventsListProps) {
	const ownedEvents: Array<React.ReactNode> = [];
	const otherEvents: Array<React.ReactNode> = [];

	const sortedEvents = props.groupSchedulingEvents.sort((e1, e2) => {
		if (!e1.availableStart) {
			return 1;
		} else if (!e2.availableStart) {
			return -1;
		} else if (e1.availableStart < e2.availableStart) {
			return -1;
		} else if (e1.availableStart < e2.availableStart) {
			return 1;
		} else {
			return 0;
		}
	})

	sortedEvents.forEach(event => {
		if (event.users && event.users[props.userId] && event.users[props.userId].isOwner) {
			ownedEvents.push(
				<div key={event.id}>
					<Link
						url={`${ROUTES.GROUP_SCHEDULING}/${event.id}/owner`}
						text={event.title}
					/>
				</div>
			);
		} else {
			otherEvents.push(
				<div key={event.id}>
					<Link
						url={`${ROUTES.GROUP_SCHEDULING}/${event.id}`}
						text={event.title}
					/>
				</div>
			);
		}
	})

	return (
		<GroupSchedulingPageContainer>
			<h1>Group Scheduling</h1>
			<JetpackButton
				onClick={props.handleCreateEventClick}
				variant='contained'
				sx={{
					textTransform: 'none'
				}}
			>
				Create event
			</JetpackButton>
			{ownedEvents.length > 0 ?
				<Fragment>
					<h2>My Events</h2>
					{
						ownedEvents
					}
				</Fragment>
				: null
			}
			{otherEvents.length > 0 ?
				<Fragment>
					<h2>Events Created by Others</h2>
					{
						otherEvents
					}
				</Fragment>
				: null
			}
		</GroupSchedulingPageContainer>
	);
}

const GroupSchedulingPageContainer = styled.div``;

// --------------------
// Types
// --------------------

type GroupSchedulingEventsListProps = {
	userId: string;
	groupSchedulingEvents: Array<GroupSchedulingEvent>;
	handleCreateEventClick: () => void;
}