import React, { Fragment, useState } from 'react';
import { Link, Table, TableBody, TableContainer, TableRow, TextField } from '@mui/material';
// @ts-ignore (No type definitions for 'react-phone-number-input/input')
import ReactPhoneNumberInput from 'react-phone-number-input/input';
import Phonenumber from 'awesome-phonenumber';
import { useUser } from '../../utilities/userContext';
import {JetpackButton } from '../JetpackComponents';
import { RemoveAccountButton, SettingsSectionContainer, SettingsSectionHeader, SettingsTableCell, TableWrapper } from './SettingsComponents';

export default function PhoneSettings(props: PhoneSettingsProps) {
	const user = useUser();
	const [state, setState] = useState<PhoneSettingsStateType>({
		newRegisteredPhoneNumber: {
			visible: false,
			inputValue: '',
			code: ''
		},
	});

	const phoneNumber = user.registeredPhoneNumber
		? new Phonenumber(user.registeredPhoneNumber)
		: user.pendingPhoneNumber
			? new Phonenumber(user.pendingPhoneNumber)
				:undefined
		;

	return (
		<SettingsSectionContainer>
			<SettingsSectionHeader>Phone Number</SettingsSectionHeader>
			{
				(!!user.registeredPhoneNumber || !!user.pendingPhoneNumber || !!state.newRegisteredPhoneNumber.visible) ?
				<TableWrapper>
					<TableContainer style={{width: 'fit-content', maxWidth: 600}}>
						<Table size='small'>
							<TableBody>
								{user.registeredPhoneNumber ?
									<TableRow>
										<RemoveAccountButton
											onClick={() => props.onRemoveAccount('phone', user.registeredPhoneNumber || '', phoneNumber?.getNumber('national') || '')}
										/>
										<SettingsTableCell>
											{phoneNumber!.getNumber('national')}
										</SettingsTableCell>
										<SettingsTableCell>
											<em>Verified</em>
										</SettingsTableCell>
									</TableRow>
									:
									(user.pendingPhoneNumber ?
										<Fragment>
											<TableRow>
												<RemoveAccountButton
													onClick={() => props.onRemoveAccount('phone', user.pendingPhoneNumber || '', phoneNumber?.getNumber('national') || '')}
												/>
												<SettingsTableCell>
													{phoneNumber!.getNumber('national') || 'Invalid number'}
												</SettingsTableCell>
												<SettingsTableCell>
													<em>
														{'Pending '}
														<Link
															onClick={() => {
																user.sendVerificationText(user.pendingPhoneNumber!);
																alert('Verification code sent. Please check your text messages.');
															}}
															underline='hover'
															style={{cursor: 'pointer'}}
														>
															(resend)
														</Link>
													</em>
												</SettingsTableCell>
											</TableRow>
											<TableRow>
												<SettingsTableCell />
												<SettingsTableCell>
													<TextField
														variant='standard'
														placeholder='Enter verification code'
														value={state.newRegisteredPhoneNumber.code}
														onChange={(e) => setState({...state, newRegisteredPhoneNumber: {...state.newRegisteredPhoneNumber, code: e.target.value}})}
													/>
												</SettingsTableCell>
												<SettingsTableCell>
													<Link
														onClick={() => {
															user.confirmPhoneNumber(state.newRegisteredPhoneNumber.code)
																.then((result) => {
																	if (result.code) {
																		console.error(result);
																		throw result.message;
																	}
																	alert('Successfully verified phone number.');
																	setState({...state, newRegisteredPhoneNumber: {...state.newRegisteredPhoneNumber, code: ''}});
																})
																.catch((error) => {
																	// TODO: Gracefully handle error
																	console.error(error);
																	alert('Error verifying phone number. Please try again.');
																});
														}}
														underline='hover'
														style={{cursor: 'pointer'}}
													>
														Submit
													</Link>
												</SettingsTableCell>
											</TableRow>
										</Fragment>
										:
										(state.newRegisteredPhoneNumber.visible ?
											<Fragment>
												<TableRow>
													<SettingsTableCell />
													<SettingsTableCell>
														<ReactPhoneNumberInput
															placeholder='Enter phone number'
															country='US'
															value={state.newRegisteredPhoneNumber.inputValue}
															onChange={(value) => setState({...state, newRegisteredPhoneNumber: {...state.newRegisteredPhoneNumber, inputValue: value}})}
															inputComponent={TextFieldForPhoneNumber}
														/>
													</SettingsTableCell>
													<SettingsTableCell>
														<Link
															onClick={() => {
																// TODO: Validate phone number? (Should be good because the input controls it, but a double-check might be nice)
																alert(`Sending a verification text to ${state.newRegisteredPhoneNumber.inputValue ? state.newRegisteredPhoneNumber.inputValue : 'null'}.`);
																user.addRegisteredPhoneNumber(state.newRegisteredPhoneNumber.inputValue!);
																setState({...state, newRegisteredPhoneNumber: {...state.newRegisteredPhoneNumber, inputValue: '', visible: false}});
															}}
															underline='hover'
															style={{cursor: 'pointer'}}
														>
															Add
														</Link>
													</SettingsTableCell>
												</TableRow>
												<TableRow>
													<SettingsTableCell align='center' colSpan={3}>
														<Link
															onClick={() => setState({...state, newRegisteredPhoneNumber: {...state.newRegisteredPhoneNumber, visible: false, inputValue: '', code: ''}})}
															underline='hover'
															style={{cursor: 'pointer'}}
														>
															Cancel
														</Link>
													</SettingsTableCell>
												</TableRow>
											</Fragment>
											:
											null
										)
									)
								}
							</TableBody>
						</Table>
					</TableContainer>
				</TableWrapper>
				:
				<JetpackButton
					onClick={() => setState({...state, newRegisteredPhoneNumber: {...state.newRegisteredPhoneNumber, visible: true}})}
					variant='contained'
					color='primary'
				>
					Add phone number
				</JetpackButton>
			}
		</SettingsSectionContainer>
	);
}

const TextFieldForPhoneNumber = React.forwardRef((props: any, ref: any) => {
	return (
		<TextField
			{...props}
			variant='standard'
			value={props.value}
			onChange={props.onChange}
			inputRef={ref}
		/>
	);
});

// --------------------
// Types
// --------------------

type PhoneSettingsProps = {
	onRemoveAccount: (accountType: 'phone', accountKey: string, accountDisplayName: string) => void;
};

type PhoneSettingsStateType = {
	newRegisteredPhoneNumber: {
		visible: boolean,
		inputValue?: string,
		code: string
	},
};