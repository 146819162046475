import React, { useState } from 'react';
import styled from '@emotion/styled';
import { CircularProgress, TextField } from '@mui/material';
import { AdminProvider, useAdmin } from '../utilities/adminContext';
import { useJetpackSnackbar } from './Snackbar';
import Card from './JetpackCard';
import { JetpackButton } from './JetpackComponents';
import { Heading1, Heading3 } from './JetpackText';
import { colors } from '../styles/colors';

export default function Admin() {
	return (
		<AdminProvider>
			<AdminComponent />
		</AdminProvider>
	);
}

function AdminComponent() {
	const [inviteEmailAddress, setInviteEmailAddress] = useState('');
	const [isSendingInvitation, setIsSendingInvitation] = useState(false);
	const admin = useAdmin();
	const { enqueueSnackbar } = useJetpackSnackbar();

	const sendInvitation = () => {
		setIsSendingInvitation(true);
		
		admin.inviteNewUser(inviteEmailAddress)
			.then((result) => {
				if ('success' in result) {
					enqueueSnackbar(`Sent invitation to ${inviteEmailAddress}.`, {variant: 'success'});
					setIsSendingInvitation(false);
				} else {
					throw result.error;
				}
			})
			.catch((error) => {
				enqueueSnackbar(`Error sending invitation to ${inviteEmailAddress}.`, {variant: 'error'})
				setIsSendingInvitation(false);
			});
	}

	const handleKeyPress = (e: string) => {
		if (e === 'Enter') {
			sendInvitation();
		}
	}

	return (
		<AdminContainer>
			<ContentContainer>
				<Heading1>{'Admin'}</Heading1>
				<Heading3>{'Send invite code'}</Heading3>
				<InvitationArea>
					<TextField
						variant='standard'
						placeholder='Enter email address'
						value={inviteEmailAddress}
						onChange={(e) => setInviteEmailAddress(e.target.value)}
						onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => handleKeyPress(e.key)}
					/>
					<div style={{width: 16}} />
					<SendInvitationButton
						variant='contained'
						color='primary'
						onClick={sendInvitation}
					>
						{isSendingInvitation ? <CircularProgress size='1.75em' color='secondary' /> : 'Invite'}
					</SendInvitationButton>
				</InvitationArea>
			</ContentContainer>
		</AdminContainer>
	);
}

const AdminContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${colors.jetpackBackgroundDark};
`;

const ContentContainer = styled(Card)`
	width: 80%;
	max-width: 576px;
	box-sizing: border-box;
	margin: 24px;
	padding: 24px;
	text-align: center;
	align-items: center;
`;

const InvitationArea = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const SendInvitationButton = styled(JetpackButton)`
	width: fit-content;
	text-transform: none;
`;