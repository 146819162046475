import React, { Fragment, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import styled from '@emotion/styled';
import { ThemeProvider } from '@mui/material/styles';
import { CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { FiCalendar, FiChevronRight, FiChevronDown, FiSettings } from 'react-icons/fi';
import { FaCheckCircle } from 'react-icons/fa';

import { GroupSchedulingProvider, useGroupScheduling } from '../../utilities/groupSchedulingContext';
import { useUser } from '../../utilities/userContext';
import { checkIfIntervalIsFree, checkIfIntervalIsBusy } from '../../utilities/scheduleHelpers';
import { GroupSchedulingPageContainer, GroupSchedulingSection, GroupSchedulingSectionDivider, GroupSchedulingSectionHeading } from './GroupSchedulingComponents';
import { JetpackButton } from '../JetpackComponents';
import { LinkText } from '../JetpackText';
import { darkTheme } from '../../styles/materialUiTheme';
import { colors } from '../../styles/colors';
import * as ROUTES from '../../constants/routes';
import { FreeBusyStatus, TimeIntervalEpochSeconds } from '../../types/jetpack/collaboration';
import { CalendarAccountsObject } from '../../types/jetpack/user';

const SCHEDULING_TIME_INCREMENT_SECONDS = 1800

export default function GroupSchedulingParticipantPageContextWrapper() {
	let { eventId } = useParams<GroupSchedulingRouterParams>();

	return (
		<LocalizationProvider dateAdapter={AdapterLuxon}>
			<GroupSchedulingProvider
				eventId={eventId}
			>
				<GroupSchedulingParticipantPageDataWrapper />
			</GroupSchedulingProvider>
		</LocalizationProvider>
	);
}

function GroupSchedulingParticipantPageDataWrapper() {
	const groupSchedulingContext = useGroupScheduling();
	const userContext = useUser();

	return (
		<GroupSchedulingParticipantPageDisplayComponent
			eventId={groupSchedulingContext.eventId}
			title={groupSchedulingContext.title}
			userIsOwner={groupSchedulingContext.userIsOwner}
			availableIntervals={groupSchedulingContext.availableIntervals}
			gotCalendarResponse={groupSchedulingContext.gotCalendarResponse}
			calendarBusyIntervals={groupSchedulingContext.calendarBusyIntervals}
			manualFreeIntervals={groupSchedulingContext.manualFreeIntervals}
			manualBusyIntervals={groupSchedulingContext.manualBusyIntervals}
			noCalendarsFound={groupSchedulingContext.noCalendarsFound}
			gettingBusyTimes={groupSchedulingContext.gettingBusyTimes}
			addCurrentUserToEvent={groupSchedulingContext.addCurrentUserToEvent}
			manuallyEditFreeBusy={groupSchedulingContext.manuallyEditFreeBusy}
			getBusyTimes={groupSchedulingContext.getBusyTimes}
			calendarAccounts={userContext.calendarAccounts}
		/>
	);
}

export function GroupSchedulingParticipantPageDisplayComponent(props: GroupSchedulingPageProps) {
	const routerHistory = useHistory();
	let location = useLocation();

	const [isPainting, setIsPainting] = useState(false);
	const [currentPaintType, setCurrentPaintType] = useState<FreeBusyStatus>('free');
	const [paintMode, setPaintMode] = useState<'painting' | 'removing'>('painting');
	const [calendarDays, setCalendarDays] = useState<{[key: string]: Array<TimeIntervalEpochSeconds>}>({});
	const [cellDataById, setCellDataById] = useState<{[key: string]: CellData}>({});
	const [calendarIsExpanded, setCalendarIsExpanded] = useState(false);
	const [forceRerender, setForceRerender] = useState(0);

	useEffect(() => {
		document.addEventListener('mouseup', endPaint);
		return () => document.removeEventListener('mouseup', endPaint);
	}, [])

	useEffect(() => {
		let cellData: {[key: string]: CellData} = {};
		props.availableIntervals.forEach(interval => {
			let stepper = interval.start;
			while (stepper + SCHEDULING_TIME_INCREMENT_SECONDS <= interval.end) {
				const window: TimeIntervalEpochSeconds = {start: stepper, end: stepper + SCHEDULING_TIME_INCREMENT_SECONDS};
				const isCalendarBusy = checkIfIntervalIsBusy(window, props.calendarBusyIntervals);
				const isManualFree = checkIfIntervalIsFree(window, props.manualFreeIntervals);
				const isManualBusy = checkIfIntervalIsBusy(window, props.manualBusyIntervals);
				cellData[stepper.toString()] = {
					id: stepper,
					interval: window,
					calendarFreeBusyStatus: (props.calendarBusyIntervals && props.calendarBusyIntervals.length > 0) ? (isCalendarBusy ? 'busy' : 'free') : null,
					manualFreeBusyStatus: (isManualBusy ? 'busy' : (isManualFree ? 'free' : null))
				};
				stepper += SCHEDULING_TIME_INCREMENT_SECONDS;
			}
		});
		setCellDataById(cellData);
		setCalendarDays(makeCalendar(cellData));
	}, [props.availableIntervals, props.calendarBusyIntervals, props.manualFreeIntervals, props.manualBusyIntervals])

	function startPaint(cellId: string) {
		setIsPainting(true);
	}

	function endPaint() {
		setIsPainting(false);
	}

	function paintCell(cellInterval: TimeIntervalEpochSeconds) {
		props.manuallyEditFreeBusy(cellInterval, currentPaintType);
	}

	function unpaintCell(cellInterval: TimeIntervalEpochSeconds) {
		props.manuallyEditFreeBusy(cellInterval, null);
	}

	function paintOrUnpaintCell(cellInterval: TimeIntervalEpochSeconds) {
		if (paintMode === 'painting') {
			paintCell(cellInterval);
		} else {
			unpaintCell(cellInterval);
		}
	}

	function handleMouseDown(e: React.MouseEvent<HTMLDivElement>, cellId: string) {
		e.preventDefault();
		if (cellDataById[cellId].manualFreeBusyStatus !== currentPaintType) {
			paintCell(cellDataById[cellId].interval);
			setPaintMode('painting');
		} else {
			unpaintCell(cellDataById[cellId].interval);
			setPaintMode('removing');
		}
		startPaint(cellId);
	}

	function handleMouseEnter(e: React.MouseEvent<HTMLDivElement>, cellId: string) {
		e.preventDefault();
		if (isPainting) {
			paintOrUnpaintCell(cellDataById[cellId].interval);
		}
	}

	function handlePaintTypeChange(e: React.ChangeEvent<HTMLInputElement>) {
		switch(e.target.value) {
			case 'free':
				setCurrentPaintType('free');
				break;
			case 'busy':
				setCurrentPaintType('busy');
				break;
			case '':
				setCurrentPaintType(null);
				break;
			default:
				break;
		}
	}

	const calendarDaysToDisplay = [];
	for (const [day, arrayOfIntervals] of Object.entries(calendarDays)) {
		const cells = arrayOfIntervals.map(interval => {
			const timeString = DateTime.fromSeconds(interval.start).toLocaleString(DateTime.TIME_SIMPLE).toLowerCase();
			return (
				<CalendarCell
					key={interval.start}
					calendarFreeBusy={cellDataById[interval.start].calendarFreeBusyStatus}
					manualFreeBusy={cellDataById[interval.start].manualFreeBusyStatus}
					onMouseDown={(e) => handleMouseDown(e, interval.start.toString())}
					onMouseEnter={(e: React.MouseEvent<HTMLDivElement>) => handleMouseEnter(e, interval.start.toString())}
				>
					{timeString}
				</CalendarCell>
			);
		});
		calendarDaysToDisplay.push(
			<CalendarDay
				key={day}
				dayString={day}
			>
				{cells}
			</CalendarDay>
		)
	}

	const calendarError = Object.values(props.calendarAccounts || []).filter(account => {
		if (account.error) {
			return true;
		} else {
			return false;
		}
	}).length > 0;

	let responseSection = <PromptToConnectCalendars routerHistoryPush={routerHistory.push} />;

	if (!props.calendarAccounts || !(Object.keys(props.calendarAccounts).length > 0)) {
		responseSection = <PromptToConnectCalendars routerHistoryPush={routerHistory.push} />;
	} else {
		if (props.gotCalendarResponse && props.calendarBusyIntervals) {
			responseSection = <GotResponse />;
		} else if (calendarError) {
			responseSection = <PromptToFixCalendarError routerHistoryPush={routerHistory.push} />;
		}
		else {
			// Calendar accounts are connected, but there's no response and no error.
			responseSection = <PromptToQueryForBusyTimes getBusyTimes={props.getBusyTimes} forceUpdate={() => setForceRerender(forceRerender + 1)} gettingBusyTimes={props.gettingBusyTimes} />;
		}
	}

	return (
		<GroupSchedulingPageContainer>
			<ThemeProvider theme={darkTheme}>
				<h1>{`${props.title}`}</h1>

				<GroupSchedulingSection>
					{responseSection}
					<div style={{height: 16}} />
					<LinkText
						onClick={() => setCalendarIsExpanded(!calendarIsExpanded)}
					>
						<div>
							View and manually edit available times
							{calendarIsExpanded ?
							<FiChevronDown />
							: <FiChevronRight />
							}
						</div>
					</LinkText>
					{calendarIsExpanded ?
						<>
							<p>
								This calendar shows your availability during the specified times (blue times are free and red times are busy). You can manually edit your availability for this event by selecting an option below and painting the calendar.
							</p>
							<CalendarContainer>
								{calendarDaysToDisplay}
							</CalendarContainer>
							<div style={{height: '8px'}} />
							{(props.manualFreeIntervals && props.manualFreeIntervals.length) || (props.manualBusyIntervals && props.manualBusyIntervals.length) ?
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									<FaCheckCircle color={colors.green} size={24} style={{marginRight: '16px'}} />
									<div>Your response has been sent to the event organizer.</div>
								</div>
								: null
							}
							<div style={{height: '16px'}} />
							<div style={{paddingBottom: '8px'}}>
								Manually mark times as:
							</div>
							<FormControl component='fieldset'>
								<RadioGroup
									aria-label='paint-type'
									defaultValue={'free'}
									name='paint-type-radio-buttons-group'
									onChange={handlePaintTypeChange}
								>
									<FormControlLabel value='free' control={<Radio sx={{color: colors.blueDark, '&.Mui-checked': {color: colors.blueDark}, '&.MuiRadio-root': {paddingBottom: '2px', paddingTop: '2px'}}} />} label='Free' />
									<FormControlLabel value='busy' control={<Radio sx={{color: colors.red, '&.Mui-checked': {color: colors.red}, '&.MuiRadio-root': {paddingBottom: '2px', paddingTop: '2px'}}} />} label='Busy' />
									<FormControlLabel value={''} control={<Radio sx={{color: colors.white, '&.Mui-checked': {color: colors.white}, '&.MuiRadio-root': {paddingBottom: '2px', paddingTop: '2px'}}} />} label='Remove' />
								</RadioGroup>
							</FormControl>
						</>
					: null}
				</GroupSchedulingSection>

				{props.userIsOwner ?
					<Fragment>

						<GroupSchedulingSectionDivider />
					
						<GroupSchedulingSection>
							<GroupSchedulingSectionHeading>You are the organizer of this event</GroupSchedulingSectionHeading>
							<p>Click to udate event settings, invite others, and view results.</p>
							<LinkToOwnerPage
								currentUrl={location.pathname}
							/>
						</GroupSchedulingSection>

					</Fragment>
					: null
				}
				<div style={{height: 24}} />
			</ThemeProvider>
		</GroupSchedulingPageContainer>
	);
}

function GotResponse() {
	return (
		<div>
			<GroupSchedulingSectionHeading>Got free times from your calendar</GroupSchedulingSectionHeading>
			<CalendarResponseContainer>
				<FaCheckCircle color={colors.green} size={24} style={{marginRight: '16px'}} />
				<div>
					<div>Your response has been sent to the event organizer.</div>
				</div>
			</CalendarResponseContainer>
		</div>
	);
}

function PromptToConnectCalendars(props: {routerHistoryPush: (path: string, state?: unknown) => void}) {
	return (
		<div>
			<GroupSchedulingSectionHeading>Connect calendars to get your availability</GroupSchedulingSectionHeading>
			<JetpackButton
				onClick={() => props.routerHistoryPush(ROUTES.SETTINGS)}
				endIcon={<FiCalendar />}
			>
				Connect calendars
			</JetpackButton>
			<div style={{height: 16}} />
			{`If you don't connect your calendars, you can manually enter your availability below.`}
		</div>
	);
}

function PromptToFixCalendarError(props: {routerHistoryPush: (path: string, state?: unknown) => void}) {
	return (
		<div>
			<GroupSchedulingSectionHeading>Error getting free times from your calendar</GroupSchedulingSectionHeading>
			<p>
				Please reconnect your calendar to fix the issue.
			</p>
			<JetpackButton
				onClick={() => props.routerHistoryPush(ROUTES.SETTINGS)}
				endIcon={<FiCalendar />}
			>
				Connect calendars to get your availability
			</JetpackButton>
		</div>
	);
}

function PromptToQueryForBusyTimes(props: {getBusyTimes: () => Promise<void>, forceUpdate: () => void, gettingBusyTimes: boolean}) {
	return (
		<div>
			<JetpackButton
				onClick={() => {
					props.getBusyTimes()
						.then(() => {
							props.forceUpdate();
						})
						.catch(err => console.error(err))
				}}
				variant='contained'
			>
				Get busy times from your calendars
				<div style={{width: '8px'}} />
				{props.gettingBusyTimes ? <CircularProgress size='1em' /> : null}
			</JetpackButton>
		</div>
	);
}

function makeCalendar(cellDataById: {[key: string]: CellData}) {
	const calendarDays: {[key: string]: Array<TimeIntervalEpochSeconds>} = {};

	for (const cellData of Object.values(cellDataById)) {
		const dateString = DateTime.fromSeconds(cellData.interval.start).toFormat('yyyy-MM-dd');
		if (dateString in calendarDays) {
			calendarDays[dateString].push(cellData.interval);
		} else {
			calendarDays[dateString] = [cellData.interval];
		}
	}

	return calendarDays;
}

function LinkToOwnerPage(props: {currentUrl: string}) {
	const url = `${props.currentUrl}/owner`;

	return (
		<RouterLink
			to={url}
			style={{
				textDecoration: 'none'
			}}
		>
			<JetpackButton
				endIcon={<FiSettings />}
			>
				Manage event
			</JetpackButton>
		</RouterLink>
	);
}

function CalendarDay({dayString, children}: CalendarDayProps) {
	const date = DateTime.fromFormat(dayString, 'yyyy-MM-dd');
	return (
		<CalendarDayContainer>
			<div
				style={{
					fontSize: 12
				}}
			>
				{`${date.monthShort} ${date.day}`}
			</div>
			<div
				style={{
					fontSize: 14,
					fontWeight: 'bold'
				}}
			>
				{`${date.weekdayShort}`}
			</div>
			{children}
		</CalendarDayContainer>
	);
}

const CalendarResponseContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const CalendarContainer = styled.div`
	max-width: 100%;
	padding: 8px;
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
`;

const CalendarDayContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const borderWidth = 0;
const CalendarCell = styled.div<CalendarCellProps>`
	min-width: 64px;
	min-height: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: ${borderWidth}px solid ${colors.gray2};
	margin-bottom: -${borderWidth}px;
	margin-right: -${borderWidth}px;
	padding: 2px;
	box-sizing: border-box;
	cursor: default;
	font-size: 12px;
	color: ${props => {
		if (props.manualFreeBusy) {
			switch (props.manualFreeBusy) {
				case 'free':
				return colors.white;
			case 'busy':
				return colors.white;
			default:
				break;
			}
		} else if (props.calendarFreeBusy) {
			switch (props.calendarFreeBusy) {
				case 'free':
				return 'inherit';
			case 'busy':
				return 'inherit';
			default:
				break;
			}
		} else {
			return 'inherit';
		}
	}};
	background-color: ${props => {
		if (props.manualFreeBusy) {
			switch (props.manualFreeBusy) {
				case 'free':
				return colors.blueDark;
			case 'busy':
				return colors.red;
			default:
				break;
			}
		} else if (props.calendarFreeBusy) {
			switch (props.calendarFreeBusy) {
				case 'free':
				return colors.blueLight;
			case 'busy':
				return colors.jetpackCoral;
			default:
				break;
			}
		} else {
			return 'transparent';
		}
	}};
	:hover {
		background-color: ${props => {
			if (!props.calendarFreeBusy && !props.manualFreeBusy) {
				return colors.gray1;
			}
		}};
		filter: ${props => {
			if (!props.calendarFreeBusy && !props.manualFreeBusy) {
				return 'brightness(90%)';
			} else {
				return 'brightness(120%)';
			}
		}}
	}
`;

// --------------------
// Types
// --------------------

type GroupSchedulingRouterParams = {
	eventId: string;
}

type GroupSchedulingPageProps = {
	eventId: string;
	userIsOwner: boolean;
	title: string;
	availableIntervals: Array<TimeIntervalEpochSeconds>;
	gotCalendarResponse: boolean;
	calendarBusyIntervals: Array<TimeIntervalEpochSeconds>;
	manualFreeIntervals: Array<TimeIntervalEpochSeconds>;
	manualBusyIntervals: Array<TimeIntervalEpochSeconds>;
	noCalendarsFound: boolean;
	gettingBusyTimes: boolean;
	addCurrentUserToEvent: () => void;
	manuallyEditFreeBusy: (interval: TimeIntervalEpochSeconds, newStatus: FreeBusyStatus) => void;
	getBusyTimes: () => Promise<void>;
	calendarAccounts: CalendarAccountsObject | undefined;
}

type CalendarDayProps = {
	dayString: string;
	children: React.ReactNode;
}

type CalendarCellProps = {
	calendarFreeBusy: FreeBusyStatus;
	manualFreeBusy: FreeBusyStatus;
}

type CellData = {
	id: number;
	interval: TimeIntervalEpochSeconds;
	calendarFreeBusyStatus: FreeBusyStatus;
	manualFreeBusyStatus: FreeBusyStatus;
}