import React from 'react';
import styled from '@emotion/styled';
import { Dialog, DialogActions, DialogContent, DialogContentText, TableCell } from '@mui/material';
import { FiX } from 'react-icons/fi';
import Card from '../JetpackCard';
import { JetpackButton } from '../JetpackComponents';
import { Heading2 } from '../JetpackText';
import { colors } from '../../styles/colors';
import { AccountTypes } from '../../types/jetpack/user';

export function RemoveAccountButton(props: RemoveAccountButtonProps) {
	return (
		<SettingsTableCell style={{padding: 0}}>
			<RemoveAccountButtonIcon
				onClick={props.onClick}
			/>
		</SettingsTableCell>
	);
}

export function RemoveAccountDialog(props: RemoveAccountDialogProps) {
	let dialogText = `Remove account?`;
	
	switch (props.type) {
		case 'email':
			dialogText = `Remove email account ${props.account}?`;
			break;
		case 'phone':
			dialogText = `Remove phone number ${props.account}?`;
			break;
		case 'calendar':
			dialogText = `Remove calendar account ${props.account}?`;
			break;
		default:
			dialogText = `Remove ${props.type} ${props.account}?`;
	}

	return (
		<Dialog open={props.open}>
			<DialogContent>
				<DialogContentText>
					{dialogText}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<JetpackButton variant='text' onClick={props.onCancelClick}>Cancel</JetpackButton>
				<JetpackButton variant='contained' onClick={props.onConfirmClick}>Remove account</JetpackButton>
			</DialogActions>
		</Dialog>
	)
}

export const SettingsContainer = styled.div`
	width: 100%;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${colors.jetpackBackgroundDark};
`;

export const SettingsCard = styled(Card)`
	width: 80%;
	max-width: 576px;
	box-sizing: border-box;
	margin: 24px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SettingsSectionContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SettingsSectionHeader = styled(Heading2)`
	margin-bottom: 8px;
`;

export const TableWrapper = styled.div`
	max-width: 100%;
	border-style: solid;
	border-width: 1;
	border-radius: 4;
	border-color: transparent;
`;

export const SettingsTableCell = styled(TableCell)`
	border-color: transparent;
	padding: 4;
	font-size: 1.0em;
`;

export const RemoveAccountButtonIcon = styled(FiX)`
	align-items: center;
	vertical-align: middle;
	color: ${colors.gray3};
	:hover {
		color: ${colors.gray1};
		cursor: pointer;
	}
`;

// --------------------
// Types
// --------------------

type RemoveAccountButtonProps = {
	onClick: () => void
};

type RemoveAccountDialogProps = {
	open: boolean,
	type: AccountTypes,
	account: string,
	onConfirmClick: () => void,
	onCancelClick: () => void
}